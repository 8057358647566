import React, { useState } from "react";
import { connect } from "react-redux";
import { userRegisterStart } from "../store/actions/UserAction";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const Register = (props) => {
  const [passwordEye, setPasswordEye] = useState({
    password: false,
    confirm_password: false,
  });

  const handleSignup = (values) => {
    const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

    const newData = { ...values, user_timezone: timezone };

    props.dispatch(userRegisterStart(newData));
  };

  const registerSchema = Yup.object().shape({
    name: Yup.string().required("Name is required *"),
    username: Yup.string().required("Username is required *"),
    mobile: Yup.number()
      .required("Mobile Number is Required")
      .test("mobile", "Mobile Number is Invalid", (val) => {
        if (val) return val.toString().length >= 6;
      }),
    email: Yup.string()
      .email("Invalid email address format")
      .required("Email is required *"),
    password: Yup.string()
      .min(6, "Password must be 6 characters at minimum")
      .required("Password is required *"),
    // confirm_password: Yup.string()
    //   .min(6, "Password must be 6 characters at minimum")
    //   .required("Password is required *")
    //   .test('passwords-match', 'Passwords must match', function(value){
    //     return this.parent.password === value
    //   })
  });

  const handlePasswordEye = () => {
    setPasswordEye({
      ...passwordEye,
      password : passwordEye.password ? false : true
    })
  }

  const handleConfirmPasswordEye = () => {
    setPasswordEye({
      ...passwordEye,
      confirm_password : passwordEye.confirm_password ? false : true
    })
  }

  return (
    <>
      <div class="content-area">
        <div class="login-bg access-register"></div>
        <div class="container login-area register">
          <div class="section pb0">
            <h3 class="bot-20 center white-text">Register</h3>
            <Formik
              initialValues={{
                name: "",
                username: "",
                mobile: "",
                email: "",
                password: "",
                // confirm_password: "",
              }}
              validationSchema={registerSchema}
              onSubmit={(values) => handleSignup(values)}
            >
              {({ touched, errors, setFieldValue, isSubmitting }) => (
                <Form noValidate>
                  <div className="row">
                    <div
                      className={`input-field col s10 offset-s1  ${
                        touched.name && errors.name ? "is-invalid" : ""
                      }`}
                    >
                      <Field
                        className={`input-bordered no-padding  ${
                          touched.name && errors.name ? "is-invalid" : ""
                        }`}
                        type="text"
                        name="name"
                        placeholder="Your Name"
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      name="name"
                      className="invalid-feedback col s10 offset-s1"
                    />
                  </div>
                  <div className="row">
                    <div
                      className={`input-field col s10 offset-s1  ${
                        touched.username && errors.username ? "is-invalid" : ""
                      }`}
                    >
                      <Field
                        className={`input-bordered no-padding  ${
                          touched.username && errors.username
                            ? "is-invalid"
                            : ""
                        }`}
                        type="text"
                        name="username"
                        placeholder="Your Username"
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      name="username"
                      className="invalid-feedback col s10 offset-s1"
                    />
                  </div>
                  <div className="row">
                    <div
                      className={`input-field col s10 offset-s1  ${
                        touched.email && errors.email ? "is-invalid" : ""
                      }`}
                    >
                      <Field
                        className={`input-bordered no-padding ${
                          touched.email && errors.email ? "is-invalid" : ""
                        }`}
                        type="email"
                        name="email"
                        placeholder="Email Address"
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      name="email"
                      className="invalid-feedback col s10 offset-s1"
                    />
                  </div>
                  <div className="row">
                    <div
                      className={`input-field col s10 offset-s1  ${
                        touched.mobile && errors.mobile ? "is-invalid" : ""
                      }`}
                    >
                      <Field
                        name="mobile"
                        type="number"
                        className={`input-bordered no-padding ${
                          touched.mobile && errors.mobile ? "is-invalid" : ""
                        }`}
                        placeholder="Mobile Number"
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      name="mobile"
                      className="invalid-feedback col s10 offset-s1"
                    />
                  </div>
                  <div className="row">
                    <div
                      className={`input-field col s10 offset-s1 password-eye-wrapper  ${
                        touched.password && errors.password ? "is-invalid" : ""
                      }`}
                    >
                      <Field
                        type={`${passwordEye.password ? "text" : "password"}`}
                        className={`input-bordered no-padding ${
                          touched.password && errors.password
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Password"
                        name="password"
                      />
                      <div className="password-eye" onClick={handlePasswordEye}>
                        {passwordEye.password ? (
                          <>
                            <i className="mdi mdi-eye-off-outline"></i>
                          </>
                        ) : (
                          <>
                            <i className="mdi mdi-eye-outline"></i>
                          </>
                        )}
                      </div>
                    </div>
                    <ErrorMessage
                      component="div"
                      name="password"
                      className="invalid-feedback col s10 offset-s1"
                    />
                  </div>
                  {/* <div className="row">
                    <div
                      className={`input-field col s10 offset-s1 password-eye-wrapper  ${
                        touched.confirm_password && errors.confirm_password
                          ? "is-invalid"
                          : ""
                      }`}
                    >
                      <Field
                        type={`${
                          passwordEye.confirm_password ? "text" : "password"
                        }`}
                        className={`input-bordered no-padding ${
                          touched.confirm_password && errors.confirm_password
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Confirm Password"
                        name="confirm_password"
                      />
                      <div className="password-eye" onClick={handleConfirmPasswordEye}>
                        {passwordEye.confirm_password ? (
                          <>
                            <i className="mdi mdi-eye-off-outline"></i>
                          </>
                        ) : (
                          <>
                            <i className="mdi mdi-eye-outline"></i>
                          </>
                        )}
                      </div>
                    </div>
                    <ErrorMessage
                      component="div"
                      name="confirm_password"
                      className="invalid-feedback col s10 offset-s1"
                    />
                  </div> */}
                  <div class="row center">
                    <button
                      className="waves-effect waves-light btn-large bg-primary"
                      type="submit"
                      disabled={props.signup.buttonDisable}
                    >
                      {props.signup.loadingButtonContent !== null
                        ? props.signup.loadingButtonContent
                        : "Register"}
                    </button>
                    <div class="spacer"></div>
                    <div class="links">
                      <Link to={"/login"}>Already Registered? Login</Link>{" "}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  signup: state.users.registerInputData,
  usernameAva: state.users.usernameAva,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Register);
