import React, { useState } from "react";
import AuthHeader from "./Header/AuthHeader";
import { Notify } from "react-redux-notify";
import AuthTabBar from "./Header/AuthTabBar";
import AuthFooter from "./Footer/AuthFooter";
import { Helmet } from "react-helmet";

const AuthLayout = (props) => {

  const [hideTab, setHideTab] = useState(false);
  
  return (
    <>
      <>
        <Notify position="TopRight" />
        <AuthHeader locationState={props.locationState}/>
        <AuthTabBar hideTab={hideTab}/>
        <div className="landing-main-wrapper">
          {React.cloneElement(props.children ,{hideTab : hideTab , setHideTab : setHideTab})}
        </div>
        <AuthFooter hideTab={hideTab}/>
      </>
    </>
  );
};


export default AuthLayout;
