import React, { useRef, useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  fetchCommentRepliesStart,
  saveCommentReplyStart,
  deleteReplyCommentStart,
} from "../../store/actions/CommentsAction";
import { connect } from "react-redux";
import ReplyComments from "./ReplyComments";
import CustomSvgIcon from "../helper/CustomSvgIcon";

const PostComments = (props) => {
  const { comment } = props;

  const [replies , setReplies] = useState({
    total_replies : null
  })

  const commentReplySchema = Yup.object().shape({
    reply: Yup.string().required("required *"),
  });

  const handleReplyCommentShow = (comment_id) => {
    props.setComments({
      ...props.commentsState,
      showReplay: comment_id,
    });
  };

  const handleCommentSubmit = (values) => {
    props.dispatch(
      saveCommentReplyStart({
        reply: values.reply,
        post_id: props.comment.post_id,
        post_comment_id: props.comment.post_comment_id,
      })
    );
  };

  const handleReplyDeleteComment = (post_id, reply_comment_id) => {
    props.dispatch(
      deleteReplyCommentStart({
        post_id: post_id,
        post_comment_reply_id: reply_comment_id,
      })
    );
  };

  useEffect(() => {
    setReplies({
      ...replies,
      total_replies: props.comment.total_replies,
    });
  },[props.comment])

  useEffect(() => {
    if (!props.saveComment.loading) {
      if (props.saveComment.data.post_id == props.comment.post_id) {
        props.setComments({
          ...props.commentsState,
          commentCount: props.saveComment.data.total_comments,
        });
      }
    }
  }, [props.saveComment.data]);
  

  useEffect(() => {
    if (!props.delComment.loading) {
      if (props.delComment.data.post_id == props.comment.post_id) {
        props.setComments({
          ...props.commentsState,
          commentCount: props.delComment.data.total_comments,
        });
      }
    }
  }, [props.delComment.data]);

  useEffect(() => {
    if (!props.commentReplies.loading) {
      if (props.commentReplies.data.post_comment_replies.length > 0 && props.commentReplies.data.post_comment_id == props.comment.post_comment_id) {
        setReplies({
          ...replies,
          total_replies: props.commentReplies.data.post_comment_replies.length,
        });
      }
    }
  }, [props.commentReplies.data]);

  return (
    <>
      <div
        className={`post-comments my-2 ${
          replies.total_replies > 0
            ? "has-replies"
            : props.commentsState.showReplay == comment.post_comment_id
            ? "has-replies"
            : ""
        } ${
          props.commentsState.showReplay == comment.post_comment_id
            ? "active"
            : ""
        }`}
      >
        <div className="post-comment">
          <div className="comment-user">
            <img src={comment.user_picture} alt={comment.username} />
            <h6 className="user-name-mobile">{comment.username}</h6>
          </div>
          <div className="comments">
            <div className="comments-wrapper">
              <div className="commenter-name">
                <p className="user-name">{comment.username}</p>
                <span className="ml-auto align-center">{comment.created}</span>
              </div>
              <p className="comment">{comment.comment}</p>
              {comment.user_id == localStorage.getItem("userId") && (
                <div className="comment-action">
                  <button
                    className="no-btn comment-action-btn reply"
                    onClick={() =>
                      handleReplyCommentShow(comment.post_comment_id)
                    }
                  >
                    Reply
                    <i class="mdi mdi-reply"></i>
                  </button>
                  <button
                    className="no-btn comment-action-btn delete"
                    onClick={() =>
                      props.handleDeleteComment(comment.post_comment_id)
                    }
                  >
                    Delete
                    <i class="mdi mdi-delete"></i>
                  </button>
                </div>
              )}
              {comment.user_id != localStorage.getItem("userId") && (
                <div className="comment-action">
                  <button
                    className="no-btn comment-action-btn reply"
                    onClick={() =>
                      handleReplyCommentShow(comment.post_comment_id)
                    }
                  >
                    Reply
                    <i class="mdi mdi-reply"></i>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {!props.commentReplies.loading && props.commentsState.showReplay == comment.post_comment_id && (
          <>
            {comment.total_replies > 0 && (
              <span
                className="total-replies"
                onClick={() => handleReplyCommentShow(false)}
              >
                <CustomSvgIcon id="replies" styles="replies-icon" />
                Close replies
              </span>
            )}
          </>
        )}
        {props.commentsState.showReplay == comment.post_comment_id &&
          !props.commentReplies.loading &&
          props.commentReplies.data.post_comment_replies.length > 0 && (
            <>
              <div className="post-reply-comment-wrapper">
                {props.commentReplies.data.post_comment_replies.map(
                  (replycomment, index) => (
                    <ReplyComments
                      replycomment={replycomment}
                      handleReplyDeleteComment={handleReplyDeleteComment}
                      key={index}
                      comments={props.comments}
                    />
                  )
                )}
              </div>
            </>
          )}
        {props.commentsState.showReplay != comment.post_comment_id && (
          <>
            {replies.total_replies > 0 && (
              <span
                className="total-replies"
                onClick={() => handleReplyCommentShow(comment.post_comment_id)}
              >
                <CustomSvgIcon id="replies" styles="replies-icon" />
                View {replies.total_replies} {replies.total_replies <= 1 ? "reply" : "replies"} 
              </span>
            )}
          </>
        )}
        {props.commentsState.showReplay == comment.post_comment_id && (
          <>
            <div className="mt-2 post-reply-comment-wrapper">
              <Formik
                initialValues={{ reply: "" }}
                validationSchema={commentReplySchema}
                onSubmit={async (values, { resetForm }) => {
                  await handleCommentSubmit(values);
                  resetForm();
                }}
              >
                {({ touched, errors, isSubmitting }) => (
                  <Form noValidate>
                    <div className="reply-comment-reply-box">
                      <div className="row d-flex align-items-center m-0">
                        <div
                          className={`input-field  w-100 reply-comment-input ${
                            touched.reply && errors.reply ? "is-invalid" : ""
                          }`}
                        >
                          <Field
                            type="email"
                            name="reply"
                            className={`validate ${
                              touched.reply && errors.reply ? "is-invalid" : ""
                            }`}
                            placeholder="Please Type a reply"
                          />
                        </div>
                        <div className="ml-auto center mb-0">
                          <button
                            type="submit"
                            className="waves-effect waves-light btn-large bg-pri  mary round-btn-icon m-0"
                            disabled={props.saveComment.buttonDisable}
                          >
                            <i className="mdi mdi-send"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  commentReplies: state.comment.commentReplies,
  comments: state.comment.comments,
  saveComment: state.comment.saveComment,
  delComment: state.comment.delComment,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PostComments);
