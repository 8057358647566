import React, { useState } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const ThankYouIndex = (props) => {
  return (
    <>
      {/* <div className="thankyou-sec">
        <h1>Thank You</h1>
        <div className="pyro">
          <div className="before"></div>
          <div className="after"></div>
        </div>
        <a
          type="button"
          className="waves-effect waves-light btn-large bg-primary mt-3 mt-md-0"
          href="/"
        >
          Go Home
        </a>
      </div> */}
      <div className="content-area">
        <div className="login-bg thankyou-bg"></div>
        <div className="container login-area register">
          <div className="section pb0">
              <div className="vertical-center-div">
                <h3 class="bot-20 white-text">Thank you</h3>
                <p>for registering with us !</p>
              <a
          type="button"
          className="thank-you-btn"
          href="/"
        >
          Head on over to our website 
            <img
                src={window.location.origin + "/assets/images/thank-you/thumb-icon.png"}
                alt=""
                className="thank-you-icon"
              />
        </a>
              </div>
            </div>
            </div>
            </div>
    </>
  );
};

export default ThankYouIndex;
