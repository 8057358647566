import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LogoutConfirmation from "../helper/LogoutConfirmation";

const ProfileIndex = () => {
  const [logoutModal, setLogoutModal] = useState(false);

  const handlelogoutModalOpen = () => {
    setLogoutModal(true);
  };

  const handlelogoutModalClose = () => {
    setLogoutModal(false);
  };

  return (
    <>
      <div className="content-area">
        <div className="pagehead-bg   primary-bg"></div>
        <div className="container has-pagehead is-pagetitle">
          <div className="section">
            <h5 className="pagetitle">Account</h5>
          </div>
        </div>
        <div className="container full subpage over">
          <div className="section">
            <ul className="subpages collection">
              <li className="collection-item">
                <Link to={"/profile"} className="waves-effect">
                  <i className="mdi mdi-account-heart "></i>
                  <span>Profile</span>
                  <i className="arrow mdi mdi-chevron-right"></i>
                </Link>
              </li>
              <li className="collection-item">
                <Link to={"/edit-profile"} className="waves-effect">
                  <i className="mdi mdi-account-edit "></i>
                  <span>Edit Profile</span>
                  <i className="arrow mdi mdi-chevron-right"></i>
                </Link>
              </li>
              <li className="collection-item">
                <Link to={"/post-list"} className="waves-effect">
                  <i className="mdi mdi-cards-outline "></i>
                  <span>Your Posts</span>
                  <i className="arrow mdi mdi-chevron-right"></i>
                </Link>
              </li>
              <li className="collection-item">
                <Link to={"/purchased-list"} className="waves-effect">
                  <i className="mdi  mdi-shopping "></i>
                  <span>Your Purchase</span>
                  <i className="arrow mdi mdi-chevron-right"></i>
                </Link>
              </li>
              <li className="collection-item">
                <Link to={"/chat"} className="waves-effect">
                  <i className="mdi mdi-message-text-outline "></i>
                  <span>Chat</span>
                  <i className="arrow mdi mdi-chevron-right"></i>
                </Link>
              </li>
              <li className="collection-item">
                <Link to={"/change-password"} className="waves-effect">
                  <i className="mdi mdi-view-carousel "></i>
                  <span>Change Password</span>
                  <i className="arrow mdi mdi-chevron-right"></i>
                </Link>
              </li>
              <li className="collection-item">
                <Link to={"/delete-account"} className="waves-effect">
                  <i className="mdi mdi-notification-clear-all "></i>
                  <span>Delete Account</span>
                  <i className="arrow mdi mdi-chevron-right"></i>
                </Link>
              </li>
              <>
                <li className="collection-item">
                  <Link
                    to={"#"}
                    onClick={handlelogoutModalOpen}
                    className="waves-effect"
                  >
                    <i className="mdi mdi-logout "></i>
                    <span>Logout</span>
                    <i className="arrow mdi mdi-chevron-right"></i>
                  </Link>
                </li>
              </>
            </ul>
          </div>
        </div>
      </div>
      <LogoutConfirmation
        show={logoutModal}
        handlelogoutModalClose={handlelogoutModalClose}
      />
      <div id="modal1" class="modal bottom-sheet">
        <div class="modal-content">
          <h4>Modal Header</h4>
          <p>A bunch of text</p>
        </div>
        <div class="modal-footer">
          <a href="#!" class="modal-close waves-effect waves-green btn-flat">
            Agree
          </a>
        </div>
      </div>
    </>
  );
};

export default ProfileIndex;
