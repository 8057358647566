import React from "react";
import {Modal } from "react-bootstrap";
import {Link} from 'react-router-dom'

const LoginPopup = (props) => {
  return (
    <>
      <Modal
        className="modal-dialog-center custom-modal logout-confirmation"
        size="sm"
        centered
        show={props.show}
        onHide={props.hide}
      >
        <Modal.Body>
          <p className="text-center mb-3">Login to Continue..!</p>
          <div className="logout-confirmation-btn-wrapper">
            <Link className="waves-effect waves-light btn-large bg-primary m-0" to={"/login"}>
                Login
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default LoginPopup
