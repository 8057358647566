import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import NoDataFound from "../helper/NoDataFound";
import UserPosts from "../post/UserPosts";
import { connect } from "react-redux";
import {
  fetchHomePostsStart,
  loadMoreHomePostsStart,
} from "../../store/actions/HomeAction";
import InfiniteScroll from "../helper/InfiniteScroll";
import TopStoreOwnerCard from "./TopStoreOwnerCard";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import RatingReviewModal from "../helper/RatingReviewModal";
import qs from "query-string";
import { useHistory } from "react-router";
import ReactPlayer from "react-player";
import {
  fetchExploreStart,
} from "../../store/actions/PostAction";
import ExplorePostCard from "../explore/ExplorePostCard";
import { savePostLikeStart } from "../../store/actions/PostLikesAction";
import FilterIndex from "./FilterIndex";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const HomePageIndex = (props) => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    pauseOnHover: false,
  };

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
  };

  const queryParam = qs.parse(props.location.search);

  const history = useHistory();

  const [ratingModal, setRatingModal] = useState({
    modalFlag: false,
    post_id: null,
  });

  const [inputData, setInputData] = useState({
    type: "all", skip: 0, take: 12
  });

  const [buyProduct, setBuyProduct] = useState({
    post_id: "",
  });

  useEffect(() => {
    props.dispatch(fetchHomePostsStart(inputData));
  }, []);

  useEffect(() => {
    props.dispatch(fetchExploreStart());
  }, []);

  const handleRatingModalOpen = (post_id) => {
    setRatingModal({
      ...ratingModal,
      modalFlag: true,
      post_id: post_id,
    });
  };

  const handleRatingModalClose = () => {
    setRatingModal({
      ...ratingModal,
      modalFlag: false,
      post_id: null,
    });
  };

  const handleLike = (post_id) => {
    props.dispatch(savePostLikeStart({ post_id: post_id }));
  };

  const handleBuyProduct = (post_id) => {
    setBuyProduct({
      ...buyProduct,
      post_id: post_id,
    });
  };

  const getParameter = (from_user_id, to_user_id, post_id) => {
    const newQueryParam = {
      ...queryParam,
      from_user: from_user_id,
      to_user: to_user_id,
      post_id: post_id,
    };
    return newQueryParam;
  };

  useEffect(() => {
    if (!props.saveChatUser.loading) {
      if (
        props.saveChatUser.data.from_user_id == localStorage.getItem("userId")
      ) {
        const location = {
          pathname: `/chat/user/${props.saveChatUser.data.to_username}`,
          search: qs.stringify(
            getParameter(
              props.saveChatUser.data.from_user_id,
              props.saveChatUser.data.to_user_id,
              buyProduct.post_id
            )
          ),
        };
        history.push(location);
      }
    }
  }, [props.saveChatUser.data]);

  const getCategorySearchData = (event, category) => {
    event.preventDefault();
    props.dispatch(fetchExploreStart({ category_id: category.category_id }));
  };

  const getSearchData = (event) => {
    event.preventDefault();
    props.dispatch(fetchExploreStart({ [event.target.name]: [event.target.value] }));
  };

  const getSearchPriceRange = (min, max) => {
    props.dispatch(
      fetchExploreStart({
        'from_price': min,
        'to_price': max
      })
    );
  };

  return (
    <>
      <div className="content-area">
        <div className="home-slider">
          <Slider {...settings}>
            <div className="bg embed-responsive embed-responsive-16by9">
              <div>
                {/* <img
                  src={window.location.origin + "/assets/images/slider-1.png"}
                  alt=""
                /> */}
                <video
                  playsInline
                  autoPlay
                  muted
                  loop
                  preload="none"
                  className="video-size lazyload"
                  width="100%"
                  alt=""
                  loading="lazy"
                >
                  <source
                    src={window.location.origin + "/assets/videos/home/1.mp4"}
                    type="video/mp4"
                  ></source>
                </video>
              </div>
              <div className="content-wrapper center-align white-text">
                <div className="spacer-large"></div>
                <h3>INDZEE is an Elegant & Stylish PWA Mobile App</h3>
              </div>
            </div>
            <div className="bg embed-responsive embed-responsive-16by9">
              {/* <img
                src={window.location.origin + "/assets/images/slider-2.png"}
                alt=""
              /> */}
              <video
                playsInline
                autoPlay
                muted
                loop
                preload="none"
                className="video-size lazyload"
                width="100%"
                alt=""
                loading="lazy"
              >
                <source
                  src={window.location.origin + "/assets/videos/home/2.mp4"}
                  type="video/mp4"
                ></source>
              </video>
              <div className="item-content center-align white-text">
                <div className="spacer-large"></div>
                <h3>A premium multi purpose PWA Mobile UI App</h3>
              </div>
            </div>
            <div className="bg embed-responsive embed-responsive-16by9">
              {/* <img
                src={window.location.origin + "/assets/images/slider-3.png"}
                alt=""
              /> */}
              <video
                playsInline
                autoPlay
                muted
                loop
                preload="none"
                className="video-size lazyload"
                width="100%"
                alt=""
                loading="lazy"
              >
                <source
                  src={window.location.origin + "/assets/videos/home/3.mp4"}
                  type="video/mp4"
                ></source>
              </video>
              <div className="item-content center-align white-text">
                <div className="spacer-large"></div>
                <h3>INDZEE is fast, secure and user friendly UI App</h3>
              </div>
            </div>
            <div className="bg embed-responsive embed-responsive-16by9">
              {/* <img
                src={window.location.origin + "/assets/images/slider-4.png"}
                alt=""
              /> */}
              <video
                playsInline
                autoPlay
                muted
                loop
                preload="none"
                className="video-size lazyload"
                width="100%"
                alt=""
                loading="lazy"
              >
                <source
                  src={window.location.origin + "/assets/videos/home/4.mp4"}
                  type="video/mp4"
                ></source>
              </video>
              <div className="item-content center-align white-text">
                <div className="spacer-large"></div>
                <h3>A modern looking responsive PWA Mobile App</h3>
              </div>
            </div>
            {/**<div className="bg player-wrapper">
              <ReactPlayer
                url="/assets/videos/home/1.mp4"
                width="100%"
                height="100%"
                className="indzee-player"
                playsinline={true}
                muted={true}
                autoPlay={true}
                playing={true}
                loop={true}
                controls={false}
              />
            </div>
            <div className="player-wrapper">
              <ReactPlayer
                url="/assets/videos/home/2.mp4"
                width="100%"
                height="100%"
                className="indzee-player"
                muted={true}
                autoPlay={true}
                playsinline={true}
                playing={true}
                loop={true}
                controls={false}
              />
            </div>
            <div className="player-wrapper">
              <ReactPlayer
                url="/assets/videos/home/3.mp4"
                className="indzee-player"
                width="100%"
                height="100%"
                muted={true}
                autoPlay={true}
                playsinline={true}
                playing={true}
                loop={true}
                controls={false}
              />
            </div>
            <div className="player-wrapper">
              <ReactPlayer
                url="/assets/videos/home/4.mp4"
                className="indzee-player"
                width="100%"
                height="100%"
                muted={true}
                autoPlay={true}
                playsinline={true}
                playing={true}
                loop={true}
                controls={false}
              />
            </div> */}
          </Slider>
        </div>
        <FilterIndex
          getCategorySearchData={getCategorySearchData}
          getSearchData={getSearchData}
          getSearchPriceRange={getSearchPriceRange}
        />
        <div className="theme-bg">
          <div className="container home-iconbox">
            <div className="section pb0 pt-100">
              {props.explorePosts.loading ? (
                <div className="row mb0">
                  <div className="col s12 pad-0 pt-0">
                    <div className="center">
                      <Skeleton width={"200px"} height={30} />
                    </div>
                    <div className="ui-mediabox top-profile explore-index-grid">
                      {[...Array(6)].map(() =>
                        <Skeleton className="product-loader" />
                      )}
                    </div>
                  </div>
                </div>
              ) : props.explorePosts.total > 0 ? (
                <>
                  <div className="row mb0">
                    <div className="col s12 pad-0 pt-0">
                      <h5 className="bot-20 sec-tit center mt-0 primary-text">
                        TOP POSTS
                      </h5>
                      <div className="ui-mediabox top-profile explore-index-grid ">
                        {props.explorePosts.data
                          .filter((post) => post.postFiles.length > 0)
                          .map((post, index) => (
                            <>
                              {index < 9 && (
                                <ExplorePostCard
                                  post={post}
                                  key={index}
                                  handleLike={handleLike}
                                  postLike={props.postLike}
                                />
                              )}
                            </>
                          ))}
                      </div>
                      {props.explorePosts.total > 9 && (
                        <>
                          <div className="text-center mt-3">
                            <Link
                              type="submit"
                              className="waves-effect waves-light btn-large bg-primary"
                              to={"/see-all/posts"}
                            >
                              See All
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <NoDataFound message="No Store OwnerFound" />
              )}
            </div>
          </div>
        </div>
        {props.posts.loading ? null : (
          <>
            <InfiniteScroll
              fetchAction={loadMoreHomePostsStart}
              fetchData={props.posts}
              skip={props.posts.data.length}
              take={12}
              skippedFetch={true}
              total={props.posts.total}
              dispatchData={{ type: "all" }}
            >
              {props.posts.data.length > 0 ? (
                <div className="container ">
                  <div className="section">
                    <ul className="collection mailbox activity z-depth-0 ">
                      {props.posts.data
                        .filter((post) => post.status == 1)
                        .map((post, index) => (
                          <UserPosts
                            post={post}
                            key={index}
                            location={props.location}
                            history={props.history}
                            handleRatingModalOpen={handleRatingModalOpen}
                            handleBuyProduct={handleBuyProduct}
                            homePage={true}
                          />
                        ))}
                    </ul>
                  </div>
                </div>
              ) : (
                <>
                  <div className="no-home-post">
                    <NoDataFound message="Follow other profiles to view the post on the wall" />
                  </div>
                </>
              )}
            </InfiniteScroll>
            {props.posts.total != 0 &&
              props.posts.data.length == props.posts.total && (
                <NoDataFound message="No More Post to show" />
              )}
          </>
        )}
      </div>
      <RatingReviewModal
        show={ratingModal.modalFlag}
        handleRatingModalClose={handleRatingModalClose}
        post_id={ratingModal.post_id}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  posts: state.home.homePost,
  saveChatUser: state.chat.saveChatUser,
  explorePosts: state.post.explorePosts,
  postLike: state.postLike,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(HomePageIndex);
