import React from "react";
import { Modal } from "react-bootstrap";

const RatingReviewViewerModal = (props) => {
  return (
    <>
      <Modal
        className="modal-dialog-center custom-modal rating-modal"
        size="md"
        centered
        show={props.show}
        onHide={props.handleViewRatingModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reviews for {props.username}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="viewratingreview">
          <div className="user-ratings-view">
            {props.reviewData.length > 0 &&
              props.reviewData.map((review, index) => (
                <>
                  <div className="review" key={index}>
                    <div className="user-info">
                      <img src={review.user_picture} alt="" />
                      <div>
                        <h6>{review.username}</h6>
                        <p className="review-desc">{review.review}</p>
                      </div>
                      <div className="rating">
                        <i
                          className={`mdi mdi-star ${
                            review.ratings <= 1
                              ? "bad"
                              : review.ratings <= 3
                              ? "average"
                              : "good"
                          }`}
                        ></i>
                        {review.ratings}
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RatingReviewViewerModal;
