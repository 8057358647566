import React, { useEffect, useState } from "react";
import {
  postAvailableStatusUpdateStart,
  deletePostStart,
} from "../../store/actions/PostAction";
import NoDataFound from "../helper/NoDataFound";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const PostListItem = (props) => {
  const { post } = props;

  const [availableStatus, setAvailableStatus] = useState(post.is_available);

  const [skipInitalRender, setSkipInitialRender] = useState(false);

  const handlePostAvailableChange = (id, status, user_id) => {
    props.dispatch(
      postAvailableStatusUpdateStart({
        post_id: id,
        is_available: status,
        product_purchased_user_id: user_id,
      })
    );
    props.setLoadingState(true);
  };

  useEffect(() => {
    if (!props.postAvailableStatusUpdate.loading && skipInitalRender) {
      if (post.post_id == props.postAvailableStatusUpdate.data.post.post_id) {
        props.setLoadingState(false);
        setAvailableStatus(
          props.postAvailableStatusUpdate.data.post.is_available == 1
            ? "available"
            : "sold"
        );
      }
    }
    setSkipInitialRender(true);
  }, [props.postAvailableStatusUpdate.data]);

  const handleModalOpen = (post_id) => {
    props.setSearchUserModal({
      ...props.searchUserModal,
      modalStatus: true,
      post_id: post_id,
    });
  };

  return (
    <>
      <tr>
        <td className="post-list-img-sec">
          {post.postFiles.length > 0 ? (
            <>
              {post.postFiles[0].file_type == "image" ? (
                <>
                  <img
                    src={post.postFiles[0].post_file}
                    className="post-list-img max-width-100"
                  />
                </>
              ) : (
                post.postFiles[0].file_type == "video" && (
                  <>
                    <video
                      autoplay
                      controls
                      id="myVideo"
                      className="user-profile1 w-100"
                    >
                      <source
                        src={post.postFiles[0].post_file}
                        type="video/mp4"
                      />
                    </video>
                  </>
                )
              )}
            </>
          ) : (
            <NoDataFound message="No image" />
          )}
        </td>
        <td>{post.title}</td>
        <td className="content">{post.content}</td>
        <td>{post.post_type == 1 ? "Image" : "Product"}</td>
        <td>{post.status == 1 ? "Approved" : "Pending"}</td>
        <td>{post.post_type == 1 ? "-" : post.is_available}</td>
        <td>{post.created}</td>
        <td>
          <div className="post-action-wrapper">
            <Link
              className="waves-effect waves-light btn bg-primary m-0"
              to={`/post/${post.post_unique_id}`}
            >
              View
            </Link>
            {post.post_type == 2 ? (
              availableStatus == "available" && (
                <>
                  <Link
                    className="waves-effect waves-light btn bg-primary m-0"
                    to={`/edit-post/${post.post_unique_id}`}
                  >
                    Edit
                  </Link>
                </>
              )
            ) : (
              <>
                <Link
                  className="waves-effect waves-light btn bg-primary m-0"
                  to={`/edit-post/${post.post_unique_id}`}
                >
                  Edit
                </Link>
              </>
            )}
            <button
              className="waves-effect waves-light btn bg-danger m-0"
              type="button"
              onClick={() =>
                props.handleDeletPostConfirmationOpen(post.post_id)
              }
            >
              <i className="mdi mdi-delete"></i>
            </button>
            {post.post_type == 2 && post.status == 1 ? (
              availableStatus == "available" ? (
                <>
                  <button
                    type="button"
                    className="waves-effect waves-light btn bg-primary m-0"
                    disabled={
                      props.loadingState
                        ? props.postAvailableStatusUpdate.buttonDisable
                        : false
                    }
                    // onClick={() => handlePostAvailableChange(post.post_id, 0)}
                    onClick={() => handleModalOpen(post.post_id)}
                  >
                    {props.loadingState
                      ? props.postAvailableStatusUpdate.loadingButtonContent
                      : "Mark as Sold Out"}
                  </button>
                </>
              ) : (
                <>
                  {/* <button
                  type="button"
                  className="waves-effect waves-light btn bg-primary m-0"
                  disabled={loadingState ? props.postAvailableStatusUpdate.buttonDisable : false}
                  onClick={() => handlePostAvailableChange(post.post_id, 1)}
                >
                  {loadingState
                    ? props.postAvailableStatusUpdate.loadingButtonContent
                    : "Remove Sold Out"}
                </button> */}
                  <button
                    type="button"
                    className="waves-effect waves-light btn bg-primary m-0"
                    disabled={true}
                  >
                    Sold Out
                  </button>
                </>
              )
            ) : null}
          </div>
        </td>
        
      </tr>
    </>
  );
};

const mapStateToPros = (state) => ({
  postAvailableStatusUpdate: state.post.postAvailableStatusUpdate,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PostListItem);
