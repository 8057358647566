import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  BECOME_STORE_OWNER_START,
  FETCH_TOP_STORE_OWNERS_START,
  STORE_OWNER_REVIEW_START,
  LOAD_MORE_TOP_STORE_OWNERS_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  becomeStoreOwnerSuccess,
  becomeStoreOwnerFailure,
  fetchTopStoreOwnersSuccess,
  fetchTopStoreOwnersFailure,
  storeOnwerReviewSuccess,
  storeOnwerReviewFailure,
} from "../actions/StoreOwnerAction";

import { fetchUserDetailsStart } from "../actions/UserAction";

function* becomeStoreOwnerAPI() {
  try {
    const response = yield api.postMethod("send_request_to_admin");

    if (response.data.success) {
      yield put(becomeStoreOwnerSuccess(response.data.data));
      yield put(fetchUserDetailsStart());
    } else {
      yield put(becomeStoreOwnerFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(becomeStoreOwnerFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchTopStoreOWnersAPI() {
  try {
    const inputData = yield select(
      (state) => state.storeOwner.topStoreOwners.inputData
    );

    const response = yield api.postMethod("store_owners_index", inputData);

    if (response.data.success) {
      yield put(fetchTopStoreOwnersSuccess(response.data.data));
    } else {
      yield put(fetchTopStoreOwnersFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchTopStoreOwnersFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* loadMoreTopStoreOWnersAPI(action) {
  try {
    const response = yield api.postMethod("store_owners_index", action.data);

    if (response.data.success) {
      yield put(fetchTopStoreOwnersSuccess(response.data.data));
    } else {
      yield put(fetchTopStoreOwnersFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchTopStoreOwnersFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* storeOwnerReviewAPI(action) {
  try {
    const response = yield api.postMethod(
      "store_owner_review_save",
      action.data
    );

    if (response.data.success) {
      yield put(storeOnwerReviewSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(storeOnwerReviewFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(storeOnwerReviewFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(BECOME_STORE_OWNER_START, becomeStoreOwnerAPI)]);
  yield all([
    yield takeLatest(FETCH_TOP_STORE_OWNERS_START, fetchTopStoreOWnersAPI),
  ]);
  yield all([yield takeLatest(STORE_OWNER_REVIEW_START, storeOwnerReviewAPI)]);
  yield all([
    yield takeLatest(LOAD_MORE_TOP_STORE_OWNERS_START, loadMoreTopStoreOWnersAPI),
  ]);
}
