import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  savePostStart,
  postFileUploadStart,
  postFileRemoveStart,
} from "../../store/actions/PostAction";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import {
  fetchCategoriesStart,
} from "../../store/actions/HomeAction";

const CreatePost = (props) => {
  const [postType, setPostType] = useState(2);

  const [fileType, setFileType] = useState(null);

  const [fileData, setFileData] = useState([]);

  const [uploadedFilesID, setUploadedFileID] = useState([]);

  useEffect(() => {
    props.dispatch(fetchCategoriesStart());
  }, []);

  const createPostSchema = Yup.object().shape({
    title: Yup.string().required("Title is required *"),
    post_type: Yup.string(),
    amount: Yup.string().when("post_type", {
      is: (post_type) => post_type == "true",
      then: Yup.string().required("Amount is required *"),
      otherwise: Yup.string(),
    }),
    content: Yup.string().required("Description is required *"),
    post_file: Yup.mixed().when("post_type", {
      is: (post_type) => post_type == "false",
      then: Yup.mixed().required("Image or video is required *"),
      otherwise: Yup.mixed(),
    }),
    // product_file: Yup.mixed().when("post_type", {
    //   is: (post_type) => post_type == false,
    //   then: Yup.mixed().required("Product image is required *"),
    //   otherwise: Yup.mixed(),
    // }),
    file_type: Yup.string().required("image or video is required *"),
  });

  const handleCreatePost = (values) => {
    let newData = {};
    var newDate = new Date();
    newData = {
      ...newData,
      amount: postType == 1 ? "" : values.amount,
      title: values.title,
      size: values.size,
      content: values.content,
      category_id: values.category_id,
      brand_type:values.brand_type,
      post_type: postType,
      post_file_id: uploadedFilesID.map((file) => file.file_id).toString(),
      publish_time: newDate,
    };
    props.dispatch(savePostStart(newData));
  };

  const handleChangeImageSingle = (event, fileType) => {
    if (event.currentTarget.type === "file") {
      let reader = new FileReader();
      let file = event.currentTarget.files[0];
      reader.onloadend = () => {
        setFileData([
          ...fileData,
          {
            previewImage: reader.result,
            file: file,
            file_type: file.type.match("image") ? "image" : "video",
            index: fileData.length + 1,
          },
        ]);
        props.dispatch(postFileUploadStart({ file: file }));
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const removeFileChange = (index) => {
    const removedData = fileData.filter((file) => file.index != index);
    const removedFile = uploadedFilesID[index - 1];
    setFileData(removedData);
    props.dispatch(
      postFileRemoveStart({
        file: removedFile.file_url,
        post_file_id: removedFile.file_id,
      })
    );
    const removedFileData = uploadedFilesID.filter((file) => file.file_id != removedFile.file_id);
    setUploadedFileID(removedFileData)
  };

  const handleChangeType = (type) => {
    setPostType(postType == 2 ? 1 : 2);
  };

  const handleFileTypeChange = (type) => {
    setFileType(type);
    setFileData([]);
    let removedFile = [];
    const removedFileData = uploadedFilesID.filter((file) => file.file_id != removedFile.file_id);
    for (let i = 0; i < uploadedFilesID.length; i++) {
      removedFile = uploadedFilesID[i]
      props.dispatch(
        postFileRemoveStart({
          file: removedFileData[i].file_url,
          post_file_id: removedFileData[i].file_id,
        })
      );
    }
    setUploadedFileID([])
  };

  useEffect(() => {
    if (!props.fileUpload.loading && props.fileUpload.data.post_file_id) {
      console.log(props.fileUpload.data);
      setUploadedFileID([
        ...uploadedFilesID,
        {
          file_id: props.fileUpload.data.post_file_id,
          file_url: props.fileUpload.data.file_url,
          index: uploadedFilesID.length,
        },
      ]);
    }
  }, [props.fileUpload.data]);

  return (
    <>
      <div className="content-area">
        <div className="pagehead-bg   primary-bg"></div>
        <div className="container has-pagehead is-pagetitle">
          <div className="section">
            <h5 className="pagetitle">Add New Post</h5>
          </div>
        </div>
        <div className="container over ">
          <div className="section">
            <div className="row mb0">
              <div className="col s12 pad-0">
                <h5 className="bot-20 sec-tit  ">Post Details</h5>
                {localStorage.getItem("store_owner") != 2 && (
                  <p className="note mb-0">
                    <span>Note :</span> To Become a store owner apply from
                    profile.
                  </p>
                )}
                {localStorage.getItem("store_owner") == 2 && (
                <Formik
                  enableReinitialize
                  initialValues={{
                    title: "",
                    post_type: false,
                    amount: "",
                    content: "",
                    post_file: "",
                    category_id: "",
                    brand_type:'',
                    // product_file: "",
                    file_type: "",
                  }}
                  validationSchema={createPostSchema}
                  onSubmit={(values) => handleCreatePost(values)}
                >
                  {({ touched, errors, setFieldValue, isSubmitting }) => (
                    <Form noValidate>
                      <div className="row">
                        <div className="input-field withicon col s12">
                          <i className="mdi mdi-calendar"></i>
                          <Field
                            className={`input-bordered no-padding  ${
                              touched.title && errors.title ? "is-invalid" : ""
                            }`}
                            type="text"
                            name="title"
                            placeholder="Post Title"
                          />
                        </div>
                        <ErrorMessage
                          component="div"
                          name="title"
                          className="invalid-feedback col s12"
                        />
                      </div>
                      <div className="">
                        <div
                          className="radio-select-post"
                          role="group"
                          aria-labelledby="my-radio-group"
                        >
                          <label className="post-image-display">
                            <input
                              type="radio"
                              name="file_type"
                              className="with-gap"
                              onChange={() => {
                                setFieldValue("file_type", "image");
                                handleFileTypeChange(1);
                              }}
                            />
                            <span>Image Post</span>
                          </label>
                          <label className="ml-3 post-product-display">
                            <input
                              type="radio"
                              name="file_type"
                              className="with-gap"
                              onChange={() => {
                                setFieldValue("file_type", "video");
                                handleFileTypeChange(2);
                              }}
                            />
                            <span>Video Post</span>
                          </label>
                        </div>
                        <ErrorMessage
                          component="div"
                          name="file_type"
                          className="invalid-feedback mt-0 mb-3"
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                        {props.categories.loading
                            ? "Loading..."
                        : props.categories.data.post_categories.length > 0
                            ? 
                          <div class="input-field withicon col s12">
                            <select
                              name="category_id"
                              id="category_id"
                              className={`input-bordered no-padding d-block ${
                                touched.category_id && errors.category_id
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={(event) => {
                                setFieldValue("category_id", event.target.value);
                              }}
                            >
                              <option value="">Category</option>
                              {props.categories.data.post_categories.map((category) => 
                                <option value={category.category_id}>{category.name}</option>
                              )}
                            </select>
                            <ErrorMessage
                              component="div"
                              name="token_type"
                              className="invalid-feedback col s12"
                            />
                          </div>
                          : ""}
                        </div>
                        <div className="col-md-4">
                          <div className="input-field withicon col s12">
                        
                            <select
                              name="brand_type"
                              id="brand_type"
                              className={`input-bordered no-padding d-block ${
                                touched.brand_type && errors.brand_type
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={(event) => {
                                setFieldValue("brand_type", event.target.value);
                              }}
                            >
                              <option value="">Product Condition</option>
                              <option value="SPARINGLY_USED">SPARINGLY USED</option>
                              <option value="USED">USED</option>
                              <option value="USED_ONCE">USED ONCE</option>
                              <option value="ALL_NEW">ALL NEW</option>
                            </select>
                            <ErrorMessage
                              component="div"
                              name="token_type"
                              className="invalid-feedback col s12"
                            />
                          </div>
                        </div>
                      </div>
                      {fileType != null && (
                        <div
                          className={`post-multi-wrapper ${
                            fileData.length > 0 && fileType == 1
                              ? "col s12 image"
                              : ""
                          }`}
                        >
                          {fileType != null ? (
                            fileType == 1 ? (
                              <>
                                <div className="post-img-sec">
                                  <div
                                    className={`file-field  input-field  center-file-button custom-round-file ${
                                      fileData.length > 0 ? "center" : ""
                                    }`}
                                  >
                                    <div className="btn">
                                      {/* {props.fileUpload.loading ? (
                                        <>
                                          <div class="file-loader">
                                            <div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div></div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <span>
                                          <i className="mdi mdi-plus-circle-outline"></i>
                                        </span>
                                      )} */}
                                      <span>
                                        <i className="mdi mdi-plus-circle-outline"></i>
                                      </span>
                                      <input
                                        type="file"
                                        name="post_file"
                                        accept="image/*"
                                        onChange={(event) => {
                                          handleChangeImageSingle(event);
                                          setFieldValue(
                                            "post_file",
                                            event.target.files[0]
                                          );
                                        }}
                                        disabled={
                                          props.fileUpload.buttonDisable
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <ErrorMessage
                                  component="div"
                                  name="post_file"
                                  className="invalid-feedback  mb-3"
                                />
                              </>
                            ) : (
                              <>
                                {fileData.length < 1 && (
                                  <>
                                    <div className="post-img-sec">
                                      <div
                                        className={`file-field  input-field  center-file-button custom-round-file ${
                                          fileData.length > 0 ? "center" : ""
                                        }`}
                                      >
                                        <div className="btn">
                                          <span>
                                            <i className="mdi mdi-plus-circle-outline"></i>
                                          </span>
                                          <input
                                            type="file"
                                            name="post_file"
                                            accept="video/*"
                                            onChange={(event) => {
                                              handleChangeImageSingle(event);
                                              setFieldValue(
                                                "post_file",
                                                event.target.files[0]
                                              );
                                            }}
                                            disabled={
                                              props.fileUpload.buttonDisable
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <ErrorMessage
                                      component="div"
                                      name="post_file"
                                      className="invalid-feedback mb-3"
                                    />
                                  </>
                                )}
                              </>
                            )
                          ) : null}
                          {/* mutiple image */}
                          {fileData.length > 0 && (
                            <>
                              {fileData.map((fileData, index) => (
                                <>
                                  {fileData.previewImage &&
                                  fileData.previewImage != "" &&
                                  fileData.file_type == "image" ? (
                                    <div
                                      className={`file-wrapper ${
                                        uploadedFilesID.filter(
                                          (uploadFile) =>
                                            uploadFile.index == index
                                        ).length > 0
                                          ? ""
                                          : props.fileUpload.loading ? "loading" : ""
                                      }`}
                                    >
                                      {uploadedFilesID.filter(
                                        (uploadFile) =>
                                          uploadFile.index == index
                                      ).length > 0 ? null : props.fileUpload.loading && (
                                        <>
                                          <div className="files-loader-wrapper">
                                            <div className="loader"></div>
                                          </div>
                                        </>
                                      )}
                                      <img
                                        src={fileData.previewImage}
                                        alt=""
                                        key={index}
                                      />
                                      {uploadedFilesID.filter(
                                        (uploadFile) =>
                                          uploadFile.index == index
                                      ).length > 0 && (
                                        <div
                                          className="file-remover"
                                          onClick={() =>
                                            removeFileChange(fileData.index)
                                          }
                                        >
                                          <i className="mdi mdi-delete text-danger"></i>
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <>
                                      <div
                                        className={`file-wrapper ${
                                          uploadedFilesID.filter(
                                            (uploadFile) =>
                                              uploadFile.index == index
                                          ).length > 0
                                            ? ""
                                            :  props.fileUpload.loading ? "loading" : ""
                                        }`}
                                      >
                                        {uploadedFilesID.filter(
                                          (uploadFile) =>
                                            uploadFile.index == index
                                        ).length > 0 ? null : props.fileUpload.loading && (
                                          <>
                                            <div className="files-loader-wrapper">
                                              <div className="loader"></div>
                                            </div>
                                          </>
                                        )}
                                        <video
                                          autoplay
                                          controls
                                          id="myVideo"
                                          className="user-profile1 w-100"
                                        >
                                          <source
                                            src={fileData.previewImage}
                                            type="video/mp4"
                                          />
                                        </video>
                                        {uploadedFilesID.filter(
                                          (uploadFile) =>
                                            uploadFile.index == index
                                        ).length > 0 && (
                                          <div
                                            className="file-remover"
                                            onClick={() =>
                                              removeFileChange(fileData.index)
                                            }
                                          >
                                            <i className="mdi mdi-delete text-danger"></i>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </>
                          )}
                        </div>
                      )}

                      {fileType != null ? (
                        fileType == 1 ? (
                          <>
                            <p className="note mb-3 ">
                              <span>Note :</span> Accepted Image Format
                              JPG,JPEG,PNG,BMP,etc.
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="note mb-3">
                              <span>Note :</span> Accepted Video Format MP4,MOV,AVI,MKV,etc.
                            </p>
                          </>
                        )
                      ) : null}
                      {localStorage.getItem("store_owner") == 2 && (
                        <div className="product-post-check">
                          <label className="ml-3 post-product-display">
                            <Field
                              type="checkbox"
                              name="post_type"
                              className="with-gap"
                              checked={true}
                              disabled
                              onChange={() => {
                                handleChangeType(2);
                                setFieldValue(
                                  "post_type",
                                  postType == 2 ? false : true
                                );
                              }}
                            />
                            <span>Product Post</span>
                          </label>
                        </div>
                      )}
                      {postType != null && postType == 2 && (
                        <>
                          <div className="row">
                            <div className="input-field withicon col s12">
                              <i className="mdi mdi-coin"></i>
                              <Field
                                className={`input-bordered no-padding  ${
                                  touched.amount && errors.amount
                                    ? "is-invalid"
                                    : ""
                                }`}
                                type="text"
                                name="amount"
                                placeholder="Amount"
                              />
                            </div>
                            <ErrorMessage
                              component="div"
                              name="amount"
                              className="invalid-feedback col s12"
                            />
                          </div>
                          <div className="row">
                            <h6>Size</h6>
                            <div
                              className="radio-select-post"
                              role="group"
                              aria-labelledby="my-radio-group"
                            >
                              <label className="post-image-display">
                                <input
                                  type="radio"
                                  name="size"
                                  className="with-gap"
                                  onChange={() => {
                                    setFieldValue("size", "XS");
                                  }}
                                />
                                <span>XS</span>
                              </label>
                              <label className="ml-3 post-product-display">
                                <input
                                  type="radio"
                                  name="size"
                                  className="with-gap"
                                  onChange={() => {
                                    setFieldValue("size", "S");
                                  }}
                                />
                                <span>S</span>
                              </label>
                              <label className="ml-3 post-product-display">
                                <input
                                  type="radio"
                                  name="size"
                                  className="with-gap"
                                  onChange={() => {
                                    setFieldValue("size", "M");
                                  }}
                                />
                                <span>M</span>
                              </label>
                              <label className="ml-3 post-product-display">
                                <input
                                  type="radio"
                                  name="size"
                                  className="with-gap"
                                  onChange={() => {
                                    setFieldValue("size", "L");
                                  }}
                                />
                                <span>L</span>
                              </label>
                              <label className="ml-3 post-product-display">
                                <input
                                  type="radio"
                                  name="size"
                                  className="with-gap"
                                  onChange={() => {
                                    setFieldValue("size", "XL");
                                  }}
                                />
                                <span>XL</span>
                              </label>
                            </div>
                            <ErrorMessage
                              component="div"
                              name="size"
                              className="invalid-feedback mt-0 mb-3"
                            />
                          </div>
                        </>
                      )}
                      <div className="row">
                        <div className="input-field withicon col s12 textarea">
                          <i className="mdi mdi-calendar-text "></i>
                          <Field
                            as="textarea"
                            className={`materialize-textarea ml-0 my-2 no-padding  ${
                              touched.content && errors.content
                                ? "is-invalid"
                                : ""
                            }`}
                            type="text"
                            name="content"
                            placeholder="Brief Description"
                          />
                        </div>
                        <ErrorMessage
                          component="div"
                          name="content"
                          className="invalid-feedback col s12"
                        />
                      </div>
                      <div class="row center">
                        <button
                          type="submit"
                          className="waves-effect waves-light btn-large bg-primary"
                          disabled={
                            props.savePost.buttonDisable ||
                            props.fileUpload.buttonDisable
                          }
                        >
                          {props.savePost.loadingButtonContent !== null
                            ? props.savePost.loadingButtonContent
                            : "Save Post"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  savePost: state.post.savePost,
  fileUpload: state.post.fileUpload,
  categories: state.home.categories,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CreatePost);
