import React, { useState, useEffect } from "react";

const ReplyComments = (props) => {
  const { replycomment } = props;

  return (
    <>
      <div className="post-reply-comment">
        <div className="reply-user">
          <img src={replycomment.user_picture} alt="" />
        </div>
        <div className="reply-post-comment">
          <div className="reply-comments">
            <div className="commenter-name"> 
              <p className="reply-user-name">{replycomment.username}</p>
              <span className="ml-auto">{replycomment.created}</span>
            </div>
            <p className="comment">{replycomment.reply}</p>
            {replycomment.user_id == localStorage.getItem("userId") && (
              <div className="comment-action">
                <button
                  className="no-btn comment-action-btn delete"
                  onClick={() =>
                    props.handleReplyDeleteComment(
                      replycomment.post_id,
                      replycomment.post_comment_reply_id
                    )
                  }
                >
                  Delete
                  <i class="mdi mdi-delete"></i>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReplyComments;
