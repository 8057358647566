import React from "react";
import { userLoginStart } from "../store/actions/UserAction";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const Login = (props) => {
  const handleLogin = (values) => {
    const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

    const newData = { ...values, user_timezone: timezone };

    props.dispatch(userLoginStart(newData));
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address format")
      .required("Email is required *"),
    password: Yup.string()
      .min(6, "Password must be 6 characters at minimum")
      .required("Password is required *"),
  });

  return (
    <>
      <div class="menu-close">
        <i class="mdi mdi-close"></i>
      </div>
      <div class="content-area">
        <div class="login-bg access-login"></div>
        <div class="container login-area">
          <div class="section">
            <h3 class="bot-20 center white-text">Login</h3>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={loginSchema}
              onSubmit={(values) => handleLogin(values)}
            >
              {({ touched, errors, isSubmitting }) => (
                <Form noValidate>
                  <div className="row">
                    <div
                      className={`input-field col s10 offset-s1 ${
                        touched.email && errors.email ? "is-invalid" : ""
                      }`}
                    >
                      <Field
                        type="email"
                        name="email"
                        className={`validate no-padding ${
                          touched.email && errors.email ? "is-invalid" : ""
                        }`}
                        placeholder="Your Email"
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      name="email"
                      className="invalid-feedback col s10 offset-s1"
                    />
                  </div>
                  <div className="row">
                    <div
                      className={`input-field col s10 offset-s1 ${
                        touched.password && errors.password ? "is-invalid" : ""
                      }`}
                    >
                      <Field
                        name="password"
                        type="password"
                        className={`validate no-padding ${
                          touched.password && errors.password
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Password"
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      name="password"
                      className="invalid-feedback col s10 offset-s1"
                    />
                  </div>
                  <div className="row center">
                    <div class="links text-center">
                      <Link to={"/forgot-password"}>Forgot Password</Link>
                      <span class="sep">|</span>
                      <Link to={"/register"}>Register</Link>{" "}
                    </div>
                    <div class="spacer"></div>
                    <button
                      type="submit"
                      disabled={props.login.buttonDisable}
                      className="waves-effect waves-light btn-large bg-primary"
                    >
                      {props.login.loadingButtonContent !== null
                        ? props.login.loadingButtonContent
                        : "Sign In"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  login: state.users.loginInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Login);
