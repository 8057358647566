import React from "react";
import {Modal } from "react-bootstrap";
import {Link} from 'react-router-dom'

const LogoutConfirmation = (props) => {
  return (
    <>
      <Modal
        className="modal-dialog-center custom-modal logout-confirmation"
        size="sm"
        centered
        show={props.show}
        onHide={props.handlelogoutModalClose}
      >
        <Modal.Body>
          <p className="text-center mb-3">Are you sure, Want to logout...?</p>
          <div className="logout-confirmation-btn-wrapper">
            <button
              type="button"
              className="waves-effect waves-light btn-large bg-primary cancel-btn m-0"
              onClick={() => props.handlelogoutModalClose()}
            >Cancel</button>
            <Link
              to={"/logout"}
              type="button"
              className="waves-effect waves-light btn-large bg-primary m-0"
            >
              Logout
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LogoutConfirmation;
