import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import NoDataFound from "../helper/NoDataFound";
import UserPosts from "../post/UserPosts";
import { connect } from "react-redux";
import { fetchHomePostsStart } from "../../store/actions/HomeAction";
import { fetchTopStoreOwnersStart } from "../../store/actions/StoreOwnerAction";
import InfiniteScroll from "../helper/InfiniteScroll";
import TopStoreOwnerCard from "./TopStoreOwnerCard";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import RatingReviewModal from "../helper/RatingReviewModal";
import qs from "query-string";
import { useHistory } from "react-router";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SeeAllStoreOwner = (props) => {
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: false,
  };

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
  };

  const queryParam = qs.parse(props.location.search);

  const history = useHistory();

  const [ratingModal, setRatingModal] = useState({
    modalFlag: false,
    post_id: null,
  });

  const [buyProduct, setBuyProduct] = useState({
    post_id: "",
  });

  useEffect(() => {
    props.dispatch(fetchHomePostsStart());
  }, []);

  useEffect(() => {
    props.dispatch(fetchTopStoreOwnersStart({ take: 100 }));
  }, []);

  const handleRatingModalOpen = (post_id) => {
    setRatingModal({
      ...ratingModal,
      modalFlag: true,
      post_id: post_id,
    });
  };

  const handleRatingModalClose = () => {
    setRatingModal({
      ...ratingModal,
      modalFlag: false,
      post_id: null,
    });
  };

  const handleBuyProduct = (post_id) => {
    setBuyProduct({
      ...buyProduct,
      post_id: post_id,
    });
  };

  const getParameter = (from_user_id, to_user_id, post_id) => {
    const newQueryParam = {
      ...queryParam,
      from_user: from_user_id,
      to_user: to_user_id,
      post_id: post_id,
    };
    return newQueryParam;
  };

  useEffect(() => {
    if (!props.saveChatUser.loading) {
      if (
        props.saveChatUser.data.from_user_id == localStorage.getItem("userId")
      ) {
        const location = {
          pathname: `/chat/user/${props.saveChatUser.data.to_username}`,
          search: qs.stringify(
            getParameter(
              props.saveChatUser.data.from_user_id,
              props.saveChatUser.data.to_user_id,
              buyProduct.post_id
            )
          ),
        };
        history.push(location);
      }
    }
  }, [props.saveChatUser.data]);

  return (
    <>
      <div className="content-area">
        <div className="theme-bg">
          <div className="container home-iconbox">
            <div className="section pb0 pt-0">
              {props.topStoreOwners.loading ? (
                <div className="row mb5">
                  <div className="col s12 pad-0 pt-0">
                    <div className="center">
                      <Skeleton width={"200px"} height={30} />
                    </div>
                    <div className="ui-mediabox top-profile explore-index-grid">
                      {[...Array(6)].map(() =>
                        <Skeleton className="product-loader" />
                      )}
                    </div>
                  </div>
                </div>
              ) : props.topStoreOwners.data.total > 0 ? (
                <>
                  <div className="row mb5">
                    <div className="col s12 pad-0 pt-0">
                      <h5 className="bot-20 sec-tit center mt-0">
                        All Sellers
                      </h5>
                      <div className="row ui-mediabox top-profile top-profile-grid ">
                        {props.topStoreOwners.data.users
                          .filter((owner) => owner.user_unique_id != "")
                          .map((storeOwner, index) => (
                            <>
                              <TopStoreOwnerCard
                                storeOwner={storeOwner}
                                key={index}
                              />
                            </>
                          ))}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <NoDataFound message="No Store OwnerFound" />
              )}
            </div>
          </div>
        </div>
      </div>
      <RatingReviewModal
        show={ratingModal.modalFlag}
        handleRatingModalClose={handleRatingModalClose}
        post_id={ratingModal.post_id}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  posts: state.home.homePost,
  topStoreOwners: state.storeOwner.topStoreOwners,
  saveChatUser: state.chat.saveChatUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SeeAllStoreOwner);
