import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { storeOnwerReviewStart } from "../../store/actions/StoreOwnerAction";

const RatingReviewModal = (props) => {
  const startCount = [1, 2, 3, 4, 5];

  const [rating, setRating] = useState(null);

  const [ratingHover, setRatingHover] = useState(null);

  const handleRatingReview = (values) => {
    const newData = {
      post_id: props.post_id,
      ...values,
    };
    props.dispatch(storeOnwerReviewStart(newData));
  };

  const ratingReviewSchema = Yup.object().shape({
    ratings: Yup.number().required("Rating is required *"),
    review: Yup.string().required("Review is required *"),
  });

  useEffect(() => {
    if (!props.storeOwnerReview.loading) {
      setRating(null);
      props.handleRatingModalClose();
    }
  }, [props.storeOwnerReview.data]);

  return (
    <>
      <Modal
        className="modal-dialog-center custom-modal rating-modal"
        size="md"
        centered
        show={props.show}
        onHide={props.handleRatingModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title> Rate and Review Store Owner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <h6 className="text-center border-bottom">
            Rate and Review Store Owner
          </h6> */}
          <Formik
            initialValues={{ ratings: "", review: "" }}
            validationSchema={ratingReviewSchema}
            onSubmit={(values) => handleRatingReview(values)}
          >
            {({ touched, errors, isSubmitting, setFieldValue }) => (
              <Form noValidate>
                <div className="row mb-0">
                  <div className="col s12 ">
                    <div className="rating-star-wrapper">
                      <h6>Rating : </h6>
                      {startCount.map((star, index) => (
                        <div className="star" key={index}>
                          <Field
                            className="rating-star"
                            type="checkbox"
                            name="rating"
                            onChange={() => {
                              setFieldValue("ratings", star);
                              setRating(rating == index + 1 ? null : index + 1);
                            }}
                            onMouseEnter={() => setRatingHover(index)}
                            onMouseLeave={() => setRatingHover(null)}
                          />
                          <i
                            className={`mdi mdi-star ${
                              rating != null
                                ? rating <= index
                                  ? ""
                                  : "active"
                                : ""
                            } ${
                              ratingHover != null
                                ? ratingHover <= index
                                  ? ""
                                  : "active"
                                : ""
                            }`}
                          ></i>
                        </div>
                      ))}
                    </div>
                    <ErrorMessage
                      component="div"
                      name="ratings"
                      className="invalid-feedback col s12 mt-0 mb-3"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="input-field withicon col s12 textarea m-0">
                    <i className="mdi mdi-calendar-text "></i>
                    <Field
                      as="textarea"
                      className={`materialize-textarea ml-0 my-2 no-padding  ${
                        touched.review && errors.review ? "is-invalid" : ""
                      }`}
                      type="text"
                      name="review"
                      placeholder="write a review..."
                    />
                  </div>
                  <ErrorMessage
                    component="div"
                    name="review"
                    className="invalid-feedback col s12"
                  />
                </div>
                <div className="button-wrapper">
                  <button
                    type="button"
                    className="waves-effect waves-light btn-large bg-primary cancel-btn m-0"
                    onClick={props.handleRatingModalClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="Submit"
                    className="waves-effect waves-light btn-large bg-primary m-0"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  storeOwnerReview: state.storeOwner.storeOwnerReview,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(RatingReviewModal);
