import { all, fork } from "redux-saga/effects";

import UserSaga from "./UserSaga";
import ChangePasswordSaga from "./ChangePasswordSaga";
import PageSaga from "./PageSaga";
import PostSaga from "./PostSaga";
import ErrorSaga from "./ErrorSaga";
import FollowSaga from "./FollowSaga";
import VerificationDocumentSaga from "./VerificationDocumentSaga";
import CommentsSaga from "./CommentsSaga";
import HomeSaga from "./HomeSaga";
import OtherUserSaga from "./OtherUserSaga";
import PostLikesSaga from "./PostLikesSaga";
import ChatSaga from "./ChatSaga";
import NotificationSaga from "./NotificationSaga";
import StoreOwnerSaga from './StoreOwnerSaga'

export default function* rootSaga() {
  yield all([fork(UserSaga)]);
  yield all([fork(ChangePasswordSaga)]);
  yield all([fork(ErrorSaga)]);
  yield all([fork(PageSaga)]);
  yield all([fork(PostSaga)]);
  yield all([fork(FollowSaga)]);
  yield all([fork(VerificationDocumentSaga)]);
  yield all([fork(CommentsSaga)]);
  yield all([fork(HomeSaga)]);
  yield all([fork(OtherUserSaga)]);
  yield all([fork(PostLikesSaga)]);
  yield all([fork(ChatSaga)]);
  yield all([fork(NotificationSaga)]);
  yield all([fork(StoreOwnerSaga)])
}
