import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "react-bootstrap";
import { translate } from "react-multi-lang";
import { connect } from "react-redux";
import NoDataFound from "./NoDataFound";

const InfiniteScroll = (props) => {
  const [isFetching, setIsFetching] = useState(false);

  const [initialRender, seTinitialRender] = useState(false);

  const [loadMore, setLoadMore] = useState(props.skip != props.total);

  const initalRenderRef = useRef();

  initalRenderRef.current = initialRender;

  const setScrollHeight = () => {

    const targetHeight = document.getElementById("InfiniteScroller").clientHeight;
    const difference = document.scrollingElement.scrollHeight - targetHeight;

    // dont delete this is for reference
    // window.scrollY + window.innerHeight > (document.scrollingElement.scrollHeight - 200) for full page scroll length
    //window.scrollY + window.innerHeight - difference > targetHeight - 200 for element target height based 

    if (
      window.scrollY + window.innerHeight - difference > targetHeight - 200
    ) {
      if (initalRenderRef) {
        if (props.skippedFetch) {
          if (loadMore) {
            setIsFetching(true);
          }
        } else {
          setIsFetching(true);
        }
      }
    }
  };

  useEffect(() => {
    seTinitialRender(true);

    window.addEventListener("scroll", setScrollHeight);

    return () => {
      window.removeEventListener("scroll", setScrollHeight);
    };
  }, []);

  useEffect(() => {
    if (!props.fetchData.loading) {
      setIsFetching(false);
      if (props.fetchData.data.length == props.total) {
        setLoadMore(false);
      }
    }
  }, [props.fetchData.data]);

  const overflowedParentHeight = () => {

    const targetHeight = document.getElementById("InfiniteScroller").scrollHeight;
    const difference = document.getElementById("InfiniteScroller").clientHeight;
    const scrolledheight = document.getElementById("InfiniteScroller").scrollTop + difference;

    if (scrolledheight > targetHeight - 50) {
      if (initalRenderRef) {
        if (props.skippedFetch) {
          if (loadMore) {
            setIsFetching(true);
          }
        } else {
          setIsFetching(true);
        }
      }
    }
  }

  useEffect(() => {
    if (isFetching) {
      if (initialRender) {
        if (props.skippedFetch) {
          if (loadMore) {
            if (props.dispatchData) {
              props.dispatch(
                props.fetchAction({ skip: props.skip, take: props.take, ...props.dispatchData })
              );
            } else {
              props.dispatch(
                props.fetchAction({ skip: props.skip, take: props.take })
              );
            }
          }
        } else {
          props.dispatch(props.fetchAction());
        }
      }
    }
  }, [isFetching]);

  return (
    <>
      <div id="InfiniteScroller" className={`${props.overflowClassName ? props.overflowClassName : ""}`} onScroll={props.parentOverflow ? overflowedParentHeight : null}>
        {props.children}
        {isFetching && loadMore && (
          props.loader ?
            <>
              {props.loader}
            </>
            : <div
              style={{ display: "grid", placeItems: "center", padding: "2em 0" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
        )}
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(translate(InfiniteScroll));
