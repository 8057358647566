import React, { useEffect, useState } from "react";
import { fetchSinglePostStart } from "../../store/actions/PostAction";
import { connect } from "react-redux";
import UserPostsSingle from "./UserPostsSingle";
import RatingReviewModal from "../helper/RatingReviewModal";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SinglePostView = (props) => {

  const [ratingModal, setRatingModal] = useState({
    modalFlag: false,
    post_id: null,
  });

  useEffect(() => {
    props.dispatch(
      fetchSinglePostStart({
        post_unique_id: props.match.params.id,
      })
    );
  }, []);

  const handleRatingModalOpen = (post_id) => {
    setRatingModal({
      ...ratingModal,
      modalFlag: true,
      post_id: post_id,
    });
  };

  const handleRatingModalClose = () => {
    setRatingModal({
      ...ratingModal,
      modalFlag: false,
      post_id: null,
    });
  };

  return (
    <>
      <div className="content-area">
        <div className="pagehead-bg   primary-bg"></div>
        <div className="container has-pagehead is-pagetitle">
          <div className="section">
            <h5 className="pagetitle">Single Post</h5>
          </div>
        </div>
        <div className="container over">
          <div className="section">
            <div className="row ui-mediabox blogs">
              {props.singlePost.loading ? (
                <>
                  <div class="d-flex">
                    <Skeleton
                      width={47}
                      className="circle-loader"
                      containerClassName="circle-loader-container" />
                    <Skeleton containerClassName="title-loader" width={100} />
                  </div>
                  <div className="full-image-loader-container">
                    <Skeleton className="full-image-loader" />
                  </div>
                </>
              ) : (
                <>
                  <UserPostsSingle
                    post={props.singlePost.data.post}
                    data={props.singlePost.data}
                    key={props.singlePost.data.post.post_unique_id}
                    location={props.location} history={props.history}
                    handleRatingModalOpen={handleRatingModalOpen}
                  />
                </>
              )}
            </div>
            {/* <div className="col-item col s12 ">
                    <h4 className="title ">
                      Making the best of your time today{" "}
                    </h4>
                    <div className="user-avatar mini">
                      <div className="maillink status available">
                        <img
                          src="assets/images/user-31.jpg"
                          alt="Rebeca Boult"
                          title="Rebeca Boult"
                          className="circle"
                        />
                        <span className="title">Rebeca Boult</span>
                        <span className="time">23 Feb 2020, Sun</span>
                      </div>
                    </div>
                    <div className="blog-img-wrap">
                      <a
                        className="img-wrap round"
                        href="assets/images/blog-101.jpg"
                        data-fancybox="images"
                        data-caption="Making the best of your time today"
                      >
                        <img
                          alt="image"
                          className="w-100"
                          src="assets/images/blog-101.jpg"
                        />
                      </a>
                    </div>
                    <div className="blog-info  ">
                      <p className="bot-0 text">
                        He couldn't move. His head throbbed and spun. He
                        couldn't decide if it was the flu or the drinking last
                        night. It was probably a combination of both. she'll
                        prove she can again. We all already know th...
                      </p>
                      
                      <div className="user-actions">
                        <a href="#!">
                          <i className="mdi mdi-thumb-up-outline"></i>
                          <span>435</span>
                        </a>{" "}
                        <a href="#!">
                          <i className="mdi mdi-heart-outline"></i>
                          <span>589</span>
                        </a>{" "}
                        <a href="#!" class="active">
                          <i className="mdi mdi-comment-outline"></i>
                          <span>984</span>
                        </a>
                      </div>
                      <div className="spacer"></div>
                      <div className="divider"></div>
                      <div className="spacer"></div>
                    </div>
                  </div>
            <div className="row ">
              <div className="col s12 pad-0">
                <h5 className="bot-20 sec-tit  ">Comment</h5>
                <div className="col s12">
                  <div className="row">
                    <div className="input-field col s12">
                      <i className="mdi mdi-face prefix"></i>
                      <input id="event_title11" type="text" />
                      <label for="event_title11">Name</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-field col s12">
                      <i className="mdi mdi-email-outline prefix"></i>
                      <input id="event_title112" type="text" />
                      <label for="event_title112">Email</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-field col s12 textarea">
                      <i className="mdi mdi-file-outline prefix"></i>
                      <textarea
                        id="textarea-prefix"
                        className="materialize-textarea"
                      ></textarea>
                      <label for="textarea-prefix">Brief Description</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="">
                      <a className="waves-effect waves-light btn bg-primary">
                        Submit Comment
                      </a>
                      &nbsp; &nbsp;
                      <a className="waves-effect waves-light btn btn-secondary">
                        <i className="mdi mdi-attachment"></i>
                      </a>
                      &nbsp; &nbsp;
                      <a className="waves-effect waves-light btn bg-secondary">
                        <i className="mdi mdi-format-color-text"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <RatingReviewModal
        show={ratingModal.modalFlag}
        handleRatingModalClose={handleRatingModalClose}
        post_id={ratingModal.post_id}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  singlePost: state.post.singlePost,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SinglePostView);
