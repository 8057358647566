import {
  BECOME_STORE_OWNER_START,
  BECOME_STORE_OWNER_SUCCESS,
  BECOME_STORE_OWNER_FAILURE,
  FETCH_TOP_STORE_OWNERS_START,
  FETCH_TOP_STORE_OWNERS_SUCCESS,
  FETCH_TOP_STORE_OWNERS_FAILURE,
  STORE_OWNER_REVIEW_START,
  STORE_OWNER_REVIEW_SUCCESS,
  STORE_OWNER_REVIEW_FAILURE,
  LOAD_MORE_TOP_STORE_OWNERS_START,
} from "../actions/ActionConstant";

const initialState = {
  storeOwner: {
    data: {},
    loading: false,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false,
  },
  topStoreOwners: {
    inputData: {},
    data: {},
    loading: false,
    error: false,
  },
  storeOwnerReview: {
    data: {},
    loading: false,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false,
  },
};

const StoreOwnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case BECOME_STORE_OWNER_START:
      return {
        ...state,
        storeOwner: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading...",
          buttonDisable: true,
        },
      };
    case BECOME_STORE_OWNER_SUCCESS:
      return {
        ...state,
        storeOwner: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case BECOME_STORE_OWNER_FAILURE:
      return {
        ...state,
        storeOwner: {
          data: {},
          loading: false,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };

    case FETCH_TOP_STORE_OWNERS_START:
      return {
        ...state,
        topStoreOwners: {
          inputData: action.data,
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_TOP_STORE_OWNERS_SUCCESS:
      return {
        ...state,
        topStoreOwners: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case LOAD_MORE_TOP_STORE_OWNERS_START:
      return {
        ...state,
        topStoreOwners: {
          ...state.topStoreOwners,
          loading: false,
          error: false,
        },
      };
    case STORE_OWNER_REVIEW_START:
      return {
        ...state,
        storeOwnerReview: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading...",
          buttonDisable: true,
        },
      };
    case STORE_OWNER_REVIEW_SUCCESS:
      return {
        ...state,
        storeOwnerReview: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case STORE_OWNER_REVIEW_FAILURE:
      return {
        ...state,
        storeOwnerReview: {
          data: {},
          loading: false,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };

    default:
      return state;
  }
};

export default StoreOwnerReducer;
