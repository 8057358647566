import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { deleteAccountStart } from "../../store/actions/UserAction";

const DeleteAccount = (props) => {
  const handleSubmit = (values) => {
    props.dispatch(deleteAccountStart({ password: values }));
  };

  const deleteAccountSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password must be 6 characters at minimum")
      .required("Password is required *"),
  });

  return (
    <>
      <div className="content-area">
        <div className="login-bg access-reset"></div>
        <div className="container login-area">
          <div className="section">
            <h3 className="bot-20 center white-text">Delete Account</h3>
            <p className="note mb-3 white-colot-text"><span>Note : </span>Once you deleted account, you will lose your history and wishlist details.</p>
            <Formik
              initialValues={{
                password: "",
              }}
              validationSchema={deleteAccountSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form noValidate>
                  <div className="row">
                    <div className="input-field withicon col s12">
                      <i className="mdi mdi-email-outline"></i>
                      <Field
                        type="password"
                        name="password"
                        placeholder="password"
                        className={`no-padding form-control ${
                          touched.password && errors.password
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      name="password"
                      className="invalid-feedback col s10 offset-s1"
                    />
                  </div>
                  <div className="row text-center">
                    <Link
                      type="button"
                      className="waves-effect waves-light btn-large bg-primary mt-3 mt-md-0"
                      to="/account"
                    >
                      Cancel
                    </Link>
                    <button
                      type="submit"
                      className="waves-effect waves-light btn-large bg-primary ml-2 mt-3 mt-md-0"
                      disabled={props.deleteAcc.buttonDisable}
                    >
                      {props.deleteAcc.loadingButtonContent !== null
                        ? props.deleteAcc.loadingButtonContent
                        : "Delete Account"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  deleteAcc: state.users.deleteAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DeleteAccount);
