import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Image, Media, Form } from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css";
import {
    fetchCategoriesStart,
    fetchPriceFilterStart
} from "../../store/actions/HomeAction";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const FilterIndex = (props) => {

    const [rangeValue, setRangeValue] = useState({ min: 2, max: 500 });

    useEffect(() => {
        props.dispatch(fetchPriceFilterStart());
        props.dispatch(fetchCategoriesStart());
    }, []);

    useEffect(() => {
        setRangeValue({
            ...rangeValue,
            min: 0,
            max: props.priceFilter.data.max_price_formatted,
        });
    }, [props.priceFilter]);

    const setSearchPriceRange = (value) => {
        setRangeValue({
            ...rangeValue,
            min: value.value.min,
            max: value.value.max,
        });
        props.getSearchPriceRange(value.value.min, value.value.max);
    };

    return (
        <div className="home-filter-sec">
            <div className="container">
                <div className="row">
                    <div className="col s12 pad-0 pt-0">
                        <form>
                            <div className="home-filter-box">
                                {/* <div className="home-filter-card range-width">
                                    <label for="exampleInputEmail1">Price</label>
                                    
                                    {props.priceFilter.loading
                                        ? "Loading..."
                                    : props.priceFilter.data.price_range.length > 0
                                    ? 
                                        <Form.Group className="mb-3 mt-4">
                                            <InputRange
                                                maxValue={props.priceFilter.data.max_price_formatted}
                                                minValue={0}
                                                value={rangeValue}
                                                onChange={value => setSearchPriceRange({ value })}
                                            />
                                        </Form.Group>
                                    : ""}
                                    
                                </div> */}
                                {/* {props.priceFilter.loading
                                    ? "Loading..."
                                : props.priceFilter.data.price_range.length > 0
                                    ?  */}
                                {/* {props.priceFilter.loading
                                    ? "Loading..." : */}
                                    <div className="home-filter-card mr-4">
                                        <label for="exampleInputEmail1">Price</label>
                                        <select
                                            className="form-control"
                                            onChange={(event) =>
                                                props.getSearchData(event)
                                            }
                                            name="price_range"
                                        >
                                            {/* {props.priceFilter.data.price_range.map((price) =>  */}
                                            <option value='0 - 200'>0 - 200</option>
                                            <option value='200-400'>200 - 400</option>
                                            <option value='400-600'>400 - 600</option>
                                            <option value='600-800'>600 - 800</option>
                                            <option value='800-1000'>800 - 1000</option>
                                            <option value='>1000'>Above 1000</option>
                                            {/* )} */}
                                        </select>
                                    </div>
                                {/* } */}
                                {/* : ""} */}
                                <div className="home-filter-card">
                                    <label for="exampleInputEmail1">Size</label>
                                    <select
                                        className="form-control"
                                        onChange={(event) =>
                                            props.getSearchData(event)
                                        }
                                        name="size"
                                    >
                                        <option value='XS'>XS</option>
                                        <option value='S'>S</option>
                                        <option value='M'>M</option>
                                        <option value='L'>L</option>
                                        <option value='XL'>XL</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                        {props.categories.loading ?
                            <div className="col s12 pad-0 pt-0">
                                <ul className="list-unstyled home-filter-category-sec">
                                    {[...Array(4)].map(() =>
                                        <li>
                                            <Skeleton className="filter-loader" />
                                        </li>
                                    )}
                                </ul>
                            </div>
                            : props.categories.data.post_categories.length > 0 ?
                                <div className="col s12 pad-0 pt-0">
                                    <ul className="list-unstyled home-filter-category-sec">
                                        {props.categories.data.post_categories.map((category) =>
                                            <li>
                                                <Link
                                                    to="#"
                                                    onClick={(event) =>
                                                        props.getCategorySearchData(event, category)
                                                    }
                                                >
                                                    {/* <img
                                                        src={category.picture}
                                                        className="home-filter-category-img"
                                                        alt={category.name}
                                                    /> */}
                                                    <picture>
                                                        <source className="lazyload home-filter-category-img" type="image/png" srcset={category.picture}/>
                                                        <img data-src={category.picture} type="image/png" className=" lazyload home-filter-category-img"
                                                            alt={category.name}/>
                                                    </picture>
                                                    <span>{category.name}</span>
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                : ""}
                    </div>
                </div>
            </div>
        </div >
    );
};
const mapStateToPros = (state) => ({
    categories: state.home.categories,
    priceFilter: state.home.priceFilter,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(FilterIndex));