import {
  BECOME_STORE_OWNER_START,
  BECOME_STORE_OWNER_SUCCESS,
  BECOME_STORE_OWNER_FAILURE,
  FETCH_TOP_STORE_OWNERS_START,
  FETCH_TOP_STORE_OWNERS_SUCCESS,
  FETCH_TOP_STORE_OWNERS_FAILURE,
  STORE_OWNER_REVIEW_START,
  STORE_OWNER_REVIEW_SUCCESS,
  STORE_OWNER_REVIEW_FAILURE,
  LOAD_MORE_TOP_STORE_OWNERS_START
} from "./ActionConstant";


export function becomeStoreOwnerStart(data) {
  return {
    type: BECOME_STORE_OWNER_START,
    data,
  };
}

export function becomeStoreOwnerSuccess(data) {
  return {
    type: BECOME_STORE_OWNER_SUCCESS,
    data,
  };
}

export function becomeStoreOwnerFailure(error) {
  return {
    type: BECOME_STORE_OWNER_FAILURE,
    error,
  };
}

export function fetchTopStoreOwnersStart(data) {
  return {
    type: FETCH_TOP_STORE_OWNERS_START,
    data,
  };
}

export function fetchTopStoreOwnersSuccess(data) {
  return {
    type: FETCH_TOP_STORE_OWNERS_SUCCESS,
    data,
  };
}

export function fetchTopStoreOwnersFailure(error) {
  return {
    type: FETCH_TOP_STORE_OWNERS_FAILURE,
    error,
  };
}

export function storeOnwerReviewStart(data) {
  return {
    type: STORE_OWNER_REVIEW_START,
    data,
  };
}

export function storeOnwerReviewSuccess(data) {
  return {
    type: STORE_OWNER_REVIEW_SUCCESS,
    data,
  };
}

export function storeOnwerReviewFailure(error) {
  return {
    type: STORE_OWNER_REVIEW_FAILURE,
    error,
  };
}

export function loadMoreTopStoreOwnersStart(data) {
  return {
    type: LOAD_MORE_TOP_STORE_OWNERS_START,
    data,
  };
}
