import React, { useEffect, useState } from "react";
import UserPosts from "../post/UserPosts";
import { connect } from "react-redux";
import {
  fetchSingleUserProfileStart,
  fetchSingleUserPostsStart,
} from "../../store/actions/OtherUserAction";
import {
  fetchFollowersStart,
  followUserStart,
  fetchFollowingStart,
  unFollowUserStart,
} from "../../store/actions/FollowAction";
import NoDataFound from "../helper/NoDataFound";
import { Link } from "react-router-dom";
import qs from "query-string";
import RatingReviewModal from "../helper/RatingReviewModal";
import RatingReviewViewerModal from "../helper/RatingReviewViewerModal";
import { useHistory } from "react-router";
import LoginPopup from "../helper/LoginPopup";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const OtheruserProfile = (props) => {
  const queryParam = qs.parse(props.location.search);

  const history = useHistory();

  const [showFollow, setShowFollow] = useState({
    followFlag: null,
    followersCount: null,
  });

  const [ratingModal, setRatingModal] = useState({
    modalFlag: false,
    post_id: null,
  });

  const [viewratingModal, setViewRatingModal] = useState({
    modalFlag: false,
  });

  const [buyProduct, setBuyProduct] = useState({
    post_id: "",
  });

  const [activeTab, setActiveTab] = useState("all");

  const [showLoginPopup, setShowLoginPopup] = useState(false);

  useEffect(() => {
    props.dispatch(
      fetchSingleUserProfileStart({
        user_unique_id: props.match.params.username,
      })
    );
  }, []);

  useEffect(() => {
    if (!props.profile.loading) {
      setShowFollow({
        ...showFollow,
        followFlag: props.profile.data.show_follow == 0 ? true : false,
        followersCount: props.profile.data.total_followers,
      });
    }
  }, [props.profile.data]);

  useEffect(() => {
    if (!props.followUser.loading) {
      setShowFollow({
        ...showFollow,
        followFlag: props.followUser.data.is_follow == 0 ? true : false,
        followersCount: props.followUser.data.total_followings,
      });
    }
  }, [props.followUser.data]);

  useEffect(() => {
    if (!props.unFollowUser.loading) {
      setShowFollow({
        ...showFollow,
        followFlag: props.unFollowUser.data.show_follow == 0 ? true : false,
        followersCount: props.unFollowUser.data.total_followings,
      });
    }
  }, [props.unFollowUser.data]);

  const handleFollowUser = (user_id) => {
    if (!localStorage.getItem("userLoginStatus")) {
      setShowLoginPopup(true);
    } else {
      props.dispatch(
        followUserStart({
          user_id: user_id,
        })
      );
    }
  };

  const handleUnfollowUser = (user_id) => {
    props.dispatch(
      unFollowUserStart({
        user_id: user_id,
      })
    );
  };

  const getParameter = (from_user_id, to_user_id) => {
    const newQueryParam = {
      ...queryParam,
      from_user: from_user_id,
      to_user: to_user_id,
    };
    return newQueryParam;
  };

  const handleRatingModalOpen = (post_id) => {
    setRatingModal({
      ...ratingModal,
      modalFlag: true,
      post_id: post_id,
    });
  };

  const handleRatingModalClose = () => {
    setRatingModal({
      ...ratingModal,
      modalFlag: false,
      post_id: null,
    });
  };

  const handleViewRatingModalOpen = () => {
    setViewRatingModal({
      ...viewratingModal,
      modalFlag: true,
    });
  };

  const handleViewRatingModalClose = () => {
    setViewRatingModal({
      ...viewratingModal,
      modalFlag: false,
    });
  };

  const handleBuyProduct = (post_id) => {
    setBuyProduct({
      ...buyProduct,
      post_id: post_id,
    });
  };

  const getChatParameter = (from_user_id, to_user_id, post_id) => {
    const newQueryParam = {
      ...queryParam,
      from_user: from_user_id,
      to_user: to_user_id,
      post_id: post_id,
    };
    return newQueryParam;
  };

  useEffect(() => {
    if (!props.saveChatUser.loading) {
      if (
        props.saveChatUser.data.from_user_id == localStorage.getItem("userId")
      ) {
        const location = {
          pathname: `/chat/user/${props.saveChatUser.data.to_username}`,
          search: qs.stringify(
            getChatParameter(
              props.saveChatUser.data.from_user_id,
              props.saveChatUser.data.to_user_id,
              buyProduct.post_id
            )
          ),
        };
        history.push(location);
      }
    }
  }, [props.saveChatUser.data]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    props.dispatch(
      fetchSingleUserPostsStart({
        user_unique_id: props.match.params.username,
        type: activeTab,
      })
    );
  }, [activeTab]);

  const handlePopupClose = () => {
    setShowLoginPopup(false)
  };

  return (
    <>
      {props.profile.loading ? (
        <div className="ui-profile">
          <div className="primg mb-0 container">
            <Skeleton className="circle-loader" width={100} />
            <Skeleton className="title-loader" width={100} height={30} />
          </div>
          <div className="prinfo card-panel">
            <div className="prname bio-about">
              <p className="mb-0">
                <Skeleton count={5} width="" />
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="ui-profile">
          <div className="primg mb-0 container">
            <img
              src={props.profile.data.user.picture}
              alt={props.profile.data.user.name}
            />
            <h4 className="name m-0">{props.profile.data.user.name}</h4>
          </div>
          <div className="prinfo card-panel">
            <div className="prname bio-about">
              {props.profile.data.user.about != null &&
                props.profile.data.user.about != "undefined" &&
                props.profile.data.user.about != "" && (
                  <p className="mb-0">
                    About : {props.profile.data.user.about}
                  </p>
                )}
            </div>
            <div className="statistics mt-3 container">
              <span>
                <i className="mdi mdi-account-star"></i>Followers:{" "}
                {showFollow.followersCount}
              </span>
              <span>
                <i className="mdi mdi-account-plus"></i>Following:{" "}
                {props.profile.data.total_followings}
              </span>
              {/* <span>
                <i className="mdi mdi-file-image"></i> <span className="display-none">Images :</span>{" "}
                {props.profile.data.total_images}
              </span>
              <span>
                <i className="mdi mdi-file-video"></i> <span className="display-none">Videos :</span>{" "}
                {props.profile.data.total_videos}
              </span>
              <span>
                <i className="mdi mdi-cards-outline"></i> <span className="display-none">Posts :</span>{" "}
                {props.profile.data.user.total_normal_posts}
              </span>
              {props.profile.data.user.store_owner_request == 2 && (
                <>
                  <span>
                    <i className="mdi mdi-folder-multiple-image"></i> <span className="display-none">Product
                    Posts : </span>{props.profile.data.user.total_product_posts}
                  </span>
                </>
              )}
              <span>
                <i className="mdi mdi-animation"></i> <span className="display-none">Total Posts :</span>{" "}
                {props.profile.data.user.total_posts}
              </span>
              <span>
                <i className="mdi mdi-cube-send"></i> <span className="display-none">Products Purchased :</span>{" "}
                {props.profile.data.user.total_products_purchased}
              </span> */}
              {props.profile.data.reviews &&
                props.profile.data.reviews.length > 0 && (
                  <>
                    <span
                      className="rating-review button"
                      onClick={() => handleViewRatingModalOpen()}
                    >
                      <i className="mdi mdi-star"></i>
                      <div>
                        <span className="display-none">Rating :</span> 4{" "}
                        <i className="mdi mdi-information-outline info-icon"></i>
                      </div>
                    </span>
                  </>
                )}
            </div>
            <div>
              {showFollow.followFlag ? (
                <button
                  type="submit"
                  className="waves-effect waves-light btn-large bg-primary btn-white-outline mt-3"
                  onClick={() =>
                    handleUnfollowUser(props.profile.data.user.user_id)
                  }
                >
                  Unfollow
                </button>
              ) : (
                <button
                  type="submit"
                  className="waves-effect waves-light btn-large bg-primary btn-white-outline mt-3"
                  onClick={() =>
                    handleFollowUser(props.profile.data.user.user_id)
                  }
                >
                  Follow
                </button>
              )}
              {localStorage.getItem("userLoginStatus") ?
                <Link
                  to={{
                    pathname: `/chat/user/${props.profile.data.user.username}`,
                    search: qs.stringify(
                      getParameter(
                        localStorage.getItem("userId"),
                        props.profile.data.user.user_id
                      )
                    ),
                  }}
                  className="waves-effect waves-light btn-large bg-primary btn-white-outline mt-3 inline-btn"
                >
                  Chat
                </Link>
                :
                <Link
                  to='#'
                  onClick={() =>
                    setShowLoginPopup(true)
                  }
                  className="waves-effect waves-light btn-large bg-primary btn-white-outline mt-3 inline-btn"
                >
                  Chat
                </Link>
              }
            </div>
          </div>
        </div>
      )}

      <div className="container over profile-content">
        <div className="container mobile-remove-spacing">
          <div className="section">
            <div className="profile-tab-section sm-margin">
              <span
                onClick={() => handleTabChange("all")}
                className={activeTab == "all" ? "active" : ""}
              >
                <i className="mdi mdi-cards-outline"></i>{" "}
                <span className="display-none">All</span>
              </span>
              <span
                onClick={() => handleTabChange("image")}
                className={activeTab == "image" ? "active" : ""}
              >
                <i className="mdi mdi-file-image"></i>{" "}
                <span className="display-none">Photos</span>
              </span>
              <span
                onClick={() => handleTabChange("video")}
                className={activeTab == "video" ? "active" : ""}
              >
                <i className="mdi mdi-file-video"></i>{" "}
                <span className="display-none">Videos</span>
              </span>
            </div>
            {props.posts.loading ? (
              <div className="collection mailbox activity z-depth-0 loader-grid-view">
                {[...Array(6)].map(() =>
                  <Skeleton className="profile-product-loader" />
                )}
              </div>
            ) : props.posts.data.posts.length > 0 ? (
              <>
                <ul className="collection mailbox activity z-depth-0  collection-grid-view">
                  {props.posts.data.posts
                    .filter((post) => post.status == 1)
                    .map((post, index) => (
                      <UserPosts
                        post={post}
                        key={index}
                        location={props.location}
                        history={props.history}
                        handleRatingModalOpen={handleRatingModalOpen}
                        handleBuyProduct={handleBuyProduct}
                        profile={true}
                      />
                    ))}
                </ul>
              </>
            ) : (
              <>
                <NoDataFound message={"No Post Found"} />
              </>
            )}
          </div>
        </div>
      </div>

      <RatingReviewModal
        show={ratingModal.modalFlag}
        handleRatingModalClose={handleRatingModalClose}
        post_id={ratingModal.post_id}
      />
      <RatingReviewViewerModal
        show={viewratingModal.modalFlag}
        handleViewRatingModalClose={handleViewRatingModalClose}
        reviewData={!props.profile.loading && props.profile.data.reviews}
        username={!props.profile.loading && props.profile.data.user.username}
      />
      <LoginPopup
        show={showLoginPopup}
        hide={handlePopupClose}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.otherUser.userDetails,
  posts: state.otherUser.userPosts,
  followUser: state.follow.followUser,
  unFollowUser: state.follow.unFollowUser,
  saveChatUser: state.chat.saveChatUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(OtheruserProfile);
