import React, { useState, useEffect, useRef } from "react";
import FancyBox from "../explore/FancyBox";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { savePostLikeStart } from "../../store/actions/PostLikesAction";
import {
  fetchCommentsStart,
  fetchCommentRepliesStart,
  saveCommentStart,
  deleteCommentStart,
} from "../../store/actions/CommentsAction";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NoDataFound from "../helper/NoDataFound";
import { saveChatUserStart } from "../../store/actions/ChatAction";
import PostComments from "./PostComments";
import PostLikeViewer from "../helper/PostLikeViewer";
import Imgix from "react-imgix";
import LazyLoad from 'react-lazyload';

const UserPosts = (props) => {
  const { post } = props;

  const commentSchema = Yup.object().shape({
    comment: Yup.string().required("required *"),
  });

  const [postLikes, setPostLikes] = useState(post.like_count);

  const [likeStatus, setLikeStatus] = useState(
    props.post.post_likes.length > 0 &&
      props.post.post_likes.filter(
        (post) => post.user_id == localStorage.getItem("userId")
      ).length > 0
      ? true
      : false
  );

  const [comments, setComments] = useState({
    showComments: false,
    showReplay: false,
    commentCount: props.post.comments_count,
    commentsData: {},
    replies_count: null,
  });

  const [likeViweModal, setLikeViewModal] = useState({
    modal_status: false,
    likedata: {},
  });

  useEffect(() => {
    if (comments.showComments) {
      props.dispatch(fetchCommentsStart({ post_id: post.post_id }));
    }
  }, [comments.showComments]);

  useEffect(() => {
    if (!props.postLike.saveLike.loading) {
      if (props.postLike.saveLike.data.data.post_id == props.post.post_id) {
        if (props.postLike.saveLike.data.message == "Liked") {
          setPostLikes(postLikes + 1);
          setLikeStatus(true);
        } else {
          setPostLikes(postLikes - 1 < 0 ? 0 : postLikes - 1);
          setLikeStatus(false);
        }
      }
    }
  }, [props.postLike.saveLike.data]);

  const handleLike = (post_id) => {
    props.dispatch(savePostLikeStart({ post_id: post_id }));
  };

  const handleCommentSubmit = (values) => {
    props.dispatch(
      saveCommentStart({
        comment: values.comment,
        post_id: post.post_id,
      })
    );
  };

  const handleCommentShow = () => {
    setComments({
      ...comments,
      showComments: comments.showComments ? false : true,
    });
  };

  const handleDeleteComment = (comment_id) => {
    props.dispatch(
      deleteCommentStart({ post_id: post.post_id, post_comment_id: comment_id })
    );
  };

  const handleChatUser = () => {
    props.dispatch(
      saveChatUserStart({
        from_user_id: localStorage.getItem("userId"),
        to_user_id: props.post.user_id,
      })
    );
    props.handleBuyProduct(post.post_unique_id);
  };

  useEffect(() => {
    if (comments.showReplay) {
      props.dispatch(
        fetchCommentRepliesStart({
          post_id: props.post.post_id,
          post_comment_id: comments.showReplay,
        })
      );
    }
  }, [comments.showReplay]);

  useEffect(() => {
    if (!props.comments.loading) {
      if (props.post.post_id == props.comments.data.post_id) {
        setComments({
          ...comments,
          commentsData: props.comments.data.post_comments,
        });
      }
    }
  }, [props.comments.data]);

  const handleLikeViwerModalOpen = (likesData) => {
    setLikeViewModal({
      ...likeViweModal,
      modal_status: true,
      likedata: likesData,
    });
  };

  const handleLikeViwerModalClose = () => {
    setLikeViewModal({
      ...likeViweModal,
      modal_status: false,
      likedata: {},
    });
  };

  return (
    <>
      <li className={`collection-item avatar ${props.profile ? "mb-0" : ""}`}>
        {props.homePage && (
          <>
            <div className="maillink mb-3">
              {props.ownProfile ? (
                <>
                  <img
                    src={post.user.picture}
                    alt={post.user.user_displayname}
                    title={post.user.user_displayname}
                    className="circle"
                  />

                  <span className="title post-user">{post.user.name}</span>
                </>
              ) : (
                <>
                  <Link to={`/user/profile/${props.post.user.username}`}>
                    <img
                      src={post.user.picture}
                      alt={post.user.user_displayname}
                      title={post.user.user_displayname}
                      className="circle"
                    />
                  </Link>
                  <Link to={`/user/profile/${props.post.user.username}`}>
                    <span className="title post-user">{post.user.name}</span>
                  </Link>
                </>
              )}
              <span className="time">{post.created}</span>
            </div>
          </>
        )}
        {post.postFiles.length > 0 && (
          <div className="media m-0 w-100 post">
            <div className="row">
              {props.profile ? (
                <>
                  {post.postFiles.map((file, index) => (
                    <>
                      {index < 1 && (
                        <>
                          {file.file_type == "image" ? (
                            <div className="col s12 profile-image-grid" key={index}>
                              <Link
                                to={`/post/${post.post_unique_id}`}
                                className={`img-wrap round  ${post.post_type == 2 &&
                                  post.is_available == "sold"
                                  ? "sold"
                                  : ""
                                  }`}
                              >
                                {/* <LazyLoad className="profile-product-loader">
                                  <Imgix
                                    alt="image"
                                    className="img-responsive"
                                    src={file.post_file}
                                    width={100}
                                    disableQualityByDPR
                                  />
                                </LazyLoad> */}
                                <picture>
                                    <source className="lazyload img-responsive" type="image/webp" srcset={file.post_file}/>
                                    <img data-src={file.post_file} type="image/webp" className=" lazyload img-responsive"
                                        alt="image" loading="lazy"/>
                                </picture>
                                {post.post_type == 2 &&
                                  post.is_available == "sold" && (
                                    <>
                                      {/* <div className="sold-tag">
                              <h6 className="m-0">Sold out</h6>
                            </div> */}
                                      <div className="sold-tag-img-sec grid">
                                        {/* <img
                                          src={
                                            window.location.origin +
                                            "/assets/images/sold-out-tag.png "
                                          }
                                          alt=""
                                          className="sold-tag-img "
                                        /> */}
                                        <picture>
                                          <source className="lazyload sold-tag-img" type="image/png" srcset={
                                            window.location.origin + "/assets/images/sold-out-tag.png"
                                          }/>
                                          <img data-src={
                                            window.location.origin + "/assets/images/sold-out-tag.png"
                                          } type="image/png" class=" lazyload sold-tag-img"
                                              alt="" loading="lazy"/>
                                      </picture>
                                      </div>
                                    </>
                                  )}
                                <div className="product-img-info-sec">
                                  <ul className="list-unstyled product-img-info">
                                    {post.size ?
                                      <li>
                                        <span>{post.size}</span>
                                      </li>
                                      : ''}
                                    <li>
                                      <span>{post.amount_formatted}</span>
                                    </li>
                                  </ul>
                                </div>
                              </Link>
                            </div>
                          ) : (
                            <>
                              <video
                                autoplay
                                controls
                                id="myVideo"
                                className="user-profile1 w-100"
                              >
                                <source src={file.post_file} type="video/mp4" />
                              </video>
                              {post.post_type == 2 &&
                                post.is_available == "sold" && (
                                  <>
                                    {/* <div className="sold-tag">
                                      <h6 className="m-0">Sold out</h6>
                                    </div> */}
                                    <div className="sold-tag-img-sec grid">
                                      <img
                                        src={
                                          window.location.origin +
                                          "/assets/images/sold-out-tag.png"
                                        }
                                        alt=""
                                        className="sold-tag-img "
                                      />
                                    </div>
                                  </>
                                )}
                              <div className="product-img-info-sec">
                                <ul className="list-unstyled product-img-info">
                                  {post.size ?
                                    <li>
                                      <span>{post.size}</span>
                                    </li>
                                    : ''}
                                  <li>
                                    <span>{post.amount_formatted}</span>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <>
                  {post.postFiles.map((file, index) => (
                    <>
                      {file.file_type == "image" ? (
                        <div
                          className={
                            post.postFiles.length > 1 ? "col s6" : "col s12"
                          }
                          key={index}
                        >
                          <Link
                            to={`/post/${post.post_unique_id}`}
                            className={`img-wrap round  ${post.post_type == 2 && post.is_available == "sold"
                              ? "sold"
                              : ""
                              }`}
                          >
                            <LazyLoad >
                              <img
                                alt="image"
                                className="img-responsive"
                                src={file.post_file}
                              />
                            </LazyLoad>
                            {post.post_type == 2 &&
                              post.is_available == "sold" && (
                                <>
                                  {/* <div className="sold-tag">
                              <h6 className="m-0">Sold out</h6>
                            </div> */}
                                  <div className="sold-tag-img-sec">
                                    <img
                                      src={
                                        window.location.origin +
                                        "/assets/images/sold-out-tag.png"
                                      }
                                      alt=""
                                      className="sold-tag-img"
                                    />
                                  </div>
                                </>
                              )}
                            <div className="product-img-info-sec">
                              <ul className="list-unstyled product-img-info">
                                {post.size ?
                                  <li>
                                    <span>{post.size}</span>
                                  </li>
                                  : ''}
                                <li>
                                  <span>{post.amount_formatted}</span>
                                </li>
                              </ul>
                            </div>
                          </Link>
                        </div>
                      ) : (
                        <>
                          <video
                            autoplay
                            controls
                            id="myVideo"
                            className="user-profile1 w-100"
                          >
                            <source src={file.post_file} type="video/mp4" />
                          </video>
                          {post.post_type == 2 && post.is_available == "sold" && (
                            <>
                              {/* <div className="sold-tag">
                                <h6 className="m-0">Sold out</h6>
                              </div> */}
                              <div className="sold-tag-img-sec">
                                <img
                                  src={
                                    window.location.origin +
                                    "/assets/images/sold-out-tag.png"
                                  }
                                  alt=""
                                  className="sold-tag-img"
                                />
                              </div>
                            </>
                          )}
                          <div className="product-img-info-sec">
                            <ul className="list-unstyled product-img-info">
                              {post.size ?
                                <li>
                                  <span>{post.size}</span>
                                </li>
                                : ''}
                              <li>
                                <span>{post.amount_formatted}</span>
                              </li>
                            </ul>
                          </div>
                        </>
                      )}
                    </>
                  ))}
                </>
              )}
            </div>
          </div>
        )}
        {props.homePage && (
          <>
            <p
              className="mb-3"
              dangerouslySetInnerHTML={{
                __html: post.content != undefined ? post.content : "",
              }}
            ></p>
            <div class="user-actions col s12 mb-3">
              <div className="d-flex align-items-center post-action">
                <div className="post-actions-wrapper">
                  <button
                    className={`no-btn m-0 like-btn ${likeStatus ? "active" : ""
                      }`}
                    onClick={() => handleLike(post.post_id)}
                  >
                    {likeStatus ? (
                      <>
                        <i class="mdi mdi-thumb-up"></i>
                      </>
                    ) : (
                      <>
                        <i class="mdi mdi-thumb-up-outline"></i>
                      </>
                    )}
                  </button>
                  <span
                    className={`like-count ${postLikes > 0 ? "hover-btn" : ""}`}
                    onClick={
                      postLikes > 0
                        ? () => handleLikeViwerModalOpen(post.post_likes)
                        : null
                    }
                  >
                    {likeStatus ? (
                      <>
                        {postLikes - 1 <= 0
                          ? `You liked`
                          : `You and ${postLikes - 1} other liked`}
                      </>
                    ) : (
                      <>
                        {postLikes} {postLikes <= 1 ? "Like" : "Likes"}
                      </>
                    )}
                  </span>
                </div>

                <button
                  className="no-btn m-0 d-flex"
                  onClick={() => handleCommentShow()}
                >
                  <i class="mdi mdi-comment-outline"></i>
                  <span>
                    {comments.commentCount}{" "}
                    {comments.commentCount <= 1 ? "Comment" : "Comments"}
                  </span>
                </button>
              </div>
            </div>
            {post.post_type == 2 && (
              <div className="prod-info">
                <div className="d-flex">
                  <Link
                    to={`/post/${post.post_unique_id}`}
                    className="flex-data-space-btw"
                  >
                    <h5 className="title m-0">{post.title} </h5>
                  </Link>
                  {post.is_available != "sold" &&
                    localStorage.getItem("userId") != post.user_id && (
                      <>
                        <span className="ml-auto buynow-btn">
                          <button
                            onClick={() => handleChatUser()}
                            className="waves-effect waves-light btn-large bg-primary  m-0 addtocart btn-small"
                          >
                            Buy Now
                          </button>
                        </span>
                      </>
                    )}
                  {localStorage.getItem("userId") != post.user_id &&
                    post.product_purchased_user_id ==
                    localStorage.getItem("userId") &&
                    post.user_review_eligibility_status == 1 && (
                      <>
                        <span className="ml-auto buynow-btn">
                          <button
                            className="waves-effect waves-light btn-large bg-primary  m-0 addtocart btn-small"
                            onClick={() =>
                              props.handleRatingModalOpen(post.post_id)
                            }
                          >
                            Rate & Review
                          </button>
                        </span>
                      </>
                    )}
                </div>
                <span className="small brand text-capitalize">
                  {post.is_available}
                </span>
                <div className="spacer-line"></div>
                <h5 className="bot-0 price m-0">{post.amount_formatted}</h5>
              </div>
            )}
            <div className="mt-2">
              {comments.showComments && (
                <>
                  {props.comments.loading ? (
                    "Loading.."
                  ) : comments.commentsData.length > 0 ? (
                    comments.commentsData.map((comment, index) => (
                      <>
                        <PostComments
                          commentsState={comments}
                          comment={comment}
                          handleDeleteComment={handleDeleteComment}
                          setComments={setComments}
                          post={post}
                          key={index}
                        />
                      </>
                    ))
                  ) : (
                    <NoDataFound message="No comments yet, write a comment" />
                  )}
                  <div className="mt-2">
                    <Formik
                      initialValues={{ comment: "" }}
                      validationSchema={commentSchema}
                      onSubmit={async (values, { resetForm }) => {
                        await handleCommentSubmit(values);
                        resetForm();
                      }}
                    >
                      {({ touched, errors, isSubmitting }) => (
                        <Form noValidate>
                          <div className="comment-reply-box">
                            <div className="row d-flex align-items-center m-0">
                              <div className="input-field col w-100 m-0 ">
                                <Field
                                  type="email"
                                  name="comment"
                                  className={`validate no-padding ${touched.comments && errors.comments
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  placeholder="Please Type a comment"
                                />
                              </div>
                              <div className="col ml-auto center">
                                <button
                                  type="submit"
                                  className="waves-effect waves-light btn-large bg-primary round-btn-icon m-0"
                                  disabled={props.saveComment.buttonDisable}
                                >
                                  <i className="mdi mdi-send"></i>
                                </button>
                              </div>
                            </div>
                            <ErrorMessage
                              component="div"
                              name="comment"
                              className="invalid-feedback col s12 m-0"
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </li>
      <PostLikeViewer
        show={likeViweModal.modal_status}
        handleLikeViwerModalClose={handleLikeViwerModalClose}
        likedata={likeViweModal.likedata}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  explorePosts: state.post.explorePosts,
  postLike: state.postLike,
  comments: state.comment.comments,
  saveChatUser: state.chat.saveChatUser,
  saveComment: state.comment.saveComment,
  storeOwner: state.storeOwner.storeOwnerReview,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(UserPosts);
