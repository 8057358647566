import React, { useState } from "react";
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazyload';

const TopStoreOwnerCard = (props) => {

  const { storeOwner } = props

  return (
    <>
      <div className="top-store-owner">
        <Link className="img-wrap round" to={`/user/profile/${storeOwner.user_unique_id}`}>
          <LazyLoad once>
            <img
              alt={storeOwner.name}
              className="img-responsive"
              src={storeOwner.picture}
            />
          </LazyLoad>
        </Link>
        {/* <div className="user-actions">
          <Link to="/" className="active">
            <i className="mdi mdi-heart-outline"></i>
            <span>684</span>
          </Link>
        </div> */}
      </div>
    </>
  );
};

export default TopStoreOwnerCard;
