import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { forgotPasswordStart } from "../store/actions/UserAction";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ForgotPassword = (props) => {

  const handleForgotPassword = (values) => {
    props.dispatch(forgotPasswordStart(values));
  };

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email address format")
        .required("Email is required *"),
  });


  return (
    <>
      <div class="content-area">
        <div class="login-bg access-forgot"></div>
        <div class="container login-area">
          <div class="section">
            <h3 class="bot-20 center white-text">Forgot Password</h3>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={forgotPasswordSchema}
              onSubmit={(values) => handleForgotPassword(values)}
            >
              {({ touched, errors, isSubmitting }) => (
                <Form noValidate>
                  <div className="row">
                    <div className="input-field col s10 offset-s1">
                      <Field
                        id="email"
                        type="email"
                        name="email"
                        className={`validate no-padding ${
                            touched.email && errors.email ? "is-invalid" : ""
                        }`}
                        placeholder="Your Email"
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      name="email"
                      className="invalid-feedback col s10 offset-s1"
                    />
                  </div>
                  <div className="row center">
                    <div class="links text-center">
                      <Link to={"/login"}>Login</Link>
                      <span class="sep">|</span>
                      <Link to={"/register"}>Register</Link>{" "}
                    </div>
                    <div class="spacer"></div>
                    <button
                      type="submit"
                      disabled={props.forgotPassword.buttonDisable}
                      className="waves-effect waves-light btn-large bg-primary"
                    >
                      {props.forgotPassword.loadingButtonContent !== null
                        ? props.forgotPassword.loadingButtonContent
                        : "Reset Password"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  forgotPassword: state.users.forgotPasswordInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ForgotPassword);
