import React from 'react'

const NoDataFound = (props) => {
  return (
    <>
      <div className="container no-data-found">
        <p>{props.message}</p>
      </div>
    </>
  )
}

export default NoDataFound
