import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import {
  editUserDetails,
  fetchUserDetailsStart,
  updateUserDetailsStart,
} from "../../store/actions/UserAction";
import { connect } from "react-redux";
import { Picker, EmojiData } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import { Popover } from "@material-ui/core";

const EditProfile = (props) => {
  const [image, setImage] = useState({
    picture: "",
  });

  const [editProfile, setEditProfile] = useState({
    name: "",
    username: "",
    mobile: "",
    email: "",
    gender: "",
    address: "",
    about: "",
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const aboutRef = useRef(null);

  const [aboutCursorPosition, setAboutCursorPosition] = useState(null);

  const updateProfileSchema = Yup.object().shape({
    name: Yup.string().required("Name is required *"),
    username: Yup.string().required("Username is required *"),
    mobile: Yup.number().required("Mobile Number is Required"),
    email: Yup.string()
      .email("Invalid email address format")
      .required("Email is required *"),
    address: Yup.string(),
    gender: Yup.string(),
    picture: Yup.mixed(),
    about: Yup.string(),
  });

  useEffect(() => {
    if (props.profile.loading) props.dispatch(fetchUserDetailsStart());
  }, []);

  useEffect(() => {
    if (!props.profile.loading) {
      setEditProfile({
        ...editProfile,
        name: props.profile.data.user.name,
        username: props.profile.data.user.username,
        address: props.profile.data.user.address,
        mobile: Number(props.profile.data.user.mobile),
        email: props.profile.data.user.email,
        gender: props.profile.data.user.gender,
        picture: props.profile.data.user.picture,
        about:
          props.profile.data.user.about != null &&
          props.profile.data.user.about != "undefined"
            ? props.profile.data.user.about
            : "",
      });
    }
  }, [props.profile.data]);

  const handleSubmit = (value) => {
    if (image.picture != "") {
      const newData = { picture: image.picture, ...editProfile };
      props.dispatch(updateUserDetailsStart(newData));
    } else {
      const newData = { ...editProfile };
      delete newData.picture;
      props.dispatch(updateUserDetailsStart(newData));
    }
  };

  const handleChangeImage = (event) => {
    if (event.currentTarget.type === "file") {
      setEditProfile({
        ...editProfile,
        [event.currentTarget.name]: event.currentTarget.files[0],
      });

      let reader = new FileReader();
      let file = event.currentTarget.files[0];

      reader.onloadend = () => {
        setImage({ ...image, picture: reader.result });
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const handleInputChange = (event) => {
    setEditProfile({
      ...editProfile,
      [event.target.name]: event.target.value,
    });
  };

  const handleEmojiSelect = (emoji) => {
    if (aboutCursorPosition != null) {
      setEditProfile({
        ...editProfile,
        about:
          editProfile.about.slice(0, aboutCursorPosition) +
          emoji.native +
          editProfile.about.slice(aboutCursorPosition),
      });
    } else {
      setEditProfile({
        ...editProfile,
        about: editProfile.about + emoji.native,
      });
    }
    handleEmojiPopOverClose();
    aboutRef.current.focus();
  };

  const handleEmojiPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEmojiPopOverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div className="content-area">
        <div className="pagehead-bg   primary-bg"></div>
        {props.profile.loading ? (
          "Loading..."
        ) : (
          <>
            <div className="container has-pagehead is-pagetitle">
              <div className="section">
                <h5 className="pagetitle">Edit Profile</h5>
              </div>
            </div>
            <div className="container over">
              <div className="section ">
                <div className="spacer"></div>
                <Formik
                  enableReinitialize
                  initialValues={editProfile}
                  validationSchema={updateProfileSchema}
                  onSubmit={(values) => handleSubmit(values)}
                >
                  {({ touched, errors, setFieldValue, isSubmitting }) => (
                    <Form noValidate>
                      <div className="editprof-img">
                        <div className="img-wrap circle">
                          <img
                            src={
                              image.picture != ""
                                ? image.picture
                                : editProfile.picture
                            }
                            alt=""
                          />
                          <i className="mdi mdi-pencil prefix">
                            <input
                              type="file"
                              className="file_upload-none"
                              accept="image/*"
                              name="picture"
                              onChange={(event) => {
                                handleChangeImage(event);
                              }}
                            ></input>
                          </i>
                        </div>
                      </div>
                      <div className="spacer"></div>
                      <div className="row">
                        <div className="input-field withicon col s12">
                          <i className="mdi mdi-account-outline "></i>
                          <Field
                            className={`input-bordered no-padding  ${
                              touched.name && errors.name ? "is-invalid" : ""
                            }`}
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={editProfile.name}
                            onChange={(event) => {
                              handleInputChange(event);
                            }}
                          />
                        </div>
                        <ErrorMessage
                          component="div"
                          name="name"
                          className="invalid-feedback col s12"
                        />
                      </div>
                      <div className="row">
                        <div className="input-field withicon col s12">
                          <i className="mdi mdi-account-outline "></i>
                          <Field
                            className={`input-bordered no-padding  ${
                              touched.username && errors.username
                                ? "is-invalid"
                                : ""
                            }`}
                            type="text"
                            name="username"
                            placeholder="Your UserName"
                            value={editProfile.username}
                            onChange={(event) => {
                              handleInputChange(event);
                            }}
                          />
                        </div>
                        <ErrorMessage
                          component="div"
                          name="username"
                          className="invalid-feedback col s12"
                        />
                      </div>
                      <div className="row">
                        <div className="input-field withicon col s12">
                          <i className="mdi mdi-email-outline"></i>
                          <Field
                            className={`input-bordered no-padding ${
                              touched.email && errors.email ? "is-invalid" : ""
                            }`}
                            type="email"
                            name="email"
                            placeholder="Email Address"
                            value={editProfile.email}
                            onChange={(event) => {
                              handleInputChange(event);
                            }}
                          />
                        </div>
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="invalid-feedback col s12"
                        />
                      </div>
                      <div className="row">
                        <div className="input-field withicon col s12">
                          <i className="mdi mdi-cellphone"></i>
                          <Field
                            name="mobile"
                            type="number"
                            value={editProfile.mobile}
                            className={`input-bordered no-padding ${
                              touched.mobile && errors.mobile
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Mobile Number"
                            onChange={(event) => {
                              handleInputChange(event);
                            }}
                          />
                        </div>
                        <ErrorMessage
                          component="div"
                          name="mobile"
                          className="invalid-feedback col s12"
                        />
                      </div>
                      {/* <div className="row">
                        <div className="input-field withicon col s12">
                          <i className="mdi mdi-web"></i>
                          <Field
                            type="text"
                            className={`input-bordered no-padding ${
                              touched.website && errors.website
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Website"
                            name="website"
                            onChange={(event) => {
                              props.dispatch(
                                editUserDetails(
                                  event.currentTarget.name,
                                  event.currentTarget.value
                                )
                              );
                            }}
                          />
                        </div>
                        <ErrorMessage
                          component="div"
                          name="website"
                          className="invalid-feedback col s12"
                        />
                      </div>
                      <div className="row">
                        <div className="input-field withicon col s12">
                          <i className="mdi mdi-amazon"></i>
                          <Field
                            type="text"
                            className={`input-bordered no-padding ${
                              touched.website && errors.website
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Amazon Wishlist"
                            name="amazon_wishlist"
                            onChange={(event) => {
                              props.dispatch(
                                editUserDetails(
                                  event.currentTarget.name,
                                  event.currentTarget.value
                                )
                              );
                            }}
                          />
                        </div>
                        <ErrorMessage
                          component="div"
                          name="amazon_wishlist"
                          className="invalid-feedback col s12"
                        />
                      </div> */}
                      <div className="row">
                        <div class="input-field withicon col s12">
                          <i className="mdi mdi-gender-male-female"></i>
                          <select
                            name="gender"
                            id="gender"
                            className={`input-bordered no-padding d-block ${
                              touched.gender && errors.gender
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={(event) => {
                              props.dispatch(
                                editUserDetails(
                                  event.currentTarget.name,
                                  event.currentTarget.value
                                )
                              );
                              setFieldValue("gender", event.target.value);
                            }}
                          >
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                          <ErrorMessage
                            component="div"
                            name="token_type"
                            className="invalid-feedback col s12"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="field-with-emoji">
                          <div className="input-field withicon col s12 textarea">
                            <i className="mdi mdi-calendar-text "></i>
                            <textarea
                              className={`materialize-textarea ml-0 my-2 no-padding  ${
                                touched.about && errors.about
                                  ? "is-invalid"
                                  : ""
                              }`}
                              type="text"
                              value={editProfile.about}
                              name="about"
                              placeholder="Brief Description"
                              onChange={(event) => {
                                handleInputChange(event);
                                setFieldValue("about", event.target.value);
                              }}
                              onClick={() =>
                                setAboutCursorPosition(
                                  aboutRef.current.selectionStart
                                )
                              }
                              // onBlur={() => setAboutCursorPosition(null)}
                              ref={aboutRef}
                            />
                            <div className="emoji-button">
                              <button
                                type="button"
                                className="g-btn m-rounded emojiButtoon p-0 pr-2"
                                aria-describedby={id}
                                onClick={handleEmojiPopOver}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM164.1 325.5C158.3 318.8 148.2 318.1 141.5 323.9C134.8 329.7 134.1 339.8 139.9 346.5C162.1 372.1 200.9 400 255.1 400C311.1 400 349.8 372.1 372.1 346.5C377.9 339.8 377.2 329.7 370.5 323.9C363.8 318.1 353.7 318.8 347.9 325.5C329.9 346.2 299.4 368 255.1 368C212.6 368 182 346.2 164.1 325.5H164.1zM176.4 176C158.7 176 144.4 190.3 144.4 208C144.4 225.7 158.7 240 176.4 240C194 240 208.4 225.7 208.4 208C208.4 190.3 194 176 176.4 176zM336.4 240C354 240 368.4 225.7 368.4 208C368.4 190.3 354 176 336.4 176C318.7 176 304.4 190.3 304.4 208C304.4 225.7 318.7 240 336.4 240z" />
                                </svg>
                              </button>

                              <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleEmojiPopOverClose}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                              >
                                <div className="emojiWrapper">
                                  <Picker
                                    title=""
                                    emoji="point_up"
                                    onSelect={(emoji) =>
                                      handleEmojiSelect(emoji)
                                    }
                                  />
                                </div>
                              </Popover>
                            </div>
                          </div>
                        </div>

                        <ErrorMessage
                          component="div"
                          name="about"
                          className="invalid-feedback col s12"
                        />
                      </div>
                      <div class="row center">
                        <Link
                          className="waves-effect waves-light btn-large bg-primary cancel-btn"
                          to="/account"
                        >
                          Cancel
                        </Link>
                        <button
                          className="waves-effect waves-light btn-large bg-primary"
                          onClick={handleSubmit}
                          disabled={props.profileInputData.buttonDisable}
                        >
                          {props.profileInputData.loadingButtonContent !== null
                            ? props.profileInputData.loadingButtonContent
                            : "Save profile"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  profileInputData: state.users.profileInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EditProfile);
