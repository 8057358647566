import { combineReducers } from "redux";

import UserReducer from "./UserReducer";
import ChangePasswordReducer from "./ChangePasswordReducer";
import notifyReducer from "react-redux-notify";
import PageReducer from "./PageReducer";
import ErrorReducer from "./ErrorReducer";
import PostReducer from "./PostReducer";
import FollowReducer from "./FollowReducer";
import VerificationDocumentReducer from "./VerificationDocumentReducer";
import CommentsReducer from "./CommentsReducer";
import HomeReducer from "./HomeReducer";
import OtherUserReducer from "./OtherUserReducer";
import PostLikesReducer from "./PostLikesReducer";
import ChatReducer from "./ChatReducer";
import NotificationReducer from "./NotificationReducer";
import StoreOwnerReducer from "./StoreOwnerReducer";

export default combineReducers({
  users: UserReducer,
  changePassword: ChangePasswordReducer,
  notifications: notifyReducer,
  page: PageReducer,
  errorDetails: ErrorReducer,
  post: PostReducer,
  follow: FollowReducer,
  docs: VerificationDocumentReducer,
  comment: CommentsReducer,
  home: HomeReducer,
  otherUser: OtherUserReducer,
  postLike: PostLikesReducer,
  chat: ChatReducer,
  notification: NotificationReducer,
  storeOwner : StoreOwnerReducer,
});
