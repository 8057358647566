import React, { Component } from "react";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";
import { Form, Container, Row, Col, Dropdown, Media } from "react-bootstrap";
import { translate, t } from "react-multi-lang";

import {
  setTranslations,
  setDefaultLanguage,
  setLanguage,
  getLanguage,
} from "react-multi-lang";
import en from "../../translations/en.json";
import es from "../../translations/es.json";

const AuthFooter = (props) => {
  return (
    <>
      <footer
        className={`page-footer center social-colored ${
          props.hideTab ? "mb-0" : ""
        }`}
      >
        <div className="container footer-content">
          <div className="row">
            <div className="">
              <h5 className="logo">INDZEE</h5>
            </div>
            <div className="link-wrap">
              <ul className="link-ul">
                {configuration.get("configData.contact_mobile") != "" && (
                  <li>
                    <a className="" href="#!">
                      <i className="mdi mdi-phone"></i>{" "}
                      {configuration.get("configData.contact_mobile")}
                    </a>
                  </li>
                )}
                {configuration.get("configData.contact_address") != "" && (
                  <li>
                    <a className="" href="#!">
                      <i className="mdi mdi-email"></i>{" "}
                      {configuration.get("configData.contact_address")}
                    </a>
                  </li>
                )}
                <li>
                  <a className="" href="#!">
                    <i className="mdi mdi-map-marker"></i> Prestige ozone,
                    hagadur road, varthur main road whitefield Bangalore -
                    560066
                  </a>
                </li>
              </ul>
              <ul className="social-wrap">
                <li className="social">
                  {configuration.get("configData.facebook_link") != "" && (
                    <a href={configuration.get("configData.facebook_link")}>
                      <i className="mdi mdi-facebook"></i>
                    </a>
                  )}
                  {configuration.get("configData.twitter_link") != "" && (
                    <a href={configuration.get("configData.twitter_link")}>
                      <i className="mdi mdi-twitter"></i>
                    </a>
                  )}
                  {configuration.get("configData.instagram_link") != "" && (
                    <a href={configuration.get("configData.instagram_link")}>
                      <i className="mdi mdi-instagram"></i>
                    </a>
                  )}
                  {configuration.get("configData.linkedin_link") != "" && (
                    <a href={configuration.get("configData.linkedin_link")}>
                      <i className="mdi mdi-linkedin"></i>
                    </a>
                  )}
                  {configuration.get("configData.pinterest_link") != "" && (
                    <a href={configuration.get("configData.pinterest_link")}>
                      <i className="mdi mdi-pinterest"></i>
                    </a>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="container">
            {/* &copy; Copyright{" "} */}
            <a className="" href="https://indzee.codegama.info/">
              &copy; {configuration.get("configData.copyright_content")}
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default translate(AuthFooter);
