import React, { useEffect, useState } from "react";
import UserPosts from "../post/UserPosts";
import {
  fetchUserDetailsStart,
  updateVerifyBadgeStatusStart,
} from "../../store/actions/UserAction";
import configuration from "react-global-configuration";
import { connect } from "react-redux";
import { fetchPostsStart } from "../../store/actions/PostAction";
import { becomeStoreOwnerStart } from "../../store/actions/StoreOwnerAction";
import NoDataFound from "../helper/NoDataFound";
import RatingReviewViewerModal from "../helper/RatingReviewViewerModal";
import {
  fetchFollowersStart,
  fetchFollowingStart,
} from "../../store/actions/FollowAction";
import FollowersModal from "../helper/FollowersModal";
import FollowingModal from "../helper/FollowingModal";

const UserProfile = (props) => {
  const [viewratingModal, setViewRatingModal] = useState({
    modalFlag: false,
  });

  const [followFollowingModal, setFollowFollowingModal] = useState({
    followers_status: false,
    following_status: false,
  });

  const [activeTab, setActiveTab] = useState("all");

  useEffect(() => {
    if (props.profile.loading) {
      props.dispatch(fetchUserDetailsStart());
    }
    props.dispatch(fetchFollowingStart());
    props.dispatch(fetchFollowersStart());
  }, []);

  const handleBecomeStoreOwnerRequest = () => {
    props.dispatch(becomeStoreOwnerStart());
  };

  const handleViewRatingModalOpen = () => {
    setViewRatingModal({
      ...viewratingModal,
      modalFlag: true,
    });
  };

  const handleViewRatingModalClose = () => {
    setViewRatingModal({
      ...viewratingModal,
      modalFlag: false,
    });
  };

  const handleFollowersModalOpen = () => {
    setFollowFollowingModal({
      ...followFollowingModal,
      followers_status: true,
    });
  };

  const handleFollowersModalClose = () => {
    setFollowFollowingModal({
      ...followFollowingModal,
      followers_status: false,
    });
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    props.dispatch(fetchPostsStart({ type: activeTab }));
  }, [activeTab]);

  const handleFollowingModalOpen = () => {
    setFollowFollowingModal({
      ...followFollowingModal,
      following_status: true,
    });
  };

  const handleFollowingModalClose = () => {
    setFollowFollowingModal({
      ...followFollowingModal,
      following_status: false,
    });
  };

  return (
    <>
      <div className="content-area">
        {props.profile.loading ? (
          "Loading..."
        ) : (
          <div className="ui-profile">
            <div className="primg mb-0 container">
              <img
                src={props.profile.data.user.picture}
                alt={props.profile.data.user.name}
              />
              <h4 className="name m-0">{props.profile.data.user.name}</h4>
            </div>
            <div className="prinfo card-panel">
              <div className="prname bio-about">
                {props.profile.data.user.about != null &&
                  props.profile.data.user.about != "undefined" &&
                  props.profile.data.user.about != "" && (
                    <p className="mb-0">
                      About : {props.profile.data.user.about}
                    </p>
                  )}
                <div className="statistics mt-3 container">
                  <span
                    className="hover-btn"
                    onClick={() => handleFollowersModalOpen()}
                  >
                    <i className="mdi mdi-account-star"></i> Followers :{" "}
                    {props.profile.data.user.total_followers}
                  </span>
                  <span
                    className="hover-btn"
                    onClick={() => handleFollowingModalOpen()}
                  >
                    <i className="mdi mdi-account-plus"></i> Following :{" "}
                    {props.profile.data.user.total_followings}
                  </span>
                  <span>
                    <i className="mdi mdi-file-image"></i> Images :{" "}
                    {props.profile.data.user.total_images}
                  </span>
                  <span>
                    <i className="mdi mdi-file-video"></i> Videos :{" "}
                    {props.profile.data.user.total_videos}
                  </span>
                  <span>
                    <i className="mdi mdi-cards-outline"></i> Posts :{" "}
                    {props.profile.data.user.total_normal_posts}
                  </span>
                  {props.profile.data.user.store_owner_request == 2 && (
                    <>
                      <span>
                        <i className="mdi mdi-folder-multiple-image"></i>{" "}
                        Product Posts :{" "}
                        {props.profile.data.user.total_product_posts}
                      </span>
                    </>
                  )}
                  <span>
                    <i className="mdi mdi-animation"></i> Total Posts :{" "}
                    {props.profile.data.user.total_posts}
                  </span>
                  <span>
                    <i className="mdi  mdi-shopping"></i> Products Purchased :{" "}
                    {props.profile.data.user.total_products_purchased}
                  </span>
                  {props.profile.data.user.store_owner_request == 2 &&
                    props.profile.data.reviews &&
                    props.profile.data.reviews.length > 0 && (
                      <>
                        <span
                          className="rating-review button"
                          onClick={() => handleViewRatingModalOpen()}
                        >
                          <i className="mdi mdi-star"></i>
                          <div>
                            Rating : 4{" "}
                            <i className="mdi mdi-information-outline info-icon"></i>
                          </div>
                        </span>
                      </>
                    )}
                  {props.profile.data.user.store_owner_request == 2 && (
                    <span>
                      <i className="mdi mdi-cube-send"></i> Products Sold :{" "}
                      {props.profile.data.user.total_products_sold}
                    </span>
                  )}
                </div>
                {/* <div className="statistics-user">
                  <span>
                    {" "}
                    <i className="mdi mdi-email-outline"></i> Email :{" "}
                    {props.profile.data.user.email}
                  </span>
                  {props.profile.data.user.mobile != null &&
                    props.profile.data.user.mobile != "" &&
                    props.profile.data.user.mobile != undefined && (
                      <span>
                        {" "}
                        <i className="mdi mdi-cellphone-iphone"></i> Mobile :{" "}
                        {props.profile.data.user.mobile}
                      </span>
                    )}
                </div> */}
                {props.profile.data.user.store_owner_request == 0 ? (
                  <>
                    <button
                      type="submit"
                      className="waves-effect waves-light btn-large bg-primary btn-white-outline mt-3 hover-effect-primary"
                      onClick={() => handleBecomeStoreOwnerRequest()}
                      disabled={props.storeOwner.buttonDisable}
                    >
                      {props.storeOwner.loadingButtonContent != null
                        ? props.storeOwner.loadingButtonContent
                        : "Become Store Owner"}
                    </button>
                  </>
                ) : props.profile.data.user.store_owner_request == 1 ? (
                  <div className="store-owner-request-status mt-3">
                    <p>
                      <span> Store Owner : </span>{" "}
                      {props.profile.data.user.store_owner_request_formatted}
                    </p>
                  </div>
                ) : (
                  props.profile.data.user.store_owner_request == 3 && (
                    <div className="store-owner-request-status mt-3">
                      <p>
                        <span> Store Owner : </span>{" "}
                        {props.profile.data.user.store_owner_request_formatted}
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        )}

        <div className="container over profile-content">
          <div className="container mobile-remove-spacing">
            <div className="profile-tab-section">
              <span
                onClick={() => handleTabChange("all")}
                className={activeTab == "all" ? "active" : ""}
              >
                <i className="mdi mdi-cards-outline"></i> All
              </span>
              <span
                onClick={() => handleTabChange("image")}
                className={activeTab == "image" ? "active" : ""}
              >
                <i className="mdi mdi-file-image"></i> Photos
              </span>
              <span
                onClick={() => handleTabChange("video")}
                className={activeTab == "video" ? "active" : ""}
              >
                <i className="mdi mdi-file-video"></i> Videos
              </span>
            </div>
            <div className="section">
              {props.posts.loading ? (
                 <NoDataFound message={"Loading..."} />
              ) : props.posts.data.posts.length >
                0 ? (
                <>
                  <ul className="collection mailbox activity z-depth-0 ">
                    {props.posts.data.posts
                      // .filter((post) => post.status == 1)
                      .map((post, index) => (
                        <UserPosts
                          post={post}
                          key={index}
                          location={props.location}
                          history={props.history}
                          ownProfile={true}
                        />
                      ))}
                  </ul>
                </>
              ) : (
                <>
                  <NoDataFound message={"No Post Found"} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <RatingReviewViewerModal
        show={viewratingModal.modalFlag}
        handleViewRatingModalClose={handleViewRatingModalClose}
        reviewData={!props.profile.loading && props.profile.data.reviews}
        username={!props.profile.loading && props.profile.data.user.username}
      />
      <FollowersModal
        show={followFollowingModal.followers_status}
        onHide={handleFollowersModalClose}
        totalFollowers={
          !props.profile.loading && props.profile.data.user.total_followers
        }
      />
      <FollowingModal
        show={followFollowingModal.following_status}
        onHide={handleFollowingModalClose}
        totalFollowers={
          !props.profile.loading && props.profile.data.user.total_followings
        }
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  posts: state.post.posts,
  storeOwner: state.storeOwner.storeOwner,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(UserProfile);
