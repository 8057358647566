import React, { useRef, useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { savePostStart } from "../../store/actions/PostAction";
import {
  fetchSinglePostStart,
  postFileRemoveStart,
  postFileUploadStart,
} from "../../store/actions/PostAction";
import {
  fetchCategoriesStart,
} from "../../store/actions/HomeAction";

const EditPost = (props) => {
  const [editPostInputData, setEditPostInputData] = useState({
    title: "",
    post_type: "",
    amount: "",
    content: "",
    post_file: "",
    size: "",
    category_id: "",
    brand_type: "",
  });

  const [postType, setPostType] = useState(null);

  const [fileData, setFileData] = useState([]);

  const [uploadedFilesID, setUploadedFileID] = useState([]);

  const [fileType, setFileType] = useState(null);

  const [fileTypeBefore, setFileTypeBefore] = useState(null);

  const createPostSchema = Yup.object().shape({
    title: Yup.string().required("Title is required *"),
    post_type: Yup.string(),
    amount: Yup.string().when("post_type", {
      is: (post_type) => post_type == "true",
      then: Yup.string().required("Amount is required *"),
      otherwise: Yup.string(),
    }),
    content: Yup.string().nullable(),
    // post_file: Yup.mixed().when("file_type", {
    //   is: (file_type) => file_type != String(fileTypeBefore),
    //   then: Yup.mixed().required("Image or video is required *"),
    //   otherwise: Yup.array().min(1),
    // }),
    post_file: Yup.array().min(1, "Image or video is required *").nullable()
  });

  useEffect(() => {
    props.dispatch(
      fetchSinglePostStart({
        post_unique_id: props.match.params.id,
      })
    );
    props.dispatch(fetchCategoriesStart());
  }, []);

  useEffect(() => {
    if (!props.singlePost.loading) {
      setEditPostInputData({
        title: props.singlePost.data.post.title,
        post_type: props.singlePost.data.post.post_type,
        amount: props.singlePost.data.post.amount,
        size: props.singlePost.data.post.size,
        brand_type: props.singlePost.data.post.brand_type,
        category_id: props.singlePost.data.post.category_id,
        content: props.singlePost.data.post.content,
        post_file: props.singlePost.data.post.postFiles.map(
          (post) => post.post_file
        ),
      });
      const imageArray = [];
      const uploadedFiles = [];
      props.singlePost.data.post.postFiles.map((post, index) => {
        imageArray.push({
          previewImage: post.post_file,
          file_type: post.file_type,
          file: null,
          index: index + 1,
        });
        uploadedFiles.push({
          file_id: post.post_file_id,
          file_url: post.post_file,
          index: index,
        });
      });
      setFileData(imageArray);
      setUploadedFileID(uploadedFiles);
      setFileType(
        props.singlePost.data.post.postFiles.length > 0 ? props.singlePost.data.post.postFiles[0].file_type == "image" ? 1 : 2 : 1
      );
      setPostType(props.singlePost.data.post.post_type);
      setFileTypeBefore(
        props.singlePost.data.post.postFiles.length > 0 ? props.singlePost.data.post.postFiles[0].file_type == "image" ? 1 : 2 : 1
      );
    }
  }, [props.singlePost.data]);

  const handleCreatePost = (values) => {
    let newData = {};
    var newDate = new Date();
    newData = {
      ...newData,
      amount: postType == 1 ? "" : values.amount,
      title: values.title,
      size: values.size,
      content: values.content,
      post_type: postType,
      category_id: values.category_id,
      brand_type:values.brand_type,
      post_file_id: uploadedFilesID.map((file) => file.file_id).toString(),
      publish_time: newDate,
      post_id: !props.singlePost.loading && props.singlePost.data.post.post_id,
    };
    props.dispatch(savePostStart(newData));
  };

  const handleChangeImageSingle = (event, fileType) => {
    if (event.currentTarget.type === "file") {
      let reader = new FileReader();
      let file = event.currentTarget.files[0];
      reader.onloadend = () => {
        setFileData([
          ...fileData,
          {
            previewImage: reader.result,
            file: file,
            file_type: file.type.match("image") ? "image" : "video",
            index: fileData.length + 1,
          },
        ]);
        props.dispatch(postFileUploadStart({ file: file }));
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const removeFileChange = (index, setFieldValue) => {
    const removedData = fileData.filter((file) => file.index != index);
    const removedFile = uploadedFilesID[index - 1];
    setFileData(removedData);
    if(removedData.length > 0 ){
      setFieldValue(removedData)
    }else{
      setFieldValue("post_file", [])
    }
    props.dispatch(
      postFileRemoveStart({
        file: removedFile.file_url,
        post_file_id: removedFile.file_id,
      })
    );
    const removedFileData = uploadedFilesID.filter(
      (file) => file.file_id != removedFile.file_id
    );
    setUploadedFileID(removedFileData);
  };

  const handleChangeType = () => {
    setPostType(postType == 2 ? 1 : 2);
  };

  const handleReset = () => {
    props.dispatch(
      fetchSinglePostStart({
        post_unique_id: props.match.params.id,
      })
    );
    if(!props.singlePost.loading){
      setEditPostInputData({
        title: props.singlePost.data.post.title,
        size: props.singlePost.data.post.size,
        post_type: props.singlePost.data.post.post_type,
        amount: props.singlePost.data.post.amount,
        content: props.singlePost.data.post.content,
        post_file: props.singlePost.data.post.postFiles,
        brand_type: props.singlePost.data.post.brand_type,
        category_id: props.singlePost.data.post.category_id,
      });
      const imageArray = [];
      const uploadedFiles = [];
      props.singlePost.data.post.postFiles.map((post, index) => {
        imageArray.push({
          previewImage: post.post_file,
          file_type: post.file_type,
          file: null,
          index: index + 1,
        });
        uploadedFiles.push({
          file_id: post.post_file_id,
          file_url: post.post_file,
          index: index,
        });
      });
      setFileData(imageArray);
      setUploadedFileID(uploadedFiles);
      setFileType(
        props.singlePost.data.post.postFiles.length > 0 ? props.singlePost.data.post.postFiles[0].file_type == "image" ? 1 : 2 : 1
      );
      setPostType(props.singlePost.data.post.post_type);
    }
  };

  const handleInputChage = (event) => {
    setEditPostInputData({
      ...editPostInputData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileTypeChange = (type, setFieldValue) => {
    if (type == fileTypeBefore) {
      handleReset();
      setFileType(type);
    } else {
      setFieldValue("post_file", []);
      setFileType(type);
      setFileData([]);
      let removedFile = [];
      const removedFileData = uploadedFilesID.filter(
        (file) => file.file_id != removedFile.file_id
      );
      for (let i = 0; i < uploadedFilesID.length; i++) {
        removedFile = uploadedFilesID[i];
        // props.dispatch(
        //   postFileRemoveStart({
        //     file: removedFileData[i].file_url,
        //     post_file_id: removedFileData[i].file_id,
        //   })
        // );
      }
      setUploadedFileID([]);
    }
  };

  useEffect(() => {
    if (!props.fileUpload.loading && props.fileUpload.data.post_file_id) {
      setUploadedFileID([
        ...uploadedFilesID,
        {
          file_id: props.fileUpload.data.post_file_id,
          file_url: props.fileUpload.data.file_url,
          index: uploadedFilesID.length,
        },
      ]);
    }
  }, [props.fileUpload.data]);

  return (
    <>
      <div className="content-area">
        <div className="pagehead-bg   primary-bg"></div>
        <div className="container has-pagehead is-pagetitle">
          <div className="section">
            <h5 className="pagetitle">Edit Post</h5>
          </div>
        </div>
        <div className="container over ">
          <div className="section">
            {props.singlePost.loading ? (
              "Loading..."
            ) : (
              <>
                <div className="row mb0">
                  <div className="col s12 pad-0">
                    <h5 className="bot-20 sec-tit  ">Post Details</h5>
                    {localStorage.getItem("store_owner") != 2 && (
                      <p className="note mb-0">
                        <span>Note :</span> To Become a store owner apply from
                        profile.
                      </p>
                    )}
                    <Formik
                      enableReinitialize
                      initialValues={editPostInputData}
                      validationSchema={createPostSchema}
                      onSubmit={(values) => {
                        handleCreatePost(values);
                      }}
                    >
                      {({ touched, errors, setFieldValue, isSubmitting }) => (
                        <Form noValidate>
                          <div className="row">
                            <div className="input-field withicon col s12">
                              <i className="mdi mdi-calendar"></i>
                              <Field
                                className={`input-bordered no-padding  ${
                                  touched.title && errors.title
                                    ? "is-invalid"
                                    : ""
                                }`}
                                type="text"
                                name="title"
                                placeholder="Post Title"
                                onChange={(event) => {
                                  handleInputChage(event);
                                  setFieldValue("amount", event.target.value);
                                }}
                              />
                            </div>
                            <ErrorMessage
                              component="div"
                              name="title"
                              className="invalid-feedback col s12"
                            />
                          </div>
                          <div className="">
                            <div
                              className="radio-select-post"
                              role="group"
                              aria-labelledby="my-radio-group"
                            >
                              <label className="post-image-display">
                                <input
                                  type="radio"
                                  name="file_type"
                                  className="with-gap"
                                  checked={fileType == 1 ? true : false}
                                  onChange={() => {
                                    setFieldValue("file_type", "image");
                                    handleFileTypeChange(1, setFieldValue);
                                  }}
                                />
                                <span>Image Post</span>
                              </label>
                              <label className="ml-3 post-product-display">
                                <input
                                  type="radio"
                                  name="file_type"
                                  className="with-gap"
                                  checked={fileType == 2 ? true : false}
                                  onChange={() => {
                                    setFieldValue("file_type", "video");
                                    handleFileTypeChange(2, setFieldValue);
                                  }}
                                />
                                <span>Video Post</span>
                              </label>
                            </div>
                            <ErrorMessage
                              component="div"
                              name="file_type"
                              className="invalid-feedback mt-0 mb-3"
                            />
                          </div>

                          {fileType != null && (
                            <div
                              className={`post-multi-wrapper col s12 ${
                                fileData.length > 0 && fileType == 1
                                  ? "col s12 image"
                                  : ""
                              }`}
                            >
                              {fileType != null ? (
                                fileType == 1 ? (
                                  <>
                                    <div className="post-img-sec">
                                      <div
                                        className={`file-field  input-field  center-file-button custom-round-file ${
                                          fileData.length > 0 ? "center" : ""
                                        }`}
                                      >
                                        <div className="btn">
                                          {/* {props.fileUpload.loading ? (
                                        <>
                                          <div class="file-loader">
                                            <div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div></div>
                                            </div>
                                            <div>
                                              <div></div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <span>
                                          <i className="mdi mdi-plus-circle-outline"></i>
                                        </span>
                                      )} */}
                                          <span>
                                            <i className="mdi mdi-plus-circle-outline"></i>
                                          </span>
                                          <input
                                            type="file"
                                            name="post_file"
                                            accept="image/*"
                                            onChange={(event) => {
                                              handleChangeImageSingle(event);
                                              setFieldValue(
                                                "post_file",
                                                event.target.files[0]
                                              );
                                            }}
                                            disabled={
                                              props.fileUpload.buttonDisable
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <ErrorMessage
                                      component="div"
                                      name="post_file"
                                      className="invalid-feedback  mb-3"
                                    />
                                  </>
                                ) : (
                                  <>
                                    {fileData.length < 1 && (
                                      <>
                                        <div className="post-img-sec">
                                          <div
                                            className={`file-field  input-field  center-file-button custom-round-file ${
                                              fileData.length > 0
                                                ? "center"
                                                : ""
                                            }`}
                                          >
                                            <div className="btn">
                                              <span>
                                                <i className="mdi mdi-plus-circle-outline"></i>
                                              </span>
                                              <input
                                                type="file"
                                                name="post_file"
                                                accept="video/*"
                                                onChange={(event) => {
                                                  handleChangeImageSingle(
                                                    event
                                                  );
                                                  setFieldValue(
                                                    "post_file",
                                                    event.target.files[0]
                                                  );
                                                }}
                                                disabled={
                                                  props.fileUpload.buttonDisable
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <ErrorMessage
                                          component="div"
                                          name="post_file"
                                          className="invalid-feedback mb-3"
                                        />
                                      </>
                                    )}
                                  </>
                                )
                              ) : null}
                              {/* mutiple image */}
                              {fileData.length > 0 && (
                                <>
                                  {fileData.map((fileData, index) => (
                                    <>
                                      {fileData.previewImage &&
                                      fileData.previewImage != "" &&
                                      fileData.file_type == "image" ? (
                                        <div
                                          className={`file-wrapper ${
                                            uploadedFilesID.filter(
                                              (uploadFile) =>
                                                uploadFile.index == index
                                            ).length > 0
                                              ? ""
                                              : props.fileUpload.loading
                                              ? "loading"
                                              : ""
                                          }`}
                                        >
                                          {uploadedFilesID.filter(
                                            (uploadFile) =>
                                              uploadFile.index == index
                                          ).length > 0
                                            ? null
                                            : props.fileUpload.loading && (
                                                <>
                                                  <div className="files-loader-wrapper">
                                                    <div className="loader"></div>
                                                  </div>
                                                </>
                                              )}
                                          <img
                                            src={fileData.previewImage}
                                            alt=""
                                            key={index}
                                          />
                                          {uploadedFilesID.filter(
                                            (uploadFile) =>
                                              uploadFile.index == index
                                          ).length > 0 && (
                                            <div
                                              className="file-remover"
                                              onClick={() =>
                                                removeFileChange(fileData.index , setFieldValue)
                                              }
                                            >
                                              <i className="mdi mdi-delete text-danger"></i>
                                            </div>
                                          )}
                                        </div>
                                      ) : (
                                        <>
                                          <div
                                            className={`file-wrapper ${
                                              uploadedFilesID.filter(
                                                (uploadFile) =>
                                                  uploadFile.index == index
                                              ).length > 0
                                                ? ""
                                                : props.fileUpload.loading
                                                ? "loading"
                                                : ""
                                            }`}
                                          >
                                            {uploadedFilesID.filter(
                                              (uploadFile) =>
                                                uploadFile.index == index
                                            ).length > 0
                                              ? null
                                              : props.fileUpload.loading && (
                                                  <>
                                                    <div className="files-loader-wrapper">
                                                      <div className="loader"></div>
                                                    </div>
                                                  </>
                                                )}
                                            <video
                                              autoplay
                                              controls
                                              id="myVideo"
                                              className="user-profile1 w-100"
                                            >
                                              <source
                                                src={fileData.previewImage}
                                                type="video/mp4"
                                              />
                                            </video>
                                            {uploadedFilesID.filter(
                                              (uploadFile) =>
                                                uploadFile.index == index
                                            ).length > 0 && (
                                              <div
                                                className="file-remover"
                                                onClick={() =>
                                                  removeFileChange(
                                                    fileData.index
                                                  )
                                                }
                                              >
                                                <i className="mdi mdi-delete text-danger"></i>
                                              </div>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </>
                                  ))}
                                </>
                              )}
                            </div>
                          )}
                          {fileType != null ? (
                            fileType == 1 ? (
                              <>
                                <p className="note mb-3 ">
                                  <span>Note :</span> Accepted Image Format
                                  JPG,JPEG,PNG,BMP,etc.
                                </p>
                              </>
                            ) : (
                              <>
                                <p className="note mb-3">
                                  <span>Note :</span> Accepted Video Format
                                  MP4,MOV,AVI,MKV,etc.
                                </p>
                              </>
                            )
                          ) : null}

                          <div className="row">
                            <div className="col-md-4">
                            {props.categories.loading
                                ? "Loading..."
                            : props.categories.data.post_categories.length > 0
                                ? 
                              <div class="input-field withicon col s12">
                                <select
                                  name="category_id"
                                  id="category_id"
                                  className={`input-bordered no-padding d-block ${
                                    touched.category_id && errors.category_id
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={(event) => {
                                    setFieldValue("category_id", event.target.value);
                                  }}
                                >
                                  <option value="">Category</option>
                                  {props.categories.data.post_categories.map((category) => 
                                    <option 
                                      value={category.category_id} 
                                      selected={editPostInputData.category_id == category.category_id ? true : false}
                                    >
                                          {category.name}</option>
                                  )}
                                </select>
                                <ErrorMessage
                                  component="div"
                                  name="token_type"
                                  className="invalid-feedback col s12"
                                />
                              </div>
                              : ""}
                            </div>
                            <div className="col-md-4">
                              <div className="input-field withicon col s12">
                            
                                <select
                                  name="brand_type"
                                  id="brand_type"
                                  className={`input-bordered no-padding d-block ${
                                    touched.brand_type && errors.brand_type
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={(event) => {
                                    setFieldValue("brand_type", event.target.value);
                                  }}
                                >
                                  <option value="">Usage Type</option>
                                  <option 
                                    value="SPARINGLY_USED"
                                    selected={editPostInputData.brand_type == "SPARINGLY_USED" ? true : false}
                                  >SPARINGLY USED</option>
                                  <option 
                                    value="USED"
                                    selected={editPostInputData.brand_type == "USED" ? true : false}
                                  >USED</option>
                                  <option 
                                    value="USED_ONCE"
                                    selected={editPostInputData.brand_type == "USED_ONCE" ? true : false}
                                  >USED ONCE</option>
                                  <option 
                                    value="ALL_NEW"
                                    selected={editPostInputData.brand_type == "ALL_NEW" ? true : false}
                                  >ALL NEW</option>
                                </select>
                                <ErrorMessage
                                  component="div"
                                  name="token_type"
                                  className="invalid-feedback col s12"
                                />
                              </div>
                            </div>
                          </div>
                          {localStorage.getItem("store_owner") == 2 && (
                            <div className="product-post-check">
                              <label className="ml-3 post-product-display">
                                <Field
                                  type="checkbox"
                                  name="post_type"
                                  className="with-gap"
                                  checked={postType == 2 ? true : false}
                                  onChange={() => {
                                    handleChangeType(2);
                                    setFieldValue(
                                      "post_type",
                                      postType == 2 ? false : true
                                    );
                                  }}
                                />
                                <span>Product Post</span>
                              </label>
                            </div>
                          )}
                          {postType != null && postType == 2 && (
                            <>
                              <div className="row">
                                <div className="input-field withicon col s12">
                                  <i className="mdi mdi-coin"></i>
                                  <Field
                                    className={`input-bordered no-padding  ${
                                      touched.amount && errors.amount
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    type="text"
                                    name="amount"
                                    placeholder="Amount"
                                  />
                                </div>
                                <ErrorMessage
                                  component="div"
                                  name="amount"
                                  className="invalid-feedback col s12"
                                />
                              </div>
                              <div className="row">
                                <h6>Size</h6>
                                <div
                                  className="radio-select-post"
                                  role="group"
                                  aria-labelledby="my-radio-group"
                                >
                                  <label className="post-image-display">
                                    <input
                                      type="radio"
                                      name="size"
                                      className="with-gap"
                                      defaultChecked={editPostInputData.size == "XS" ? true : false}
                                      onChange={() => {
                                        setFieldValue("size", "XS");
                                      }}
                                    />
                                    <span>XS</span>
                                  </label>
                                  <label className="ml-3 post-product-display">
                                    <input
                                      type="radio"
                                      name="size"
                                      className="with-gap"
                                      defaultChecked={editPostInputData.size == "S" ? true : false}
                                      onChange={() => {
                                        setFieldValue("size", "S");
                                      }}
                                    />
                                    <span>S</span>
                                  </label>
                                  <label className="ml-3 post-product-display">
                                    <input
                                      type="radio"
                                      name="size"
                                      className="with-gap"
                                      defaultChecked={editPostInputData.size == "M" ? true : false}
                                      onChange={() => {
                                        setFieldValue("size", "M");
                                      }}
                                    />
                                    <span>M</span>
                                  </label>
                                  <label className="ml-3 post-product-display">
                                    <input
                                      type="radio"
                                      name="size"
                                      className="with-gap"
                                      defaultChecked={editPostInputData.size == "L" ? true : false}
                                      onChange={() => {
                                        setFieldValue("size", "L");
                                      }}
                                    />
                                    <span>L</span>
                                  </label>
                                  <label className="ml-3 post-product-display">
                                    <input
                                      type="radio"
                                      name="size"
                                      className="with-gap"
                                      defaultChecked={editPostInputData.size == "XL" ? true : false}
                                      onChange={() => {
                                        setFieldValue("size", "XL");
                                      }}
                                    />
                                    <span>XL</span>
                                  </label>
                                </div>
                                <ErrorMessage
                                  component="div"
                                  name="file_type"
                                  className="invalid-feedback mt-0 mb-3"
                                />
                              </div>
                            </>
                          )}
                          <div className="row">
                            <div className="input-field withicon col s12 textarea">
                              <i className="mdi mdi-calendar-text "></i>
                              <Field
                                as="textarea"
                                className={`materialize-textarea ml-0 my-2 no-padding  ${
                                  touched.content && errors.content
                                    ? "is-invalid"
                                    : ""
                                }`}
                                type="text"
                                name="content"
                                placeholder="Brief Description"
                                onChange={(event) => {
                                  handleInputChage(event);
                                  setFieldValue(
                                    event.target.name,
                                    event.target.value
                                  );
                                }}
                              />
                            </div>
                            <ErrorMessage
                              component="div"
                              name="content"
                              className="invalid-feedback col s12"
                            />
                          </div>

                          <div class="row center">
                            <div className="button-wrapper">
                              <Link
                                to="/post-list"
                                className="waves-effect waves-light btn-large bg-primary cancel-btn"
                              >
                                Cancel
                              </Link>
                              <button
                                type="button"
                                className="waves-effect waves-light btn-large bg-primary"
                                onClick={() => handleReset()}
                              >
                                Reset
                              </button>
                              <button
                                type="submit"
                                className="waves-effect waves-light btn-large bg-primary"
                                disabled={props.savePost.buttonDisable}
                              >
                                {props.savePost.loadingButtonContent !== null
                                  ? props.savePost.loadingButtonContent
                                  : "Save Post"}
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  savePost: state.post.savePost,
  singlePost: state.post.singlePost,
  fileUpload: state.post.fileUpload,
  categories: state.home.categories,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EditPost);
