import React from "react";
import { Notify } from "react-redux-notify";
import EmptyHeader from "./Header/EmptyHeader";

const EmptyLayoutWithoutFooter = (props) => {
  return (
    <>
      <Notify position="TopRight" />
      <EmptyHeader />
      <div className="main-wrap-sec">
        {React.cloneElement(props.children)}
      </div>
      <br />
    </>
  );
};

export default EmptyLayoutWithoutFooter;
