import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const PostLikeViewer = (props) => {
  return (
    <>
      <Modal
        className="modal-dialog-center custom-modal postlike-modal"
        size="md"
        centered
        show={props.show}
        onHide={props.handleLikeViwerModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Likes</Modal.Title>
        </Modal.Header>
        <Modal.Body className="followFollowingView">
          <div className="follow-following-view">
            {props.likedata.length > 0 &&
              props.likedata.map((user, index) => (
                <>
                  <div className="user" key={index}>
                    <div className="user-info">
                      <img src={user.user_picture} alt="" />
                      <div>
                        <h6>{user.username}</h6>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PostLikeViewer;
