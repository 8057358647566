import React, { useEffect, useRef, useState } from "react";
import {
  addMessageContent,
  fetchChatMessageStart,
  fetchChatMoreDataStart,
  clearSaveChatUsers,
} from "../../store/actions/ChatAction";
import { connect } from "react-redux";
import io from "socket.io-client";
import configuration from "react-global-configuration";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import ChatLoadMore from "../helper/ChatLoadMore";
import NoDataFound from "../helper/NoDataFound";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  postAvailableStatusUpdateStart,
  fetchSinglePostStart,
} from "../../store/actions/PostAction";
import id from "date-fns/esm/locale/id/index.js";

let chatSocket;

const SingleChat = (props) => {
  const toUserId = new URLSearchParams(props.location.search).get("to_user");

  const fromUserId = new URLSearchParams(props.location.search).get(
    "from_user"
  );

  const post_id = new URLSearchParams(props.location.search).get("post_id");

  const [initialHeight, setInitialHeight] = useState(0);

  const [loadingState, setLoadingState] = useState(
    props.postAvailableStatusUpdate.loading
  );

  const [availableStatus, setAvailableStatus] = useState(null);

  const [skipInitalRender, setSkipInitialRender] = useState(false);

  const chatSchema = Yup.object().shape({
    message: Yup.string().required("required *"),
  });

  const messageRef = useRef();

  useEffect(() => {
    props.dispatch(clearSaveChatUsers());
    props.dispatch(
      fetchChatMessageStart({
        to_user_id: toUserId,
        from_user_id: fromUserId,
      })
    );
    // let chatSocketUrl = configuration.get("configData.chat_socket_url");

    let chatSocketUrl = "https://demo-indzee.codegama.info:4000/";

    if (chatSocketUrl === "") {
      const notificationMessage = getErrorNotificationMessage(
        "Socket URL is not configured. Chat wil not work..."
      );
      props.dispatch(createNotification(notificationMessage));
      window.location.assign("/");
    }
    if (messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
    chatSocketConnect(toUserId);
  }, []);

  useEffect(() => {
    scrollDown();
  }, [props.chatMessages.loading]);

  const scrollDown = () => {
    const objDiv = document.getElementById("messages-holder");

    if (objDiv != null) {
      let differenceNumber =
        objDiv.offsetHeight > objDiv.scrollHeight
          ? objDiv.offsetHeight - objDiv.scrollHeight
          : objDiv.scrollHeight - objDiv.offsetHeight;
      if (differenceNumber > 100) {
        objDiv.scrollTop = objDiv.scrollHeight;
      } else {
        objDiv.scrollTop = initialHeight;
        setInitialHeight(initialHeight + 20);
      }
    }
  };

  const chatSocketConnect = (to_user_id) => {
    // check the socket url is configured
    // let chatSocketUrl = configuration.get("configData.chat_socket_url");
    let chatSocketUrl = "https://demo-indzee.codegama.info:4000/";

    console.log("chatSocket", chatSocketUrl);
    console.log("Input ID", to_user_id);
    if (chatSocketUrl) {
      chatSocket = io(chatSocketUrl, {
        query:
          `commonid:'user_id_` +
          localStorage.getItem("userId") +
          `_to_user_id_` +
          to_user_id +
          `',myid:` +
          localStorage.getItem("userId"),
      });
      console.log("chatSocket", chatSocket);
      chatSocket.emit("update sender", {
        commonid:
          "user_id_" +
          localStorage.getItem("userId") +
          "_to_user_id_" +
          to_user_id,
        myid: localStorage.getItem("userId"),
      });
      let chatContent;
      chatSocket.on("message", (newData) => {
        let content = [];
        content.push(newData);
        // chatContent = [...this.state.chatData, ...content];
        // this.setState({ chatData: chatContent });
        console.log(content);
        props.dispatch(addMessageContent(content));
      });
    }
  };

  const handleChatSubmit = (values) => {
    // let chatSocketUrl = configuration.get("configData.chat_socket_url");

    let chatSocketUrl = "https://demo-indzee.codegama.info:4000/";

    if (chatSocketUrl != undefined) {
      let chatData = [
        {
          from_user_id: localStorage.getItem("userId"),
          to_user_id: toUserId,
          message: values.message,
          type: "uu",
          user_picture: localStorage.getItem("user_picture"),
          loggedin_user_id: localStorage.getItem("userId"),
          created: "just now",
          created_at: Date(),
          from_username: localStorage.getItem("username"),
          from_displayname: localStorage.getItem("name"),
          from_userpicture: localStorage.getItem("user_picture"),
          from_user_unique_id: "",
          to_username: "",
          to_displayname: "",
          to_userpicture: "",
          to_user_unique_id: "",
        },
      ];
      chatSocket.emit("message", chatData[0]);
      props.dispatch(addMessageContent(chatData));
    }
  };

  const handleChatInputFocus = (status) => {
    if (status) {
      if (window.innerWidth < 576) {
        props.setHideTab(status);
      }
    } else {
      if (window.innerWidth < 576) {
        props.setHideTab(status);
      }
    }
  };

  const handlePostAvailableChange = (id, status) => {
    props.dispatch(
      postAvailableStatusUpdateStart({
        post_id: id,
        is_available: status,
        product_purchased_user_id: toUserId,
      })
    );
    setLoadingState(true);
  };

  useEffect(() => {
    if (post_id) {
      props.dispatch(
        fetchSinglePostStart({
          post_unique_id: post_id,
        })
      );
      setSkipInitialRender(true);
    }
  }, []);

  useEffect(() => {
    if (!props.postAvailableStatusUpdate.loading && skipInitalRender) {
      if (post_id == props.postAvailableStatusUpdate.data.post_id) {
        setLoadingState(false);
        setAvailableStatus(
          props.postAvailableStatusUpdate.data.is_available == 1
            ? "available"
            : "sold"
        );
      }
    }
  }, [props.postAvailableStatusUpdate.data]);

  useEffect(() => {
    if (!props.singlePost.loading) {
      setAvailableStatus(props.singlePost.data.post.is_available);
    }
  }, [props.singlePost.data]);

  return (
    <>
      <div className="content-area">
        <div className="pagehead-bg   primary-bg"></div>
        <div className="container has-pagehead is-pagetitle">
          <div className="section">
            <div className="single-chat-header">
              <h5 className="pagetitle">Chat - </h5>
              <img
                src={
                  !props.chatMessages.loading && props.chatMessages.data.user &&
                  props.chatMessages.data.user.picture
                }
                alt=""
              />
              <h5 className="username">{props.match.params.username}</h5>
            </div>
          </div>
        </div>
        <div className="container over chat-container-wrapper">
          <div className="section">
            <div className="spacer"></div>
            <ChatLoadMore
              fetchAction={fetchChatMoreDataStart}
              toUserId={toUserId}
              chatMessages={props.chatMessages}
            >
              <ul
                className="chat-message chat-area"
                ref={messageRef}
                id="messages-holder"
              >
                {props.chatMessages.loading ? (
                  <div class="is-typing">
                    <div class="jump1"></div>
                    <div class="jump2"></div>
                    <div class="jump3"></div>
                  </div>
                ) : props.chatMessages.data.messages.length > 0 ? (
                  props.chatMessages.data.messages.map((chatMessage, index) => (
                    <>
                      {chatMessage.from_user_id ==
                        localStorage.getItem("userId") ? (
                        <>
                          <li
                            className="user-avatar rightside message"
                            key={index}
                          >
                            <div className="message-wrapper">
                              <div>
                                <div className="msg-area status available ">
                                  <p className="msg">{chatMessage.message}</p>
                                </div>
                                <span className="time">
                                  {chatMessage.created}
                                </span>
                              </div>
                              <img
                                src={localStorage.getItem("user_picture")}
                                alt="sent_user"
                                title="sent_user"
                                className="circle userpic"
                              />
                            </div>
                          </li>
                        </>
                      ) : (
                        <li
                          className="user-avatar leftside message"
                          key={index}
                        >
                          <div className="message-wrapper">
                            <div>
                              <div className="msg-area status available ">
                                <p className="msg">{chatMessage.message}</p>
                              </div>
                              <span className="time">
                                {chatMessage.created}
                              </span>
                            </div>
                            <img
                              src={chatMessage.from_userpicture}
                              alt="receive_user"
                              title="receive_user"
                              className="circle userpic"
                            />
                          </div>
                        </li>
                      )}
                    </>
                  ))
                ) : (
                  <NoDataFound message="No Chat Message Found" />
                )}
              </ul>
            </ChatLoadMore>
            <div className="chat-message-area">
              <Formik
                initialValues={{ message: "" }}
                validationSchema={chatSchema}
                onSubmit={async (values, { resetForm }) => {
                  await handleChatSubmit(values);
                  resetForm();
                }}
              >
                {({ touched, errors, isSubmitting }) => (
                  <Form noValidate>
                    <div className="comment-reply-box">
                      <div className="row d-flex align-items-center m-0">
                        <div
                          className={`input-field withicon col s12 m-0 ${touched.message && errors.message
                            ? "is-invalid"
                            : ""
                            }`}
                        >
                          <i className="mdi mdi-email-outline"></i>
                          <Field
                            className={`chat-input no-padding ${touched.message && errors.message
                              ? "is-invalid"
                              : ""
                              }`}
                            type="message"
                            name="message"
                            autoComplete="none"
                            placeholder="Type a messgae"
                            onFocus={() => handleChatInputFocus(true)}
                            onBlur={() => handleChatInputFocus(false)}
                          />
                        </div>
                        <div className="col ml-auto center mb-0">
                          <div className="chat-button-wrapper">
                            <button
                              type="submit"
                              className="waves-effect waves-light btn-large bg-pri  mary round-btn-icon m-0"
                            >
                              <i className="mdi mdi-send"></i>
                            </button>
                            {localStorage.getItem("userId") != fromUserId &&
                              post_id != null
                              ? availableStatus == "available" && (
                                <>
                                  <button
                                    type="button"
                                    className="waves-effect waves-light btn bg-primary m-0"
                                    disabled={
                                      loadingState
                                        ? props.postAvailableStatusUpdate
                                          .buttonDisable
                                        : false
                                    }
                                    onClick={() =>
                                      handlePostAvailableChange(
                                        props.singlePost.data.post.post_id,
                                        0
                                      )
                                    }
                                  >
                                    {loadingState
                                      ? props.postAvailableStatusUpdate
                                        .loadingButtonContent
                                      : "Sell"}
                                  </button>
                                </>
                              )
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage
                      component="div"
                      name="message"
                      className="invalid-feedback col s12 m-0"
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  chatMessages: state.chat.messages,
  postAvailableStatusUpdate: state.post.postAvailableStatusUpdate,
  singlePost: state.post.singlePost,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleChat);
