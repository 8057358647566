import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Imgix from "react-imgix";
import LazyLoad from 'react-lazyload';

const ExplorePostCard = (props) => {
  const { post } = props;

  const [postLikes, setPostLikes] = useState(post.like_count);

  const [likeStatus, setLikeStatus] = useState(
    props.post.post_likes.length > 0 &&
      props.post.post_likes.filter((post) => post.user_id == props.post.user_id)
      ? true
      : false
  );


  useEffect(() => {
    if (!props.postLike.saveLike.loading) {
      if (props.postLike.saveLike.data.data.post_id == props.post.post_id) {
        if (props.postLike.saveLike.data.message == "Liked") {
          setPostLikes(postLikes + 1);
          setLikeStatus(true);
        } else {
          setPostLikes(postLikes - 1 < 0 ? 0 : postLikes - 1);
          setLikeStatus(false);
        }
      }
    }
  }, [props.postLike.saveLike.data]);

  return (
    <>
      <div className="grid-item">
        <Link
          to={`/post/${post.post_unique_id}`}
          className="img-wrap round"
        // href={post.postFiles[0].post_file}
        // data-fancybox="images"
        // data-caption={post.title}
        >
          {post.postFiles[0].file_type == "image" ? (
            // <LazyLoad height={200} once>
            //   <Imgix
            //     alt="image"
            //     className="img-responsive"
            //     src={post.postFiles[0].post_file}
            //     width={100}
            //     disableQualityByDPR
            //   />
            // </LazyLoad>
            <picture>
                <source className="lazyload img-responsive" type="image/webp" srcset={post.postFiles[0].post_file}/>
                <img data-src={post.postFiles[0].post_file} type="image/webp" className=" lazyload img-responsive"
                    alt="image" loading="lazy"/>
            </picture>
          ) : (
            <video
              autoplay
              controls
              preload="none"
	            muted
              id="myVideo"
              className="user-profile1 w-100 lazyload"
            >
              <source src={post.postFiles[0].post_file} type="video/mp4" className="lazyload" />
            </video>
          )}
          {post.post_type == 2 && post.is_available == "sold" && (
            <>
              <div className="sold-tag-img-sec explore">
                {/* <LazyLoad height={200} once>
                  <img
                    src={
                      window.location.origin + "/assets/images/sold-out-tag.png"
                    }
                    alt=""
                    className="sold-tag-img"
                  />
                </LazyLoad> */}
                <picture>
                    <source className="lazyload sold-tag-img" type="image/png" srcset={
                      window.location.origin + "/assets/images/sold-out-tag.png"
                    }/>
                    <img data-src={
                      window.location.origin + "/assets/images/sold-out-tag.png"
                    } type="image/png" class=" lazyload sold-tag-img"
                        alt="" loading="lazy"/>
                </picture>
              </div>
            </>
          )}
        </Link>

        {/* <div className="user-actions">
          <button
            className={`no-btn m-0 ${likeStatus ? "active" : ""}`}
            onClick={() => props.handleLike(post.post_id)}
          >
            {likeStatus ? (
              <i className="mdi mdi-heart"></i>
            ) : (
              <i className="mdi mdi-heart-outline"></i>
            )}
            <span>{postLikes}</span>
          </button>
        </div> */}
        <div className="product-img-info-sec">
          <ul className="list-unstyled product-img-info">
            {post.size ?
              <li>
                <span>{post.size}</span>
              </li>
              : ''}
            <li>
              <span>{post.amount_formatted}</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ExplorePostCard;
