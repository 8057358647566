import React from 'react';
import Icons from './SvgIcons.svg';
import PropTypes from 'prop-types';

const CustomSvgIcon = ({ id , styles }) => {
  return (
    <>
       <svg className={styles}>
        <use xlinkHref={`${Icons}#${id}`} />
      </svg>
    </>
  )
}

CustomSvgIcon.prototype = {
  id: PropTypes.string.isRequired,
  styles : PropTypes.string
}

export default CustomSvgIcon
