import React from 'react'
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import NoDataFound from './NoDataFound';

const FollowingModal = (props) => {
  return (
    <>
      <Modal
        className="modal-dialog-center custom-modal followfollowing-modal"
        size="md"
        centered
        show={props.show}
        onHide={props.onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>Followers - {props.totalFollowers}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="followFollowingView">
          <div className="follow-following-view">
            {!props.following.loading && props.following.data.followers.length > 0 ?
              props.following.data.followers.map((user, index) => (
                <>
                  <Link className="user" key={index} to={`/user/profile/${user.otherUser.user_unique_id}`}>
                    <div className="user-info">
                      <img src={user.otherUser.picture} alt="" />
                      <div>
                        <h6>{user.otherUser.username != "" ? user.otherUser.username : user.otherUser.name}</h6>
                        <p className="review-desc">{user.otherUser.email}</p>
                      </div>
                    </div>
                  </Link>
                </>
              )) : (
                <NoDataFound message="Not followings , follow other users" />
              )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToPros = (state) => ({
  following : state.follow.following
});


export default connect(mapStateToPros, null)(FollowingModal)
