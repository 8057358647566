import React from "react";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { translate, t } from "react-multi-lang";
import { useHistory } from "react-router";

const EmptyHeader = () => {

  const history = useHistory();

  return (
    <>
      <nav class="fix_topscroll logo_on_fixed  topbar navigation">
        <div class="nav-wrapper container">
          <a id="logo-container" href="/" class=" brand-logo ">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 400.000000 400.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
              >
                <path
                  d="M1810 3566 c0 -3 13 -24 29 -48 16 -24 34 -62 41 -84 l12 -42 101 -7 c248 -17 377 -112 444 -325 37 -121 43 -235 43 -931 l0 -676 49 -7 c27 -4 75
-15 107 -26 31 -11 59 -20 61 -20 2 0 2 343 0 763 -3 722 -5 766 -24 842 -45 182 -95 285 -182 374 -130 134 -294 191 -547 191 -74 0 -134 -2 -134 -4z"
                ></path>
                <path d="M1482 3541 c-55 -14 -110 -58 -142 -114 -87 -152 22 -337 200 -337 133 0 230 99 230 234 0 138 -150 251 -288 217z"></path>
                <path d="M1226 3093 c-4 -9 -16 -62 -26 -118 -19 -94 -20 -148 -20 -971 0 -819 1 -876 19 -975 39 -208 90 -318 197 -425 76 -77 164 -127 279 -159 79 -22 361 -32 448 -16 79 15 167 42 167 51 0 3 -22 16 -48 28 -26 12 -71 41 -99 65 l-51 44 -64 -9 c-34 -5 -103 -6 -153 -3 -258 19 -391 134 -451 390 -18 75 -19 134 -19 995 0 503 3 931 6 950 6 33 5 36 -34 54 -22 11 -63 41 -91 68 -27 26 -51 48 -52 48 -1 0 -5 -8 -8 -17z"></path>
                <path d="M2356 1304 c-27 -9 -61 -23 -75 -31 -53 -33 -123 -110 -149 -163 -24 -48 -27 -67 -27 -155 0 -87 3 -107 26 -155 103 -215 370 -276 563 -129 86 66 136 170 136 284 0 98 -27 170 -90 238 -101 111 -246 153 -384 111z"></path>
              </g>
            </svg>
            INDZEE */}
            <img src={configuration.get("configData.site_logo")} alt="" />
          </a>
          {history.location.pathname != "/" && (
            <a
              href="#"
              onClick={() => history.goBack()}
              className="waves-effect waves-circle navicon back-button htmlmode show-on-large "
            >
              <i className="mdi mdi-chevron-left" data-page=""></i>
            </a>
          )}
          <a
            href="#"
            data-target="slide-settings"
            class="waves-effect waves-circle navicon right sidenav-trigger show-on-large hide hidden"
          >
            <i class="app-icon-equalizer2"></i>
          </a>
        </div>
      </nav>
    </>
  );
};

export default translate(EmptyHeader);
