import React from "react";
import NoDataFound from "../helper/NoDataFound";
import { Link } from "react-router-dom";

const PurchasedPostListItem = (props) => {
  const { post } = props;

  return (
    <>
      <tr>
        <td className="post-list-img-sec">
          {post.postFiles.length > 0 ? (
            <>
              {post.postFiles[0].file_type == "image" ? (
                <>
                  <img
                    src={post.postFiles[0].post_file}
                    className="post-list-img max-width-100"
                  />
                </>
              ) : (
                post.postFiles[0].file_type == "video" && (
                  <>
                    <video
                      autoplay
                      controls
                      id="myVideo"
                      className="user-profile1 w-100"
                    >
                      <source
                        src={post.postFiles[0].post_file}
                        type="video/mp4"
                      />
                    </video>
                  </>
                )
              )}
            </>
          ) : (
            <p>No image</p>
          )}
        </td>
        <td>{post.title}</td>
        <td className="content">{post.content}</td>
        <td>{post.amount_formatted}</td>
        <td>
          <Link
            className="purchased-list-seller-info"
            to={`/user/profile/${post.user.user_unique_id}`}
          >
            <img src={post.user.picture} alt="" />
            <p>
              {post.user.username != "" ? post.user.username : post.user.name}
            </p>
          </Link>
        </td>
        <td>{post.date_of_product_purchase}</td>
        <td>
          <div className="post-action-wrapper">
            <Link
              className="waves-effect waves-light btn bg-primary m-0"
              to={`/post/${post.post_unique_id}`}
            >
              View
            </Link>
            {post.user_review_eligibility_status == 1 && (
              <>
                <button
                  className="waves-effect waves-light btn bg-primary m-0"
                  type="button"
                  onClick={() => props.handleRatingModalOpen(post.post_id)}
                >
                  Rate
                </button>
              </>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default PurchasedPostListItem;
