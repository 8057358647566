import React, { Component } from "react";
import { Notify } from "react-redux-notify";
import EmptyFooter from "./Footer/EmptyFooter";
import AuthHeader from "./Header/AuthHeader";
import EmptyHeader from "./Header/EmptyHeader";

class EmptyLayout extends Component {
  state = {};
  render() {
    return (
      <>
        <Notify position="TopRight" />
        <EmptyHeader />
        <div className="main-wrap-sec">
          {React.cloneElement(this.props.children)}
        </div>
        <br />
        {this.props.match.path == "/" && (<EmptyFooter />)}
      </>
    );
  }
}

export default EmptyLayout;
