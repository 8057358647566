import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  addMessageContent,
  fetchChatMessageStart,
  fetchChatUsersStart,
} from "../../store/actions/ChatAction";
import { connect } from "react-redux";
import NoDataFound from "../helper/NoDataFound";
import qs from "query-string";
import api from "../../Environment";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ChatIndex = (props) => {
  const queryParam = qs.parse(props.location.search);

  useEffect(() => {
    props.dispatch(fetchChatUsersStart());
  }, []);

  const getParameter = (from_user_id, to_user_id) => {
    const newQueryParam = {
      ...queryParam,
      from_user: from_user_id,
      to_user: to_user_id,
    };
    return newQueryParam;
  };

  const MINUTE_MS = 60000;

  useEffect(() => {
    const interval = setInterval(() => {
      api.postMethod("chat_user_live_status").then((response) => {

      });
    }, MINUTE_MS);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  return (
    <>
      <div className="content-area">
        <div className="pagehead-bg   primary-bg"></div>
        <div className="container has-pagehead is-pagetitle">
          <div className="section">
            <h5 className="pagetitle">Inbox</h5>
          </div>
        </div>
        <div className="container over mb-0">
          <div className="section chat-list">
            {props.chatUsers.loading ? (
              <div className="collection mailbox messages transparent  w-100">
                {
                  [...Array(5)].map(() =>
                    <Skeleton width={"100%"} height={"50px"} />
                  )
                }
              </div>
            ) : props.chatUsers.data.users.length > 0 ? (
              <>
                <ul className="collection mailbox messages transparent  w-100">
                  {props.chatUsers.data.users.map((user, index) => (
                    <>
                      {user.to_user_id != localStorage.getItem("userId") && (
                        <>
                          <li className="collection-item avatar" key={index}>
                            <Link
                              to={{
                                pathname: `/chat/user/${user.to_displayname}`,
                                search: qs.stringify(
                                  getParameter(
                                    user.from_user_id,
                                    user.to_user_id
                                  )
                                ),
                              }}
                              className="maillink waves-effect status idle"
                            >
                              <img
                                src={user.to_userpicture}
                                alt={user.to_displayname}
                                title={user.to_displayname}
                                className="circle"
                              />
                              <span className="title">
                                {user.to_username != ""
                                  ? user.to_username
                                  : user.to_displayname}
                              </span>
                              <p>{user.message}</p>
                              <span className="time">{user.created}</span>
                            </Link>
                            <div className="secondary-content">
                              <Link to={"/chat/user"}>
                                {" "}
                                <i className="mdi mdi-reply"></i>
                              </Link>{" "}
                            </div>
                          </li>
                        </>
                      )}
                    </>
                  ))}
                </ul>
              </>
            ) : (
              <NoDataFound message={"No chat history found , start new chat"} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  chatUsers: state.chat.chatUsers,
  chatMessages: state.chat.messages,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ChatIndex);
