import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  searchUserStart,
  loadMoreSearchUserStart,
  fetchUsersStart,
} from "../../store/actions/HomeAction";
import { connect } from "react-redux";
import NoDataFound from "./NoDataFound";
import { postAvailableStatusUpdateStart } from "../../store/actions/PostAction";
import InfiniteScroll from "./InfiniteScroll";

const SearchusersModal = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [skipInitalRender, setSkipInitialRender] = useState(false);

  const handlePostSearch = (value) => {
    setSearchInput(value);
  };

  const handleSearchReset = () => {
    setSearchInput("");
    props.dispatch(fetchUsersStart());
  };

  useEffect(() => {
    if (searchInput.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        props.dispatch(searchUserStart({ search_key: searchInput }));
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    setSkipInitialRender(true);
  }, [searchInput]);

  const handlePostAvailableChange = (status, user_id) => {
    props.dispatch(
      postAvailableStatusUpdateStart({
        post_id: props.searchUserModal.post_id,
        is_available: status,
        product_purchased_user_id: user_id,
      })
    );
    props.setLoadingState(true);
    props.setSearchUserModal({
      ...props.searchUserModal,
      modalStatus: false,
      post_id: null,
    });
  };

  useEffect(() => {
    if (skipInitalRender) {
      props.dispatch(fetchUsersStart());
      setSearchInput("");
    }
  }, [props.searchUserModal.modalStatus]);

  return (
    <>
      <Modal
        className="modal-dialog-center custom-modal user-search-modal"
        size="md"
        centered
        show={props.searchUserModal.modalStatus}
        onHide={props.handleSearchUserModalClose}
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Seach Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="search-user-wrapper">
            <div className="search-bar">
              <nav className="ui-forms">
                <div className="nav-wrapper">
                  <form>
                    <div className="input-field">
                      <input
                        type="search"
                        value={searchInput}
                        placeholder="Search Users"
                        required
                        onChange={(event) =>
                          handlePostSearch(event.target.value)
                        }
                      />
                      <label>
                        <i className="mdi mdi-magnify"></i>
                      </label>
                      <i
                        className="material-icons mdi mdi-close"
                        onClick={() => handleSearchReset()}
                      ></i>
                    </div>
                  </form>
                </div>
              </nav>
            </div>
            {props.searchUser.loading ? (
              "Loading"
            ) : props.searchUser.data.length > 0 &&
              props.searchUser.data.filter(
                (user) => user.user_id != localStorage.getItem("userId")
              ).length > 0 ? (
              <>
                <div className="users-wrapper">
                  <InfiniteScroll
                    fetchAction={loadMoreSearchUserStart}
                    fetchData={props.searchUser}
                    skip={props.searchUser.data.length}
                    take={12}
                    skippedFetch={true}
                    total={props.searchUser.total}
                    parentOverflow={true}
                    overflowClassName="search-users-scroll"
                    dispatchData={
                      searchInput != null ? { search_key: searchInput } : null
                    }
                  >
                    {props.searchUser.data
                      .filter(
                        (user) => user.user_id != localStorage.getItem("userId")
                      )
                      .map((users, index) => (
                        <>
                          <div className="user" key={index}>
                            <div className="user-wrapper">
                              <div className="user-info">
                                <img src={users.picture} alt={users.username} />
                                <div className="details">
                                  <h6>
                                    {users.username != ""
                                      ? users.username
                                      : users.name}
                                  </h6>
                                  <p>{users.email}</p>
                                </div>
                              </div>
                              <div>
                                <button
                                  type="button"
                                  className="waves-effect waves-light btn bg-primary m-0"
                                  onClick={() =>
                                    handlePostAvailableChange(0, users.user_id)
                                  }
                                >
                                  Sell
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    {props.searchUser.total != 0 &&
                      props.searchUser.data.length == props.searchUser.total && (
                        <NoDataFound message="No more user to show" />
                      )}
                  </InfiniteScroll>
                </div>
              </>
            ) : (
              <>
                <NoDataFound message="No user found" />
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  searchUser: state.home.searchUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SearchusersModal);
