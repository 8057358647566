import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import FancyBox from "./FancyBox";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { connect } from "react-redux";
import {
  fetchExploreStart,
  searchExplorePostStart,
  loadMoreExplorePostStart,
} from "../../store/actions/PostAction";
import NoDataFound from "../helper/NoDataFound";
import { savePostLikeStart } from "../../store/actions/PostLikesAction";
import ExplorePostCard from "./ExplorePostCard";
import InfiniteScroll from "../helper/InfiniteScroll";
import { fetchTopStoreOwnersStart, loadMoreTopStoreOwnersStart } from "../../store/actions/StoreOwnerAction";
import TopStoreOwnerCard from "../Home/TopStoreOwnerCard";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Explore = (props) => {
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    props.dispatch(fetchTopStoreOwnersStart({ skip: 0, take: 100 }));
  }, []);

  const handleLike = (post_id) => {
    props.dispatch(savePostLikeStart({ post_id: post_id }));
  };

  // useEffect(() => {
  //   if (searchInput.length > 0) {
  //     props.dispatch(searchExplorePostStart({ search_key: searchInput }));
  //   } else {
  //     props.dispatch(fetchExploreStart({ type: "all" }));
  //   }
  // }, [searchInput]);

  const handlePostSearch = (value) => {
    setSearchInput(value);
  };

  const handleSearchReset = () => {
    props.dispatch(fetchExploreStart({ type: "all" }));
    setSearchInput("");
  };

  return (
    <>
      <div className="content-area">
        <div className="pagehead-bg   primary-bg"></div>
        <div className="container has-pagehead is-pagetitle">
          <div className="section">
            <h5 className="pagetitle">Seller Profiles</h5>
          </div>
        </div>
        <div className="container over port3col">
          {/* <div className="explore-coming">
            <h6>Coming soon</h6>
          </div> */}
          <div className="section pt0">
            <div className="spacer"></div>
            {/* <div className="search-bar mb-5">
              <nav className="ui-forms">
                <div className="nav-wrapper">
                  <form>
                    <div className="input-field">
                      <input
                        type="search"
                        value={searchInput}
                        placeholder="Search Posts"
                        required
                        onChange={(event) =>
                          handlePostSearch(event.target.value)
                        }
                      />
                      <label>
                        <i className="mdi mdi-magnify"></i>
                      </label>
                      <i
                        className="material-icons mdi mdi-close"
                        // onClick={() => handleSearchReset()}
                      ></i>
                    </div>
                  </form>
                </div>
              </nav>
            </div> */}
            <div className="row ui-mediabox explore-grid ">
              {props.topStoreOwners.loading ? (
                <div className="ui-mediabox top-profile explore-index-grid">
                  {[...Array(6)].map(() => 
                    <Skeleton className="product-loader" />
                  )}
                </div>
              ) : props.topStoreOwners.data.total > 0 ? (
                <>
                  {/* <InfiniteScroll
                    fetchAction={loadMoreTopStoreOwnersStart}
                    fetchData={props.topStoreOwners}
                    skip={props.topStoreOwners.data.users.length}
                    take={12}
                    skippedFetch={true}
                    total={props.topStoreOwners.data.total}
                  > */}
                  <>
                    {/* <ResponsiveMasonry
                        columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                        className="explore-masonry-grid"
                      >
                        <Masonry gutter={16}>
                          {props.explorePosts.data
                            .filter((post) => post.postFiles.length > 0)
                            .map((post, index) => (
                              <ExplorePostCard
                                post={post}
                                key={index}
                                handleLike={handleLike}
                                postLike={props.postLike}
                              />
                            ))}
                        </Masonry>
                      </ResponsiveMasonry> */}
                    <div className="ui-mediabox top-profile explore-index-grid ">
                      {props.topStoreOwners.data.users
                        .filter((owner) => owner.user_unique_id != "")
                        .map((storeOwner, index) => (
                          <TopStoreOwnerCard
                            storeOwner={storeOwner}
                            key={index}
                          />
                        ))}
                    </div>
                  </>
                  {/* </InfiniteScroll> */}
                  {props.topStoreOwners.data.users.length ==
                    props.topStoreOwners.data.total && (
                      <NoDataFound message="No More Store Owner to show" />
                    )}
                </>
              ) : (
                <NoDataFound message={"No Store Owner Found"} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  explorePosts: state.post.explorePosts,
  postLike: state.postLike,
  topStoreOwners: state.storeOwner.topStoreOwners,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Explore);
