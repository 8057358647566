import React, { Component, useEffect, useRef, useState } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import { apiConstants } from "../Constant/constants";

import {
  setTranslations,
  setDefaultLanguage,
  translate,
  setLanguage,
} from "react-multi-lang";
import en from "../translations/en.json";
import es from "../translations/es.json";
import LandingPage from "../landing/LandingPage";
import Login from "../../auth/Login";
import Register from "../../auth/Register";
import ResetPassword from "../../auth/ResetPassword";
import ForgotPassword from "../../auth/ForgotPassword";
import HomePageIndex from "../Home/HomePageIndex";
import EmptyLayout from "../layouts/EmptyLayout";
import Explore from "../explore/Explore";
import CreatePost from "../post/CreatePost";
import ChatIndex from "../chat/ChatIndex";
import ScrollToTop from "../helper/ScrollToTop";
import SingleChat from "../chat/SingleChat";
import ProfileIndex from "../profile/ProfileIndex";
import UserProfile from "../profile/UserProfile";
import EditProfile from "../profile/EditProfile";
import ChangePassword from "../profile/ChangePassword";
import DeleteAccount from "../profile/DeleteAccount";
import OtheruserProfile from "../profile/OtheruserProfile";
import Logout from "../../auth/Logout";
import LogoutLayout from "../layouts/LogoutLayout";
import SinglePostView from "../post/SinglePostView";
import LatestStaticPage from "../StaticPage/LatestStaticPage";
import PostList from "../post/PostList";
import EmptyLayoutWithoutFooter from "../layouts/EmptyLayoutWithoutFooter";
import EditPost from "../post/EditPost";
import SeeAllStoreOwner from "../Home/SeeAllStoreOwner";
import PurchasedIndex from "../profile/PurchasedIndex";
import ThankYouIndex from "../Home/ThankYouIndex";
import SeeAllPosts from "../Home/SeeAllPosts";

setTranslations({ en, es });

const history = createHistory();
const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  path: path,
  ...rest
}) => {
  const [locationState, setLocationState] = useState({
    from_url: null,
  });

  const prevLocationRef = useRef({ path }).current;

  useEffect(() => {
    if (prevLocationRef.path !== path) {
      setLocationState({
        ...locationState,
        from_url: prevLocationRef.path,
      });
    }
  }, [path]);
  return (
    <>
    <Route
      {...rest}
      render={(props) => (
        <Layout screenProps={ScreenProps} locationState={locationState} {...props}>
          <Component {...props} />
        </Layout>
      )}
      isAuthed
    />
    </>
  );
};

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  authentication,
  path: path,
  ...rest
}) => {
  const [locationState, setLocationState] = useState({
    from_url: null,
  });

  const prevLocationRef = useRef({ path }).current;

  useEffect(() => {
    if (prevLocationRef.path !== path) {
      setLocationState({
        ...locationState,
        from_url: prevLocationRef.path,
      });
    }
  }, [path]);

  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          authentication === true ? (
            <Layout screenProps={ScreenProps} locationState={locationState}>
              <Component {...props} authRoute={true} />
            </Layout>
          ) : (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          )
        }
      />
    </>
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    let userId = localStorage.getItem("userId");
    let accessToken = localStorage.getItem("accessToken");
    this.state = {
      loading: true,
      configLoading: true,
      authentication: userId && accessToken ? true : false,
    };

    history.listen((location, action) => {
      userId = localStorage.getItem("userId");

      accessToken = localStorage.getItem("accessToken");

      this.setState({
        loading: true,
        authentication: userId && accessToken ? true : false,
      });

      document.body.scrollTop = 0;
    });
  }

  componentDidMount() {
    this.fetchConfig();
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }

  async fetchConfig() {
    try {
      const response = await fetch(apiConstants.settingsUrl);
      const configValue = await response.json();

      configuration.set({ configData: configValue.data }, { freeze: false });
      this.setState({ configLoading: false });
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      this.setState({ configLoading: false });
    }

    $("#google_analytics").html(
      configuration.get("configData.google_analytics")
    );

    $("#header_scripts").html(configuration.get("configData.header_scripts"));

    $("#body_scripts").html(configuration.get("configData.body_scripts"));
  }

  render() {
    const isLoading = this.state.configLoading;

    if (isLoading) {
      return (
        // Place content loadder here
        <div>{/* <HomeLoader></HomeLoader> */}</div>
      );
    }

    return (
      <>
        <Helmet>
          <title>
            {configuration.get("configData.site_name")} - A one-stop thrift
            solution for India’s Gen Z !
          </title>
          <link
            rel="icon"
            type="image/png"
            href={configuration.get("configData.site_icon")}
            // sizes="16x16"
          />
        </Helmet>
        <ScrollToTop />
        <Switch>
          {/* <AppRoute
            path={"/"}
            component={LandingPage}
            exact
            layout={EmptyLayout}
          /> */}
          <AppRoute
            path={"/login"}
            component={Login}
            layout={EmptyLayoutWithoutFooter}
          />
          <AppRoute
            path={"/register"}
            component={Register}
            layout={EmptyLayoutWithoutFooter}
          />
          <AppRoute
            path={"/forgot-password"}
            component={ForgotPassword}
            layout={EmptyLayoutWithoutFooter}
          />
          <AppRoute
            path={"/reset-password/:token"}
            component={ResetPassword}
            layout={EmptyLayoutWithoutFooter}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/logout"}
            component={Logout}
            layout={LogoutLayout}
          />

          <AppRoute
            // authentication={this.state.authentication}
            path={"/"}
            exact
            component={HomePageIndex}
            layout={AuthLayout}
          />

          <AppRoute
            // authentication={this.state.authentication}
            path={"/see-all/storeowners"}
            component={SeeAllStoreOwner}
            layout={AuthLayout}
          />

          <AppRoute
            // authentication={this.state.authentication}
            path={"/see-all/posts"}
            component={SeeAllPosts}
            layout={AuthLayout}
          />

          <AppRoute
            // authentication={this.state.authentication}
            path={"/explore"}
            component={Explore}
            layout={AuthLayout}
          />


          <PrivateRoute
            authentication={this.state.authentication}
            path={"/thank-you"}
            component={ThankYouIndex}
            layout={AuthLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/create-post"}
            component={CreatePost}
            layout={AuthLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/chat"}
            component={ChatIndex}
            exact
            layout={AuthLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/chat/user/:username"}
            component={SingleChat}
            layout={AuthLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/account"}
            component={ProfileIndex}
            layout={AuthLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/profile"}
            component={UserProfile}
            layout={AuthLayout}
          />

          <AppRoute
            // authentication={this.state.authentication}
            path={"/user/profile/:username"}
            component={OtheruserProfile}
            layout={AuthLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/edit-profile"}
            component={EditProfile}
            layout={AuthLayout}
          />

          {/* <PrivateRoute
            authentication={this.state.authentication}
            path={"/post/:post_unique_id"}
            component={SinglePostView}
            layout={AuthLayout}
          /> */}

          <AppRoute
            // authentication={this.state.authentication}
            path={"/post/:id"}
            component={SinglePostView}
            layout={AuthLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/edit-post/:id"}
            component={EditPost}
            layout={AuthLayout}
          />

          <AppRoute
            // authentication={this.state.authentication}
            path={"/static-page"}
            component={LatestStaticPage}
            layout={AuthLayout}
          />

          <AppRoute
            // authentication={this.state.authentication}
            path={"/post-list"}
            component={PostList}
            layout={AuthLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/change-password"}
            component={ChangePassword}
            layout={EmptyLayoutWithoutFooter}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/delete-account"}
            component={DeleteAccount}
            layout={EmptyLayoutWithoutFooter}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/purchased-list"}
            component={PurchasedIndex}
            layout={AuthLayout}
          />
        </Switch>
      </>
    );
  }
}

export default App;
