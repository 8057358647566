import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import NoDataFound from "../helper/NoDataFound";
import PurchasedPostListItem from "../post/PurchasedPostListItem";
import { fetchPurchasedProductStart } from "../../store/actions/PostAction"
import RatingReviewModal from "../helper/RatingReviewModal";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const PurchasedIndex = (props) => {

  const [ratingModal, setRatingModal] = useState({
    modalFlag: false,
    post_id: null,
  });

  useEffect(() => {
    props.dispatch(fetchPurchasedProductStart());
  }, []);

  const handleRatingModalOpen = (post_id) => {
    setRatingModal({
      ...ratingModal,
      modalFlag: true,
      post_id: post_id,
    });
  };

  const handleRatingModalClose = () => {
    setRatingModal({
      ...ratingModal,
      modalFlag: false,
      post_id: null,
    });
  };

  return (
    <>
      <div className="content-area">
        <div className="pagehead-bg   primary-bg"></div>
        <div className="container has-pagehead is-pagetitle">
          <div className="section">
            <h5 className="pagetitle">Purchase List</h5>
          </div>
        </div>
        <div className="container over z-depth-1 post-list-sec">
          <div className="section ui-dropdowns">
            <div className="row ">
              <div className="col s12 pad-0">
                {props.purchasedProducts.loading ? (
                  <div className="collection mailbox messages transparent  w-100">
                    {
                      [...Array(5)].map(() =>
                        <Skeleton width={"100%"} height={"50px"} />
                      )
                    }
                  </div>
                ) : props.purchasedProducts.data.posts.length > 0 ? (
                  <>
                    <table className="responsive-table-post colored primary post-list">
                      <thead>
                        <tr>
                          <th>Post File</th>
                          <th>Post Title</th>
                          <th className="post-desc-sec">Description</th>
                          <th>Amount</th>
                          <th>Seller</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.purchasedProducts.data.posts.map((post, index) => (
                          <PurchasedPostListItem
                            key={index}
                            post={post}
                            handleRatingModalOpen={handleRatingModalOpen}
                          />
                        ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <>
                    <div className="container no-data-found">
                      <NoDataFound message="No items found" />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <RatingReviewModal
        show={ratingModal.modalFlag}
        handleRatingModalClose={handleRatingModalClose}
        post_id={ratingModal.post_id}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  purchasedProducts: state.post.purchasedProducts,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PurchasedIndex);
