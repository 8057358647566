import React, { useState, useEffect, useRef } from "react";
import FancyBox from "../explore/FancyBox";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { savePostLikeStart } from "../../store/actions/PostLikesAction";
import {
  fetchCommentsStart,
  fetchCommentRepliesStart,
  saveCommentStart,
  deleteCommentStart,
} from "../../store/actions/CommentsAction";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NoDataFound from "../helper/NoDataFound";
import ReplyComments from "./ReplyComments";
import PostComments from "./PostComments";
import qs from "query-string";
import { useHistory } from "react-router";
import { saveChatUserStart } from "../../store/actions/ChatAction";
import PostLikeViewer from "../helper/PostLikeViewer";
import Slider from "react-slick";
import LoginPopup from "../helper/LoginPopup";
import Imgix from "react-imgix";

const UserPostsSingle = (props) => {
  const { post } = props;

  const queryParam = qs.parse(props.location.search);

  const history = useHistory();

  let settings = {
    dots: true,
    infinite: false,
    speed: 500,
    autoplay: false,
    arrows: false,
    pauseOnHover: false,
  };

  const commentSchema = Yup.object().shape({
    comment: Yup.string().required("required *"),
  });

  const [postLikes, setPostLikes] = useState(post.like_count);

  const [likeStatus, setLikeStatus] = useState(
    props.post.post_likes.length > 0 &&
      props.post.post_likes.filter((post) => post.user_id == props.post.user_id)
      ? true
      : false
  );

  const [comments, setComments] = useState({
    showComments: true,
    showReplay: false,
    commentCount: props.post.comments_count,
  });

  const [commentInputdata, setCommentInputdata] = useState({
    comment: "",
  });

  const [likeViweModal, setLikeViewModal] = useState({
    modal_status: false,
    likedata: {},
  });

  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const getParameter = (from_user_id, to_user_id, post_id) => {
    const newQueryParam = {
      ...queryParam,
      from_user: from_user_id,
      to_user: to_user_id,
      post_id: post_id,
    };
    return newQueryParam;
  };

  useEffect(() => {
    if (comments.showComments) {
      props.dispatch(fetchCommentsStart({ post_id: post.post_id }));
    }
  }, [comments.showComments]);

  useEffect(() => {
    if (!props.postLike.saveLike.loading) {
      if (props.postLike.saveLike.data.data.post_id == props.post.post_id) {
        if (props.postLike.saveLike.data.message == "Liked") {
          setPostLikes(postLikes + 1);
          setLikeStatus(true);
        } else {
          setPostLikes(postLikes - 1 < 0 ? 0 : postLikes - 1);
          setLikeStatus(false);
        }
      }
    }
  }, [props.postLike.saveLike.data]);

  const handleLike = (post_id) => {
    if(!localStorage.getItem("userLoginStatus")) {
      setShowLoginPopup(true);
    } else {
      props.dispatch(savePostLikeStart({ post_id: post_id }));
    }
  };

  const handleCommentSubmit = (values) => {
    if(!localStorage.getItem("userLoginStatus")) {
      setShowLoginPopup(true);
    } else {
      props.dispatch(
        saveCommentStart({
          comment: values.comment,
          post_id: post.post_id,
        })
      );
    }
    
    setCommentInputdata({
      comment: "",
    });
  };

  const handleCommentShow = () => {
    setComments({
      ...comments,
      showComments: comments.showComments ? false : true,
    });
  };

  const handleDeleteComment = (comment_id) => {
    props.dispatch(
      deleteCommentStart({ post_id: post.post_id, post_comment_id: comment_id })
    );
  };

  useEffect(() => {
    if (comments.showReplay) {
      props.dispatch(
        fetchCommentRepliesStart({
          post_id: props.post.post_id,
          post_comment_id: comments.showReplay,
        })
      );
    }
  }, [comments.showReplay]);

  useEffect(() => {
    if (!props.comments.loading) {
      setComments({
        ...comments,
        commentCount: props.comments.data.total,
      });
    }
  }, [props.comments.data]);

  const handleChatUser = () => {
    if(!localStorage.getItem("userLoginStatus")) {
      setShowLoginPopup(true);
    } else {
      props.dispatch(
        saveChatUserStart({
          from_user_id: localStorage.getItem("userId"),
          to_user_id: props.post.user_id,
        })
      );
    }
    
  };

  useEffect(() => {
    if (!props.saveChatUser.loading) {
      const location = {
        pathname: `/chat/user/${props.saveChatUser.data.to_username}`,
        search: qs.stringify(
          getParameter(
            props.saveChatUser.data.from_user_id,
            props.saveChatUser.data.to_user_id,
            post.post_unique_id
          )
        ),
      };
      history.push(location);
    }
  }, [props.saveChatUser.data]);

  const handleCommentInputdata = (value) => {
    setCommentInputdata({
      ...commentInputdata,
      comment: value,
    });
  };

  const handleLikeViwerModalOpen = (likesData) => {
    setLikeViewModal({
      ...likeViweModal,
      modal_status: true,
      likedata: likesData,
    });
  };

  const handleLikeViwerModalClose = () => {
    setLikeViewModal({
      ...likeViweModal,
      modal_status: false,
      likedata: {},
    });
  };

  const handlePopupClose = () => {
    setShowLoginPopup(false)
  };
  
  return (
    <>
      <div className="container mobile-remove-spacing">
        <div className="section">
          <FancyBox>
            <ul className="collection mailbox activity z-depth-0 ">
              <li className="collection-item avatar  ">
                <div className="maillink mb-3">
                  <img
                    src={post.user.picture}
                    alt={post.user.user_displayname}
                    title={post.user.user_displayname}
                    className="circle"
                  />
                  <Link
                    to={`/user/profile/${post.user.user_unique_id}`}
                    className="title"
                  >
                    {post.user.name}
                  </Link>
                  <span className="time">{post.updated}</span>
                </div>
                {post.postFiles.length > 0 && (
                  <div className="media m-0 w-100 post">
                    <div className="row">
                      <Slider {...settings} className="single-post-slider">
                        {post.postFiles.map((file, index) => (
                          <>
                            {file.file_type == "image" ? (
                              <div className={"col s12"} key={index}>
                                <a
                                  className={`img-wrap round popup-image ${
                                    post.post_type == 2 &&
                                    post.is_available == "sold"
                                      ? "sold"
                                      : ""
                                  }`}
                                  href={file.post_file}
                                  data-fancybox
                                  data-caption={post.title}
                                >
                                  {/* <Imgix
                                    alt="image"
                                    className="img-responsive"
                                    src={file.post_file}
                                    width={100}
                                    disableQualityByDPR
                                  /> */}
                                   <picture>
                                        <source className="lazyload img-responsive" type="image/webp" srcset={file.post_file}/>
                                        <img data-src={file.post_file} type="image/webp" className=" lazyload img-responsive"
                                            alt="image" loading="lazy"/>
                                    </picture>
                                  {post.post_type == 2 &&
                                    post.is_available == "sold" && (
                                      <>
                                        {/* <div className="sold-tag">
                                        <h6 className="m-0">Sold out</h6>
                                      </div> */}
                                        <div className="sold-tag-img-sec">
                                          <img
                                            src={
                                              window.location.origin +
                                              "/assets/images/sold-out-tag.png"
                                            }
                                            alt=""
                                            className="sold-tag-img"
                                          />
                                        </div>
                                      </>
                                    )}
                                    <div className="product-img-info-sec">
                                      <ul className="list-unstyled product-img-info">
                                      {post.size ? 
                                        <li>
                                            <span>{post.size}</span>
                                        </li>
                                      :''}
                                        <li>
                                            <span>{post.amount_formatted}</span>
                                        </li>
                                      </ul>
                                    </div>
                                </a>
                              </div>
                            ) : (
                              <>
                                <video
                                  autoplay
                                  controls
                                  id="myVideo"
                                  className="user-profile1 w-100"
                                >
                                  <source
                                    src={file.post_file}
                                    type="video/mp4"
                                  />
                                </video>
                              </>
                            )}
                          </>
                        ))}
                      </Slider>
                    </div>
                  </div>
                )}
                <p
                  className="mb-3"
                  dangerouslySetInnerHTML={{
                    __html: post.content != undefined ? post.content : "",
                  }}
                ></p>
                <div class="user-actions">
                  <div className="d-flex align-items-center post-action m-0">
                    <div className="d-flex gap-2">
                      <button
                        className={`no-btn m-0 like-btn ${
                          likeStatus ? "active" : ""
                        }`}
                        onClick={() => handleLike(post.post_id)}
                      >
                        {likeStatus ? (
                          <>
                            <i class="mdi mdi-thumb-up"></i>
                          </>
                        ) : (
                          <>
                            <i class="mdi mdi-thumb-up-outline "></i>
                          </>
                        )}
                      </button>
                      {postLikes > 0 && (
                        <>
                          <span
                            className="like-count hover-btn"
                            onClick={() =>
                              handleLikeViwerModalOpen(post.post_likes)
                            }
                          >
                            {likeStatus ? (
                              <>
                                {postLikes - 1 <= 0
                                  ? `You liked`
                                  : `You and ${postLikes - 1} other liked`}
                              </>
                            ) : (
                              <>
                                {postLikes} {postLikes <= 1 ? "Like" : "Likes"}
                              </>
                            )}
                          </span>
                        </>
                      )}
                    </div>

                    <button
                      className="no-btn m-0 d-flex"
                      onClick={() => handleCommentShow()}
                    >
                      <i class="mdi mdi-comment-outline"></i>
                      <span>
                        {comments.commentCount}{" "}
                        {comments.commentCount <= 1 ? "Comment" : "Comments"}
                      </span>
                    </button>
                  </div>
                </div>
                {post.post_type == 2 && (
                  <div className="prod-info">
                    <div className="d-flex">
                      <Link
                        to={`/post/${post.post_unique_id}`}
                        className="flex-data-space-btw"
                      >
                        <h5 className="title truncate m-0">{post.title} </h5>
                      </Link>
                      {post.is_available != "sold" &&
                        localStorage.getItem("userId") != post.user_id && (
                          <>
                            <span className="ml-auto buynow-btn">
                              <button
                                onClick={() => handleChatUser()}
                                className="waves-effect waves-light btn-large bg-primary  m-0 addtocart btn-small"
                              >
                                Buy Now
                              </button>
                            </span>
                          </>
                        )}
                      {localStorage.getItem("userLoginStatus") && localStorage.getItem("userId") != post.user_id &&
                        post.product_purchased_user_id ==
                          localStorage.getItem("userId") &&
                          props.data.user_review_eligibility_status == 1 ? (
                          <>
                            <span className="ml-auto buynow-btn">
                              <button
                                className="waves-effect waves-light btn-large bg-primary  m-0 addtocart btn-small"
                                onClick={() =>
                                  props.handleRatingModalOpen(post.post_id)
                                }
                              >
                                Rate & Review
                              </button>
                            </span>
                          </>
                        ) : 
                          <>
                            <span className="ml-auto buynow-btn">
                              <button
                                className="waves-effect waves-light btn-large bg-primary  m-0 addtocart btn-small"
                                onClick={() =>
                                  setShowLoginPopup(true)
                                }
                              >
                                Rate & Review
                              </button>
                            </span>
                          </>
                        }
                    </div>
                    <span className="small brand">{post.is_available}</span>
                    <div className="spacer-line"></div>
                    <span className="small brand clr-blue"><b>{post.brand_type_formatted}</b></span>
                    <div className="spacer-line"></div>
                    <span className="small brand">Category:{post.category_name}</span>
                    <div className="spacer-line"></div>
                    <h5 className="bot-0 price m-0">{post.amount_formatted}</h5>
                  </div>
                )}
                <div className="mt-3">
                  {comments.showComments && (
                    <>
                      {props.comments.loading
                        ? "Loading.."
                        : props.comments.data.post_comments.length > 0
                        ? props.comments.data.post_comments.map(
                            (comment, index) => (
                              <>
                                <PostComments
                                  commentsState={comments}
                                  comment={comment}
                                  handleDeleteComment={handleDeleteComment}
                                  setComments={setComments}
                                  post={post}
                                  key={index}
                                />
                              </>
                            )
                          )
                        : null}
                      <div className="mt-2">
                        <Formik
                          initialValues={commentInputdata}
                          validationSchema={commentSchema}
                          onSubmit={async (values, { resetForm }) => {
                            await handleCommentSubmit(values);
                            resetForm();
                          }}
                        >
                          {({
                            touched,
                            errors,
                            isSubmitting,
                            setFieldValue,
                          }) => (
                            <Form noValidate>
                              <div className="comment-reply-box col s12">
                                <div className="row d-flex align-items-center m-0">
                                  <div className="input-field col w-100 m-0 ">
                                    <Field
                                      type="email"
                                      name="comment"
                                      value={commentInputdata.comment}
                                      className={`validate no-padding ${
                                        touched.email && errors.email
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Please Type a comment"
                                      onChange={(event) => {
                                        handleCommentInputdata(
                                          event.target.value
                                        );
                                        setFieldValue(
                                          "comment",
                                          event.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="col ml-auto center mb-0">
                                    <button
                                      type="submit"
                                      className="waves-effect waves-light btn-large bg-primary round-btn-icon m-0"
                                      disabled={props.saveComment.buttonDisable}
                                    >
                                      <i className="mdi mdi-send"></i>
                                    </button>
                                  </div>
                                </div>
                                <ErrorMessage
                                  component="div"
                                  name="comment"
                                  className="invalid-feedback col s12 m-0"
                                />
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </>
                  )}
                </div>
              </li>
            </ul>
          </FancyBox>
        </div>
      </div>
      <PostLikeViewer
        show={likeViweModal.modal_status}
        handleLikeViwerModalClose={handleLikeViwerModalClose}
        likedata={likeViweModal.likedata}
      />
      <LoginPopup
        show={showLoginPopup}
        hide={handlePopupClose}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  explorePosts: state.post.explorePosts,
  postLike: state.postLike,
  comments: state.comment.comments,
  saveChatUser: state.chat.saveChatUser,
  saveComment: state.comment.saveComment,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(UserPostsSingle);
