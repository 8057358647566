import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { changePasswordStart } from "../../store/actions/UserAction";
import { connect } from "react-redux";

const ChangePassword = (props) => {
  const handleSubmit = (values) => {
    props.dispatch(changePasswordStart(values));
  };

  const changePasswordSchema = Yup.object().shape({
    old_password: Yup.string()
      .min(6, "Password must be 6 characters at minimum")
      .required("Old Password is required *"),
    password: Yup.string()
      .min(6, "Password must be 6 characters at minimum")
      .required("New Password is required *"),
    password_confirmation: Yup.string()
      .required("ConfirmPassword is required")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  });

  return (
    <>
      <div className="content-area">
        <div className="login-bg access-reset"></div>
        <div className="container login-area">
          <div className="section">
            <h3 className="bot-20 center white-text">Change Password</h3>
            <div className="spacer-large"></div>
            <Formik
              initialValues={{
                old_password: "",
                password: "",
                password_confirmation: "",
              }}
              validationSchema={changePasswordSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form noValidate>
                  <div className="row">
                    <div class="input-field col s10 offset-s1">
                      <Field
                        type="password"
                        name="old_password"
                        placeholder="old password"
                        className={`input-bordered no-padding  ${
                          touched.old_password && errors.old_password
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      name="old_password"
                      className="invalid-feedback col s10 offset-s1"
                    />
                  </div>
                  <div className="row">
                    <div class="input-field col s10 offset-s1">
                      <Field
                        type="password"
                        name="password"
                        placeholder="new password"
                        className={`no-padding form-control ${
                          touched.password && errors.password
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      name="password"
                      className="invalid-feedback col s10 offset-s1"
                    />
                  </div>
                  <div className="row">
                    <div class="input-field col s10 offset-s1">
                      <Field
                        type="password"
                        name="password_confirmation"
                        placeholder="confirm password"
                        className={`no-padding form-control ${
                          touched.password_confirmation &&
                          errors.password_confirmation
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      name="password_confirmation"
                      className="invalid-feedback col s10 offset-s1"
                    />
                  </div>
                  <div className="row text-center">
                    <Link
                      type="button"
                      className="waves-effect waves-light btn-large bg-primary mt-3 mt-md-0"
                      to="/account"
                    >
                      Cancel
                    </Link>
                    <button
                      type="submit"
                      className="waves-effect waves-light btn-large bg-primary ml-2 mt-3 mt-md-0"
                      disabled={props.changePassword.buttonDisable}
                    >
                      {props.changePassword.loadingButtonContent !== null
                        ? props.changePassword.loadingButtonContent
                        : "Change Password"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  changePassword: state.changePassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ChangePassword);
