import React from 'react'

const LatestStaticPage = () => {
    return (
        <>
            <div className="content-area">
                <div className="pagehead-bg   primary-bg">
                </div>
                <div className="container has-pagehead is-pagetitle">
                    <div className="section">
                        <h5 className="pagetitle">Static Page</h5>
                    </div>
                </div>
                <div className="container over z-depth-1">
                    <div className="section">
                        <div className="row ">
                            <div className="col s12 pad-0">
                                <blockquote>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tempus, nibh at volutpat sodales, elit lacus pharetra risus, eget consequat massa nisi sit amet est. Mauris diam arcu, finibus vel tempor ut, auctor non orci. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec vestibulum ligula, laoreet posuere lectus. Vestibulum sapien sem, aliquam at nibh ac, vestibulum posuere odio. Pellentesque ullamcorper, libero at dapibus vehicula, libero tellus fringilla enim, et fringilla justo neque a arcu. Cras nisi diam, maximus in interdum sit amet, tincidunt vel est. Sed fermentum ut sapien non placerat. Integer sem quam, pulvinar id nibh at, finibus porttitor ante. Duis viverra convallis ornare. Pellentesque id tempus quam. Nunc porta velit eget vulputate ultrices.
                                </blockquote>
                                <blockquote>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tempus, nibh at volutpat sodales, elit lacus pharetra risus, eget consequat massa nisi sit amet est. Mauris diam arcu, finibus vel tempor ut, auctor non orci. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec vestibulum ligula, laoreet posuere lectus. Vestibulum sapien sem, aliquam at nibh ac, vestibulum posuere odio. Pellentesque ullamcorper, libero at dapibus vehicula, libero tellus fringilla enim, et fringilla justo neque a arcu. Cras nisi diam, maximus in interdum sit amet, tincidunt vel est. Sed fermentum ut sapien non placerat. Integer sem quam, pulvinar id nibh at, finibus porttitor ante. Duis viverra convallis ornare. Pellentesque id tempus quam. Nunc porta velit eget vulputate ultrices.
                                </blockquote>
                                <blockquote>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tempus, nibh at volutpat sodales, elit lacus pharetra risus, eget consequat massa nisi sit amet est. Mauris diam arcu, finibus vel tempor ut, auctor non orci. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec vestibulum ligula, laoreet posuere lectus. Vestibulum sapien sem, aliquam at nibh ac, vestibulum posuere odio. Pellentesque ullamcorper, libero at dapibus vehicula, libero tellus fringilla enim, et fringilla justo neque a arcu. Cras nisi diam, maximus in interdum sit amet, tincidunt vel est. Sed fermentum ut sapien non placerat. Integer sem quam, pulvinar id nibh at, finibus porttitor ante. Duis viverra convallis ornare. Pellentesque id tempus quam. Nunc porta velit eget vulputate ultrices.
                                </blockquote>
                                <blockquote>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tempus, nibh at volutpat sodales, elit lacus pharetra risus, eget consequat massa nisi sit amet est. Mauris diam arcu, finibus vel tempor ut, auctor non orci. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec vestibulum ligula, laoreet posuere lectus. Vestibulum sapien sem, aliquam at nibh ac, vestibulum posuere odio. Pellentesque ullamcorper, libero at dapibus vehicula, libero tellus fringilla enim, et fringilla justo neque a arcu. Cras nisi diam, maximus in interdum sit amet, tincidunt vel est. Sed fermentum ut sapien non placerat. Integer sem quam, pulvinar id nibh at, finibus porttitor ante. Duis viverra convallis ornare. Pellentesque id tempus quam. Nunc porta velit eget vulputate ultrices.
                                </blockquote>
                                <blockquote>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tempus, nibh at volutpat sodales, elit lacus pharetra risus, eget consequat massa nisi sit amet est. Mauris diam arcu, finibus vel tempor ut, auctor non orci. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec vestibulum ligula, laoreet posuere lectus. Vestibulum sapien sem, aliquam at nibh ac, vestibulum posuere odio. Pellentesque ullamcorper, libero at dapibus vehicula, libero tellus fringilla enim, et fringilla justo neque a arcu. Cras nisi diam, maximus in interdum sit amet, tincidunt vel est. Sed fermentum ut sapien non placerat. Integer sem quam, pulvinar id nibh at, finibus porttitor ante. Duis viverra convallis ornare. Pellentesque id tempus quam. Nunc porta velit eget vulputate ultrices.
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LatestStaticPage;
