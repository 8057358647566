import React, { useEffect, useState } from "react";
import {
  fetchPostsStart,
  searchOwnUserPostStart,
  deletePostStart,
  postAvailableStatusUpdateStart,
} from "../../store/actions/PostAction";
import NoDataFound from "../helper/NoDataFound";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PostListItem from "./PostListItem";
import SearchusersModal from "../helper/SearchusersModal";
import DeletePostConfirmation from "../helper/DeletePostConfirmation";
import RatingReviewViewerModal from "../helper/RatingReviewViewerModal";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const PostList = (props) => {
  const [searchInput, setSearchInput] = useState("");

  const [searchUserModal, setSearchUserModal] = useState({
    modalStatus: false,
    post_id: null,
  });

  const [loadingState, setLoadingState] = useState(
    props.postAvailableStatusUpdate.loading
  );

  const [deletePostConfirmation, setDeletePostConfirmation] = useState({
    status: false,
    post_id: null,
  });

  useEffect(() => {
    props.dispatch(fetchPostsStart({ type: "all" }));
  }, []);

  useEffect(() => {
    if (searchInput.length > 0) {
      props.dispatch(searchOwnUserPostStart({ search_key: searchInput }));
    } else {
      props.dispatch(fetchPostsStart({ type: "all" }));
    }
  }, [searchInput]);

  const handlePostSearch = (value) => {
    setSearchInput(value);
  };

  const handleSearchReset = () => {
    props.dispatch(fetchPostsStart({ type: "all" }));
    setSearchInput("");
  };

  const handleSearchUserModalClose = () => {
    setSearchUserModal({
      ...searchUserModal,
      modalStatus: false,
      post_id: null,
    });
  };

  const handleDeletePost = () => {
    props.dispatch(
      deletePostStart({ post_id: deletePostConfirmation.post_id })
    );
    setDeletePostConfirmation({
      ...deletePostConfirmation,
      status: false,
      post_id: null,
    });
  };

  const handleDeletPostConfirmationOpen = (post_id) => {
    setDeletePostConfirmation({
      ...deletePostConfirmation,
      status: true,
      post_id: post_id,
    });
  };

  const handleDeletPostConfirmationClose = (post_id) => {
    setDeletePostConfirmation({
      ...deletePostConfirmation,
      status: false,
      post_id: null,
    });
  };

  return (
    <>
      <div className="content-area">
        <div className="pagehead-bg   primary-bg"></div>
        <div className="container has-pagehead is-pagetitle">
          <div className="section">
            <h5 className="pagetitle">Post List</h5>
          </div>
        </div>
        <div className="container over z-depth-1 post-list-sec">
          <div className="section ui-dropdowns">
            <div className="search-bar mb-5">
              <nav className="ui-forms">
                <div className="nav-wrapper">
                  <form>
                    <div className="input-field">
                      <input
                        type="search"
                        value={searchInput}
                        placeholder="Search Posts"
                        onChange={(event) =>
                          handlePostSearch(event.target.value)
                        }
                        required
                      />
                      <label>
                        <i className="mdi mdi-magnify"></i>
                      </label>
                      <i
                        className="material-icons mdi mdi-close"
                        onClick={() => handleSearchReset()}
                      ></i>
                    </div>
                  </form>
                </div>
              </nav>
            </div>
            <div className="row ">
              <div className="col s12 pad-0">
                {props.posts.loading ? (
                  <div className="collection mailbox messages transparent  w-100">
                    {
                      [...Array(5)].map(() =>
                        <Skeleton width={"100%"} height={"50px"} />
                      )
                    }
                  </div>
                ) : props.posts.data.posts.length > 0 ? (
                  <>
                    <table className="responsive-table-post colored primary post-list">
                      <thead>
                        <tr>
                          <th>Post File</th>
                          <th>Post Title</th>
                          <th className="post-desc-sec">Description</th>
                          <th>Post Type</th>
                          <th>Status</th>
                          <th>Availability</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.posts.data.posts.map((post, index) => (
                          <PostListItem
                            key={index}
                            post={post}
                            setLoadingState={setLoadingState}
                            loadingState={loadingState}
                            setSearchUserModal={setSearchUserModal}
                            searchUserModal={searchUserModal}
                            handleDeletPostConfirmationOpen={handleDeletPostConfirmationOpen}
                          />
                        ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <>
                    <div className="container no-data-found">
                      <p>
                        No post yet,{" "}
                        <Link to="/create-post">create a post</Link>
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SearchusersModal
        searchUserModal={searchUserModal}
        handleSearchUserModalClose={handleSearchUserModalClose}
        setSearchUserModal={setSearchUserModal}
        searchUserModal={searchUserModal}
        setLoadingState={setLoadingState}
      />
      <DeletePostConfirmation
        show={deletePostConfirmation.status}
        hide={handleDeletPostConfirmationClose}
        handleDeletePost={handleDeletePost}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  posts: state.post.posts,
  postAvailableStatusUpdate: state.post.postAvailableStatusUpdate,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PostList);
